/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from "react";
import { IconButton } from "@mui/material";
import { useFetch } from "../../Hooks/useFetch";
import BigDataTable from "../Components/Tables/BigDataTable";
import DataTableColumn from "../Components/Tables/DataTableColumn";
import configuration from "../../configuration";
import useToken from "../../Hooks/useToken";

function StatoLabel({ stato }) {
	switch (stato) {
		case "IN_ELABORAZIONE": return (<span style={{ background: "orange", borderRadius: "3px", padding: "0 5px 0 5px" }}><b>In elaborazione</b></span>);
		case "ELABORATO": return (<span style={{ background: "#6fed7e", borderRadius: "3px", padding: "0 5px 0 5px" }}><b>Elaborato</b></span>);
		case "ERRORE": return (<span style={{ background: "#ff8c8c", borderRadius: "3px", padding: "0 5px 0 5px" }}><b>Errore</b></span>);
		default:
		case "DA_ELABORARE": return (<span style={{ background: "#bfc9d9", borderRadius: "3px", padding: "0 5px 0 5px" }}><b>Da elaborare</b></span>);
	}
}

export default function Export({ account }) {
	const [refresh, setRefresh] = useState(0);
	const [query, setQuery] = useState({ page: 0, pageSize: 20, orderBy: [{ field: "id", desc: true }] });
	const [downloading, setDownloading] = useState(false);
	const jwt = useToken();

	const { data } = useFetch('/export/stato', null, 'POST', query, refresh);

	const refresher = useRef(null);

	useEffect(() => {
		const needRefreshes = data?.data?.some((v) => v.stato === "IN_ELABORAZIONE" || v.stato === "DA_ELABORARE") ?? false;

		if (refresher.current == null && needRefreshes) {
			refresher.current = setInterval(() => {
				setRefresh(Date.now());
			}, 2000);
		}

		if (refresher.current != null && !needRefreshes) {
			clearInterval(refresher.current);
			refresher.current = null;
		}
	}, [data]);

	const downloadExport = async (id) => {
		setDownloading(id);
		const response = await fetch(`${configuration.serverAddress}/export/download/${id}`, {
			method: 'GET',
			headers: new Headers({ Authorization: `Bearer ${jwt}` })
		});

		const [_, filename] = response.headers.get('content-disposition').split('filename=');

		const blob = await response.blob();

		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		a.remove();
		setDownloading(false);
	};

	const Filters = (obj) => {
		let filters = "";
		if (!obj) return "";
		Object.keys(obj).forEach((key) => {
			if (obj[key] !== undefined && obj[key] !== null && typeof obj[key] !== 'object') {
				filters += `${key}: ${obj[key]} `;
			}

			if (typeof obj[key] === 'object' && obj[key] !== null && obj[key] !== undefined && key !== "queryParameters") {
				filters += Filters(obj[key]);
				filters += " ";
			}
		});
		return filters;
	};

	if (!data) return (<></>);

	return (
		<div>
			<BigDataTable
				onPageChange={(page) => setQuery({ ...query, page })}
				page={query.page}
				data={data.data}
				pageSize={query.pageSize}
				totalCount={data.totalResults}
				idField="id"
			>
				<DataTableColumn
					renderHeader={() => "Download"}
					renderContent={(r) => r.stato === "ELABORATO" && (
						// eslint-disable-next-line
						<div align="center">
							<IconButton
								disabled={downloading !== false}
								onClick={() => downloadExport(r.id)}
							>
								{
									downloading === r.id
										? (<i className="fas fa-fw fa-spinner fa-spin" />)
										: (<i className="fas fa-fw fa-file-download" />)
								}
							</IconButton>
						</div>
					)}
					min
				/>
				<DataTableColumn renderHeader={() => "ID"} renderContent={(r) => r.id} min />
				<DataTableColumn renderHeader={() => "Stato"} renderContent={(r) => <StatoLabel stato={r.stato} />} min />
				<DataTableColumn renderHeader={() => "Tipo"} renderContent={(r) => r.tipo} min />
				<DataTableColumn renderHeader={() => "Filtri"} renderContent={(r) => Filters(r?.parametri?.filtri)} />
			</BigDataTable>
			<br />
			<br />
			<br />
		</div>
	);
}
