import { Rating, TextField } from '@mui/material';
import Highlighter from "react-highlight-words";
import React, { useEffect, useState } from 'react';
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useFetch } from '../../Hooks/useFetch';
import DataTableColumn from '../Components/Tables/DataTableColumn';
import ToolsArea from '../Components/ToolsArea';
import BigDataTable from "../Components/Tables/BigDataTable";
import configuration from "../../configuration";
import useToken from "../../Hooks/useToken";
import SpinnyPage from "../Components/SpinnyPage";
import RangePicker from "../Components/Forms/RangePicker";
import Export from "../Components/Export";
import FeedbackFields from "../Utils/FeedbackFields";
import DataGrid from "../Components/Tables/DataGrid";
import useTableColumns from "../Utils/useTableColumns";

export default function FeedbackUtenti({ account }) {
	// - Table data
	const [createEditMode, setCreateEditMode] = useState(false);
	const [deleteOpen, setDeleteOpen] = useState(false);
	const [selected, setSelected] = useState([]);
	const [refresh, setRefresh] = useState(0);
	const [query, setQuery] = useState({ page: 0, pageSize: 20 });
	const [selectedTrb, setSelectedTrb] = useState(null);
	const { data } = useFetch('/feedback/query', null, 'POST', query, refresh);
	const [openExport, setOpenExport] = useState(false);
	const history = useHistory();

	// - Prendo il token JWT
	const jwt = useToken();

	const { visibleColumns, allColumns, setVisible, visible, setOrder, sortFilter, setSort, sort } = useTableColumns("feedback", [
		{ key: "id", name: "Id", frozen: false /*true*/, draggable: false, resizable: true, highlight: false, minWidth: 80, width: 80 },
		{ key: "backoffice", name: "BO", draggable: true, sortable: true, sortColumn: "backoffice", resizable: true, highlight: false, minWidth: 100, width: 100 },
		{
			key: "valutazione",
			name: "Valutazione",
			frozen: false,
			draggable: true,
			highlight: false,
			resizable: false,
			sortColumn: "valutazione",
			sortable: true,
			minWidth: 130,
			width: 130,
			formatter: ({ row }) => <Rating sx={{ marginTop: "12px" }} id="rating-feedback-id" name="hover-feedback" size="small" readOnly value={row.valutazione} />
		},
		{ key: "dataFeedback", name: "Data", frozen: false, sortable: true, sortColumn: "tsInserimento", draggable: true, highlight: false, minWidth: 140, width: 140, resizable: true },
		{ key: "descrizione", name: "Suggerimenti", draggable: true, resizable: true, highlight: true, minWidth: 500 },
		{ key: "tributi", name: "Tipologie di pagamento", frozen: false, draggable: true, highlight: false, minWidth: 300, resizable: true }
	], ["id", "valutazione", "dataFeedback", "descrizione", "tributi"]);

	const tableData = data?.data?.map((r) => ({
		id: r?.id ?? "",
		valutazione: r?.valutazione ?? "",
		dataFeedback: r?.dataFeedback ? moment(r?.dataFeedback).format("DD/MM/yyyy") : "N.D.",
		descrizione: r.descrizione ?? "",
		tributi: r.tributiString ?? "",
		backoffice: r.backoffice ? "Si" : "No"
	}));

	useEffect(() => setQuery((q) => ({ ...q, orderBy: sortFilter })), [JSON.stringify(sortFilter)]);

	useEffect(() => {
		const action = async () => {
			if (selected.length === 1 && createEditMode === "edit") {
				const tributo = data.data.filter((d) => d.id === selected[0])[0];

				const response = await fetch(`${configuration.serverAddress}/tributo/${tributo.codiceEnte}/${tributo.codiceTributo}`, {
					method: "GET",
					headers: new Headers({
						Authorization: `Bearer ${jwt}`,
						...configuration.defaultHeaders
					})
				});

				setSelectedTrb(await response.json());
			} else setSelectedTrb(null);
		};

		action();
	}, [createEditMode]);
	if (!data) return <SpinnyPage />;

	const FilterFields = [
		[
			(r) => (<TextField {...r('id')} label="Identificativo recensione" variant="standard" type="number" />),
			(r) => (<TextField {...r('descrizione')} label="Descrizione" variant="standard" />),
			(r) => (<TextField {...r('valutazioneMin')} label="Valutazione da" variant="standard" type="number" />),
			(r) => (<TextField {...r('valutazioneMax')} label="Valutazione a" variant="standard" type="number" />),
			(r, c) => (<RangePicker {...r('dataFeedback')} control={c} nameStart="startDataFeedback" nameEnd="endDataFeedback" variant="standard" startText="Creazione da" endText="Creazione a" />)
		]
	];

	return (
		<div>
			<Export
				open={openExport}
				columns={Object.values(FeedbackFields)}
				queryFilters={{ ...query, pageSize: null, page: null }}
				onDeny={() => setOpenExport(false)}
				onSuccess={() => history.push("/backoffice/export")}
				totalResults={data.totalResults}
				type={{
					name: "Feedback",
					apiName: "feedback",
					path: "it.ras.pagopa.shared.dto.system.prenotazione.PrenotazioneFeedback"
				}}
				initialSelected={[
					FeedbackFields.id, FeedbackFields.valutazione,
					FeedbackFields.descrizione, FeedbackFields.dataFeedback,
					FeedbackFields.tributi, FeedbackFields.backoffice
				]}
			/>
			<ToolsArea
				className="mt-4 mb-3"
				selected={selected}
				queryParameters={[
					'id', 'descrizione', 'valutazioneMin', 'valutazioneMax', 'dataFeedback',
					'tributo1.denominazione', 'tributo1.idTributo.codiceEnte',
					'tributo2.denominazione', 'tributo2.idTributo.codiceEnte',
					'tributo3.denominazione', 'tributo3.idTributo.codiceEnte',
					'tributo4.denominazione', 'tributo4.idTributo.codiceEnte',
					'tributo5.denominazione', 'tributo5.idTributo.codiceEnte'
				]}
				disableDelete
				disableEdit
				disableNew
				filtersChanged={(filters) => setQuery({ ...query, ...filters })}
				deleteCallback={() => setDeleteOpen(true)}
				createCallback={() => setCreateEditMode('create')}
				editCallback={() => setCreateEditMode('edit')}
				enableExport
				exportCallback={() => setOpenExport(true)}
				fields={FilterFields}
				allColumns={allColumns}
				visibleColumns={visible}
				setVisibleColumns={setVisible}
			/>
			<DataGrid
				columns={visibleColumns}
				rows={tableData}
				query={query?.query?.split(" ") ?? []}
				onSortColumnsChange={(col) => setOrder(col.map((c) => c.key))}
				rowKeyGetter={(row) => row.id}
				totalResults={data.totalResults}
				setPage={(page) => setQuery({ ...query, page })}
				currPage={query.page}
				sortData={sort}
				onSortDataChange={setSort}
			/>
			<br />
			<br />
			<br />
		</div>
	);
}
