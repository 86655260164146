import React from "react";
import { Controller, useWatch } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";

export default function TributiAmmessi({ control, trb }) {
	return trb && (
		<Controller
			name="tributi"
			defaultValue={[]}
			control={control}
			onChange={([, d]) => d}
			render={({ field: { onChange, value } }) => (
				<Autocomplete
					multiple
					id="tags-standard"
					options={trb}
					getOptionLabel={(option) => option}
					isOptionEqualToValue={(option, v) => v === option}
					value={value || ''}
					onChange={(e, d) => onChange(d ?? [])}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="standard"
							label="Tipologie di pagamenti ammesse"
							placeholder=""
						/>
					)}
				/>
			)}
		/>
	);
}
