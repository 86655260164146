/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-indent */
import React from "react";
import { Controller, useWatch } from "react-hook-form";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import TributoSelect from "./Forms/TributoSelect";
import { useFetch } from "../../Hooks/useFetch";
import StrutturaSelect from "./Forms/StrutturaSelect";

const StatoInvioEmail = ({ ctr, name, className }) => (
	<Controller
		name={name}
		defaultValue={null}
		control={ctr}
		render={({ field: { onChange, value } }) => (
			<FormControl variant="standard" style={{ width: "200px" }} className={className}>
				<InputLabel id="statoInvioEmail-label">Stato Invio</InputLabel>
				<Select labelId="statoInvioEmail-label" id="statoInvioEmail" label="Tipo voce" value={value ?? ''} onChange={onChange}>
					<MenuItem value={null}>Qualsiasi</MenuItem>
					<MenuItem value="DaInviare">Da Inviare</MenuItem>
					<MenuItem value="InElaborazione">In Elaborazione</MenuItem>
					<MenuItem value="Inviata">Inviata</MenuItem>
					<MenuItem value="Errore">Errore</MenuItem>
				</Select>
			</FormControl>
		)}
	/>
);

function StatoAvvisiLabel({ statoAvviso }) {
	switch (statoAvviso) {
		case "Inviata":
			return (
				<span style={{ background: "lightgreen", borderRadius: "3px", padding: "0 5px 0 5px" }}><b>Inviato</b></span>
			);
		case "InElaborazione":
			return (
				<span style={{ background: "orange", color: "white", borderRadius: "3px", padding: "0 5px 0 5px" }}><b>In Elaborazione</b></span>
			);
		case "DaInviare":
			return (
				<span style={{ background: "#7ECEF8", color: "#355769", borderRadius: "3px", padding: "0 5px 0 5px" }}><b>In coda</b></span>
			);
		default:
			return (<span />);
		case "ErroreInviato":
			return (
				<span style={{ background: "red", color: "white", borderRadius: "3px", padding: "0 5px 0 5px" }}><b>Errore</b></span>
			);
		case "NoMail":
			return (<span style={{ background: "#f5853b", color: "white", borderRadius: "3px", padding: "0 5px 0 5px" }}><b>Indirizzo Assente</b></span>
			);
		case "EmailPresente":
			return (<span style={{ background: "#69A197", color: "white", borderRadius: "3px", padding: "0 5px 0 5px" }}><b>Indirizzo Presente</b></span>
			);
	}
}

function TributoPick({ control, enteOperatore, fieldWatchName, name }) {
	const ente = useWatch({ control, name: fieldWatchName }) ?? enteOperatore;
	return (
		<Controller
			render={({ field: { onChange, value } }) => (
				<TributoSelect
					onChange={(v) => onChange(v)}
					value={value}
					style={{ flexShrink: 1 }}
					ente={ente}
				/>
			)}
			name={name}
			control={control}
		/>
	);
}

const StatoPagamento = ({ ctr, name, className }) => (
	<Controller
		name={name}
		defaultValue={null}
		control={ctr}
		render={({ field: { onChange, value } }) => (
			<FormControl variant="standard" style={{ width: "200px" }} className={className}>
				<InputLabel id="statoPagamento-label">Stato pagamento</InputLabel>
				<Select labelId="statoPagamento-label" id="statoPagamento" label="Tipo voce" value={value ?? ''} onChange={onChange}>
					<MenuItem value={null}>Qualsiasi</MenuItem>
					<MenuItem value={false}>Da pagare</MenuItem>
					<MenuItem value={true}>Pagato</MenuItem>
				</Select>
			</FormControl>
		)}
	/>
);

const Soluzione = ({ ctr, name, className }) => (
	<Controller
		name={name}
		defaultValue={null}
		control={ctr}
		render={({ field: { onChange, value } }) => (
			<FormControl variant="standard" style={{ width: "200px" }} className={className}>
				<InputLabel id="soluzione-label">Rateazione</InputLabel>
				<Select labelId="soluzione-label" id="soluzione" label="Tipo voce" value={value ?? ''} onChange={onChange}>
					<MenuItem value={null}>Qualsiasi</MenuItem>
					<MenuItem value={false}>Soluzione unica</MenuItem>
					<MenuItem value={true}>Rate</MenuItem>
				</Select>
			</FormControl>
		)}
	/>
);

const StrutturaOrganizzativa = ({ ctr, name, enteOperatore }) => {
	const ente = useWatch({ control: ctr, name: "ente.codiceEnte", defaultValue: null });
	const { data: strutture } = useFetch('/struttura/list', enteOperatore ?? ente, 'GET');
	if (!strutture?.data || strutture.data.length <= 0) return (<></>);
	const linearizeData = (rows) => rows.reduce((acc, d) => [
		...acc, d, ...linearizeData(d.figli)
	], []);
	const linearizedStrutture = linearizeData(strutture.data);
	return (
		<Controller
			name={name}
			defaultValue={null}
			control={ctr}
			render={({ field: { onChange, value } }) => (
				<StrutturaSelect
					onChange={onChange}
					value={value}
					strutture={(enteOperatore ?? ente) ? linearizedStrutture : []}
					style={{ flexShrink: 1 }}
				/>
			)}
		/>
	);
};

export {
	TributoPick,
	StatoPagamento,
	Soluzione,
	StrutturaOrganizzativa,
	StatoInvioEmail,
	StatoAvvisiLabel
};
