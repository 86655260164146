/* eslint-disable  */

export function removeEmptyObjects(f) {
	if (typeof f === 'object' && !Array.isArray(f) && f !== null) {
		const newobj = {};
		// eslint-disable-next-line
		for (const k of Object.keys(f)) {
			const newValue = removeEmptyObjects(f[k]);
			if (newValue !== null && newValue !== '' && newValue !== undefined) newobj[k] = newValue;
		}

		if (Object.keys(newobj).length > 0) return newobj;
		return null;
	}

	if (Array.isArray(f)) {
		const newobj = [];
		// eslint-disable-next-line
		for (const v of f) {
			const newValue = removeEmptyObjects(v);
			if (newValue !== null && newValue !== '' && newValue !== undefined) newobj.push(newValue);
		}

		if (newobj.length > 0) return newobj;
		return null;
	}

	return f;
}