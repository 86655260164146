import React from "react";
import { TextField } from "@mui/material";

export default function CampoNumericoSetting({ value, onChange }) {
	return (
		<div>
			<TextField
				label="Valore di default"
				variant="standard"
				className="w-100"
				type="number"
				value={value.defaultValue}
				onChange={(v) => onChange({ ...value, defaultValue: v.target.value })}
			/>
			<TextField
				label="Valore minimo"
				variant="standard"
				className="w-25 mt-3"
				type="number"
				value={value.min}
				onChange={(v) => onChange({ ...value, min: v.target.value })}
			/>
			<TextField
				label="Valore massimo"
				variant="standard"
				className="w-25 ml-4 mt-3"
				type="number"
				value={value.max}
				onChange={(v) => onChange({ ...value, max: v.target.value })}
			/>
		</div>
	);
}
