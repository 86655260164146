import React from "react";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";
import moment from "moment";
import { TextField } from "@mui/material";

export default function CampoData({ value, onChange, campo }) {
	return (
		<LocalizationProvider dateAdapter={DateAdapter}>
			<DatePicker
				label={campo.label}
				className="w-100"
				value={value ? moment(value, "DD/MM/YYYY") : null}
				onChange={(v) => onChange(v.format("DD/MM/YYYY"))}
				inputFormat="DD/MM/YYYY"
				renderInput={(props) => <TextField id="datePickerText" {...props} />}
			/>
		</LocalizationProvider>
	);
}
