const excludedValues = ['@class: it.ras.pagopa.shared.plugins.pagamenti.campi.CampiData'];

export default function DataTableColumn({
	min, renderContent, renderHeader, row, key, i,
	// - Tree layout
	indent = 0, indentSize = 20, padding = 20
}) {
	// - Styles
	const indentStyle = { paddingLeft: `${indent * indentSize + padding}px` };
	const minimizedStyle = min && { whiteSpace: 'nowrap', width: '1%' };

	// - Rendering dell'header
	if (!row) { return <th style={{ ...minimizedStyle, paddingLeft: `${padding}px`, height: "45px" }} key={key}>{renderHeader()}</th>;
	}
	let content = renderContent(row, i);

	if (typeof content === "string") {
		content = content.replace(excludedValues, "").trim();
	}

	return (
		<td style={{ ...minimizedStyle, ...indentStyle }} key={key}>
			{content}
		</td>
	);
}
