/* eslint-disable max-len */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Empty, Select, Button } from "antd";
import { Chip, ChipLabel, Icon } from "design-react-kit";
import Highlighter from "react-highlight-words";

import {
	ENTE,
	fetchAllEnti,
	fetchListaEntiEnded,
	getListaEnti
} from "../../../Store/Ricerca";

const { Option } = Select;

export default function CampoRicercaEnte(props) {
	const dispatch = useDispatch();

	const {
		placeholder,
		onSelectionChange
	} = props;

	const [srcDatasource, setSrcDatasource] = useState([]);
	const [datasource, setDatasource] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);
	const [selectedKeys, setSelectedKeys] = useState([]);
	const [hasFocus, setHasFocus] = useState(false);
	const [openDropdownDisabled, setOpenDropdownDisabled] = useState(false);
	const [openMenu, setOpenMenu] = useState(false);
	const [inputQuery, setInputQuery] = useState("");

	const listaEnti = useSelector(getListaEnti);
	const listaEntiEnded = useSelector(fetchListaEntiEnded);

	useEffect(() => {
		if (!listaEnti) dispatch(fetchAllEnti());
		if (listaEnti) {
			const sourceData = [
				...(selectedKeys.some((v) => v.split("-")[0] === ENTE) ? [] : listaEnti)
			];
			setDatasource(sourceData);
			setSrcDatasource(sourceData);
		}
	}, [listaEnti]);

	const onSearch = (query) => {
		setInputQuery(query);
		if (selectedKeys.length === 0) {
			setOpenMenu(query.length >= 3);
		}

		if (selectedKeys.length === 0) {
			dispatch(fetchAllEnti({ tributo: null, query }));
		}
	};

	const onChange = (choosenItems) => {
		let choosenKeys = [];
		if (choosenItems.length > 0) {
			if (choosenItems.length > selectedKeys.length) {
				choosenKeys = [...selectedKeys, ...choosenItems.filter((item) => !selectedKeys.includes(item))];
			} else {
				choosenKeys = selectedKeys.filter((key) => choosenItems.includes(key));
			}
		}
		setOpenMenu(choosenItems.length === 0);

		setSelectedKeys(choosenKeys);
		setSelectedItems(
			// eslint-disable-next-line
			choosenKeys.map((key) => {
				const item = srcDatasource.find((i) => i.id === key);
				if (item !== undefined) return key;
			})
		);

		if (choosenItems.length === 0) {
			setOpenDropdownDisabled(false);
			setDatasource([...listaEnti]);
		} else if (choosenItems.length === 1) setOpenDropdownDisabled(true);

		if (onSelectionChange) {
			onSelectionChange(choosenItems);
		}
	};

	const onFocusChange = (e) => {
		if (!hasFocus) setHasFocus(true);
	};

	const onBlurChange = (e) => {
		setOpenMenu(false);
		const tempE = { ...e };
		if (e.target.value === undefined || e.target.value.length === 0) tempE.target = { value: null };
		if (hasFocus) setHasFocus(false);
	};

	const removeItem = (deletedKey) => {
		const newKeys = selectedKeys.filter((key) => key !== deletedKey);
		onChange(newKeys);
		setOpenMenu(false);
	};

	const tagRender = ({ label, value, onClose }) => (
		<Chip style={{ overflow: "hidden", display: "flex" }}>
			<ChipLabel tag="div" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", minWidth: "0px", flexShrink: 1 }}>{label}</ChipLabel>
			<Button
				onClick={() => {
					onClose();
					removeItem(value);
				}}
			>
				<Icon icon="it-close" />
			</Button>
		</Chip>
	);

	let labelClassName = "customSelectLabel";
	if (hasFocus || (selectedKeys && selectedKeys !== undefined && selectedKeys.length > 0)) labelClassName += " selectActive";

	const filteredData = datasource && datasource !== undefined && datasource.length > 0 ? datasource.filter(
		(item) => !selectedItems.includes(item.value)
	) : [];

	return (
		<div id="ricercaAvanzata" className="selectContent">
			<div className={labelClassName}>{placeholder ?? 'Seleziona un elemento'}</div>
			<Select
				className={openDropdownDisabled ? "customSelect selectDisabled" : "customSelect"}
				{...(openDropdownDisabled && { disabled: true })}
				tagRender={tagRender}
				mode="multiple"
				onChange={onChange}
				allowClear
				onSearch={onSearch}
				onFocus={onFocusChange}
				onBlur={onBlurChange}
				bordered={false}
				filterOption={(inputValue, option) => option.label.toString().toLowerCase().includes(inputValue.toLowerCase())}
				notFoundContent={<Empty description="Nessun risultato" />}
				dropdownClassName="customInputDropdown"
				dropdownStyle={{ display: openMenu ? "block" : "none" }}
				optionLabelProp="label"
			>
				{filteredData.map((item) => (
					<Option key={item.id} disabled={item.disabled} label={item.value}>
						<Highlighter searchWords={[inputQuery]} textToHighlight={item.value} highlightStyle={{ fontWeight: "bold", background: "none", padding: 0, margin: 0 }} />
					</Option>
				))}
			</Select>
		</div>
	);
}
