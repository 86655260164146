import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout as logoutAction } from "../Store/Authentication";

function Redirect({ logout }) {
	const dispatch = useDispatch();
	const history = useHistory();

	if (logout) {
		dispatch(logoutAction()).then(() => history.push('/'));
		return <></>;
	}

	window.location.href = '/api/auth/login';
	return <></>;
}

export default Redirect;
