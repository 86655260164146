import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export default function CampoCheckbox({ value, onChange, campo, className }) {
	return (
		<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
			<InputLabel>{campo.label}</InputLabel>

			<Select className={`${className}`} style={{ minWidth: "300px", flexGrow: 1, flexShrink: 1 }} label={campo.label} variant="standard" value={value ?? ""} onChange={(v) => onChange(v.target.value === "" ? null : v.target.value)}>
				<MenuItem value="">Nessun filtro</MenuItem>
				<MenuItem value>Spuntato</MenuItem>
				<MenuItem value={false}>Non spuntato</MenuItem>
			</Select>
		</FormControl>
	);
}
