import React from "react";
import { TextField } from "@mui/material";

export default function CampoStringSetting({ value, onChange }) {
	return (
		<div>
			<TextField
				label="Valore di default"
				variant="standard"
				className="w-100"
				value={value.defaultValue}
				onChange={(v) => onChange({ ...value, defaultValue: v.target.value })}
			/>
			<TextField
				label="Lunghezza massima"
				variant="standard"
				type="number"
				className="w-100 mt-3"
				value={value.maxLen}
				onChange={(v) => onChange({ ...value, maxLen: v.target.value })}
			/>
			<TextField
				label="Regex di controllo"
				variant="standard"
				type="text"
				className="w-100 mt-3"
				value={value.regex}
				onChange={(v) => onChange({ ...value, regex: v.target.value })}
			/>
			<TextField
				label="Messaggio di errore"
				variant="standard"
				type="text"
				className="w-100 mt-3"
				value={value.regexErrorMessage}
				onChange={(v) => onChange({ ...value, regexErrorMessage: v.target.value })}
			/>
		</div>
	);
}
