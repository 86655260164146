import React from "react";
import { useHistory } from "react-router-dom";
import ListaCardSingola from "./ListaCardSingola";
import { useFetch } from "../../../Hooks/useFetch";

export default function HomepageCards(props) {
	const {
		t,
		setShowAllTributi,
		showAllTributi
	} = props;
	const history = useHistory();
	const { data: tributi, status } = useFetch('/stats/tributiConsigliati', null, 'GET');

	return tributi && tributi.length > 0 && !showAllTributi && (
		<>
			<section className="section" style={{ backgroundColor: "#E6E9F2", paddingTop: "20px", paddingBottom: "20px" }}>
				<div className="container">
					<div className="row">
						<div className="col-12">
							<h4>{t('homePageCard.titoloCard')}</h4>
						</div>
					</div>
				</div>
			</section>
			<section className="section section-inset-shadow pb-0 pt-2 pb-5">
				<div className="container pt-2 pb-4 px-0 px-md-3" id="content">
					<div className="row">
						<div className="col-12">
							<div className="row">
								{tributi?.map((x) => (
									<ListaCardSingola
										nome={x.denominazione}
										onClick={() => history.push(`/pagamento/${x.codiceEnte}/${x.codiceTributo}`)}
										testoLink={t('homePageCard.accessoForm')}
										imageLink={<i className="fas fa-arrow-right" style={{ color: "grey" }} />}
										key={`tributo-${x.denominazione}`}
									>
										{x.nomeEnte}
									</ListaCardSingola>
								))}
							</div>
						</div>
					</div>
				</div>
				<div style={{ display: "flex", justifyContent: "center" }}>
					<button type="button" className="mr-4 btn btn-outline-primary" style={{ color: "black", fontWeight: "bold" }} onClick={() => {
						setShowAllTributi(true);
						window.scrollTo(0, 0);
					}}>
						{t('homePageCard.text')}
					</button>
				</div>
			</section>
		</>
	);
}
