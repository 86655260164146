import React from "react";
import { Divider } from "@mui/material";

function Card({ title, children, fullWidth, env }) {
	let envColor = "black";
	switch (env) {
		case "Produzione": envColor = "#D84949"; break;
		case "Staging": envColor = "#49C7D8"; break;
		default: break;
	}

	return (
		<div className="bg-white p-3" style={{ color: "#30373d", borderRadius: "5px", boxShadow: "0px 0px 20px #00000011", fontSize: "15px", maxWidth: !fullWidth && "400px" }}>
			<div className="d-flex flex-row align-items-center" style={{ fontSize: "18px", fontWeight: "600", verticalAlign: "middle" }}>
				<i className="fa fa-file-alt" style={{ color: "#1d2d7e", fontSize: "25px", marginRight: "15px" }} />
				<span style={{ lineHeight: "25px" }}>{title}</span>
				{env && <Divider orientation="vertical" style={{ height: "16px", marginLeft: "10px", marginRight: "10px" }} />}
				<span style={{ color: envColor, textTransform: "uppercase", fontSize: "14px", lineHeight: "14px" }}>{env}</span>
			</div>
			{children && <div style={{ marginTop: "10px" }}>{children}</div>}
		</div>
	);
}

export default function Manuali() {
	return (
		<div className="m-4">
			<h1 className="mt-4">Manuali operativi</h1>
			<Divider />

			<p className="mt-3" style={{ color: "#888" }}>
				In questa sezione è possibile trovare tutti i manuali operativi per gli operatori e gli amministratori.
				È possibile inoltre visionare la documentazione dei servizi web per l&apos;integrazione di gestionali
				interni dell&apos;ente.
			</p>

			<div className="d-flex flex-row" style={{ columnGap: "20px" }}>
				<a href="/documenti/Manuale%20per%20Ente%20Creditore_v6.1.pdf" aria-label="Manuale operatore" target="_blank">
					<Card title="Manuale d'uso">
						Il manuale descrive i passi operativi per l’utilizzo delle funzionalità del Sistema dei
						Pagamenti della Regione Autonoma della Sardegna, a disposizione dell’utente nel ruolo di
						Amministratore/Operatore dell’Ente Creditore (EC).
					</Card>
				</a>
				<a href="/documenti/Specifiche%20tracciato%20CSV_v7.1.pdf" aria-label="Specifiche tracciato CSV" target="_blank">
					<Card title="Documentazione CSV">
						Il documento descrive il tracciato CSV, fondamentale per il processo di caricamento massivo dei
						debiti da parte dell’Ente Creditore nel Sistema dei Pagamenti della Regione Autonoma della
						Sardegna.
					</Card>
				</a>
			</div>

			<h4 style={{ textTransform: "uppercase" }} className="mt-5">Esempi di compilazione CSV</h4>
			<div className="d-flex flex-column" style={{ rowGap: "20px" }}>
				<a href="/documenti/Debito%20standard.csv" aria-label="Esempio CSV con sole pendenze"><Card title="Esempio CSV con sole pendenze" fullWidth /></a>
				<a href="/documenti/Debito%20con%20rate.csv" aria-label="Esempio CSV con sole rate"><Card title="Esempio CSV con sole rate" fullWidth /></a>
				<a href="/documenti/Debito%20con%20rate%20e%20voci.csv" aria-label="Esempio CSV con rate e voci di pagamento"><Card title="Esempio CSV con rate e voci di pagamento" fullWidth /></a>
			</div>

			<h4 style={{ textTransform: "uppercase" }} className="mt-5">Documentazione delle API</h4>
			<div className="d-flex flex-column" style={{ rowGap: "20px" }}>
				<a href="/documenti/Specifiche%20integrazione%20caricamento%20posizioni%20debitorie%20(produzione)_v4.0.pdf" aria-label="Specifiche integrazione caricamento posizioni debitorie" target="_blank">
					<Card title="Specifiche integrazione caricamento posizioni debitorie" fullWidth env="Produzione">
						Il documento contiene le informazioni relative all’utilizzo del servizio che consente agli enti
						intermediati (dotati di opportuno client) il trasferimento di posizioni debitorie in ambiente di
						produzione, nel rispetto dei campi richiesti e presenti nel tracciato CSV.
					</Card>
				</a>
				<a href="/documenti/Specifiche%20integrazione%20caricamento%20posizioni%20debitorie%20(staging)_v3.2.pdf" aria-label="Specifiche integrazione caricamento posizioni debitorie" target="_blank">
					<Card title="Specifiche integrazione caricamento posizioni debitorie" fullWidth env="Staging">
						Il documento contiene le informazioni relative all’utilizzo del servizio che consente agli enti
						intermediati (dotati di opportuno client) il trasferimento di posizioni debitorie in ambiente di
						staging, nel rispetto dei campi richiesti e presenti nel tracciato CSV.
					</Card>
				</a>
				<a href="/documenti/Specifiche%20integrazione%20enti%20(ambiente%20di%20produzione)_v4.0.pdf" aria-label="Specifiche integrazione enti" target="_blank">
					<Card title="Specifiche integrazione enti" fullWidth env="Produzione">
						Il documento contiene le specifiche per l’integrazione di pagoPA Sardegna coi sistemi
						eventualmente
						a disposizione degli enti per l’attivazione dei pagamenti.
					</Card>
				</a>
				<a href="/documenti/Specifiche%20integrazione%20enti%20(ambiente%20di%20staging)_v2.2.pdf" aria-label="Specifiche integrazione enti" target="_blank">
					<Card title="Specifiche integrazione enti" fullWidth env="Staging">
						Il documento contiene le specifiche per l’integrazione di pagoPA Sardegna coi sistemi
						eventualmente
						a disposizione degli enti per l’attivazione dei pagamenti.
					</Card>
				</a>
				<a href="/documenti/Specifiche%20web%20service%20per%20acquisizione%20pagamenti_v4.0.pdf" aria-label="Specifiche acquisizione pagamenti" target="_blank">
					<Card title="Specifiche acquisizione pagamenti" fullWidth>
						Il documento descrive le specifiche relative al web service finalizzato all’acquisizione dei
						pagamenti effettuati.
					</Card>
				</a>
			</div>
		</div>
	);
}
