/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ListaCard from '../Common/ListaCard';
import ListaCardSingola from '../Common/ListaCardSingola';

import { useFetch } from "../../../Hooks/useFetch";
import Paginazione from '../Common/Paginazione';
import SpinnyPage from "../../../Backoffice/Components/SpinnyPage";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function ListaServizi(props) {
	const {
		t,
		codiceEnte,
		searchString,
		spontanei
	} = props;

	const pageSize = useSelector((state) => state.pagination.perPage);
	const [page, setPage] = useState(1);

	const { data, status } = useFetch('/tributo/query-public', null, 'POST', {
		pageSize,
		page: page - 1,
		ente: {
			codiceEnte: codiceEnte !== '' ? codiceEnte : null
		},
		query: searchString ?? '',
		queryParameters: [
			"codiceTributo",
			"denominazione"
		],
		orderBy: [{ field: "denominazione", desc: false }],
		spontaneo: (spontanei === 0 ? true : (spontanei === 1 ? false : undefined))
	});

	const numRisultati = data?.totalResults ? data?.totalResults : 0;

	if (status === "fetching") {
		return <SpinnyPage />;
	}

	const lista = data?.data.map((e) => {
		const numStrutture = e.struttureAmmesse.length;
		const link = e.spontaneo
			? (numStrutture > 1
				? `/strutture/${e.codiceEnte}/${e.codiceTributo}`
				: numStrutture === 1
					? `/pagamento/${e.codiceEnte}/${e.codiceTributo}/${e.struttureAmmesse[0].cdr}`
					: `/pagamento/${e.codiceEnte}/${e.codiceTributo}`)
			: `/pagamento/${e.codiceEnte}/${e.codiceTributo}`;

		return (
			<ListaCardSingola
				nome={e.denominazione}
				link={link}
				testoLink={e.spontaneo ? (numStrutture > 1 ? 'Scegli struttura' : t('enti.pagamento')) : t('enti.debito')}
				key={`tributo-${e.codiceEnte}-${e.codiceTributo}`}
			>
				<div className="strutture-organizzative">
					{numStrutture > 0 && e.spontaneo && (
						<span className="chip mt-1">
							<Link to={`${link}`}>
								{`${e.struttureAmmesse.length} Strutture organizzative`}
							</Link>
						</span>
					)}
				</div>
			</ListaCardSingola>
		);
	}) ?? <></>;

	return (
		<>
			<section className="section section-inset-shadow pb-0 pt-2 pb-5">
				<div className="container pt-2 pb-4 px-0 px-md-3" id="content">
					<ListaCard
						risultatiTotali={numRisultati}
						elementi={lista}
						paginaCorrente={page}
						perPagina={pageSize}
					/>
					<Paginazione
						paginaCorrente={page}
						perPagina={pageSize}
						risultatiTotali={numRisultati}
						onPageChange={(p) => setPage(p)}
					/>
				</div>
			</section>
		</>
	);
}
