/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import moment from "moment";
import { IconButton } from "@mui/material";
import Highlighter from "react-highlight-words";
import { useFetch } from '../../Hooks/useFetch';
import DataTableColumn from '../Components/Tables/DataTableColumn';
import BigDataTable from "../Components/Tables/BigDataTable";
import configuration from "../../configuration";
import useToken from "../../Hooks/useToken";
import useTableColumns from "../Utils/useTableColumns";
import { DataGridSelectColumn } from "../Components/Tables/DataGridSelect";
import DataGrid from "../Components/Tables/DataGrid";
import ToolsArea from "../Components/ToolsArea";

function AutomationStatus({ status }) {
	if (status === "InAttesa") return <span style={{ backgroundColor: "orange", borderRadius: "5px", padding: "0 5px 0 5px" }}><b>Conferma attesa</b></span>;
	if (status === "Confermata") return <span style={{ backgroundColor: "orange", borderRadius: "5px", padding: "0 5px 0 5px" }}><b>Confermata</b></span>;
	if (status === "Annullata") return <span style={{ backgroundColor: "#bfc9d9", borderRadius: "5px", padding: "0 5px 0 5px" }}><b>Annullata</b></span>;
	if (status === "Eseguita") return <span style={{ backgroundColor: "#6fed7e", borderRadius: "5px", padding: "0 5px 0 5px" }}><b>Eseguita</b></span>;
}

function ActionDescription({ action }) {
	const type = action["@class"].split(".").pop();
	switch (type) {
		case "RimozioneOperatoreStr":
			return `Rimozione operatore ${action.codiceFiscale} dalla struttura ${action.cdr}.`;
		case "AggiuntaOperatoreStr":
			return `Aggiunta operatore ${action.codiceFiscale} alla struttura ${action.cdr} come ${action.ruolo}.`;
		case "CancellazioneStruttura":
			return `Cancellazione della struttura organizzativa ${action.cdr}.`;
		case "CreazioneStruttura":
			return `Creazione della struttura organizzativa ${action.nome} (${action.cdr}).`;
		default:
			return <></>;
	}
}

export default function Automazioni({ account }) {
	// - Table data
	const [refresh, setRefresh] = useState(0);
	const [query, setQuery] = useState({ page: 0, pageSize: 20, orderBy: "tsInserimento", desc: true });
	const { data } = useFetch('/automazioni/query', null, 'POST', query, refresh);

	const jwt = useToken();

	const [selectedRows, setSelectedRows] = useState(new Map());

	const enteOperatore = account.ente?.codiceEnte;

	function deleteAutomation(id) {
		fetch(`${configuration.serverAddress}/automazioni/delete`, {
			method: 'POST',
			headers: new Headers({ Authorization: `Bearer ${jwt}`, ...configuration.defaultHeaders }),
			body: JSON.stringify({ id })
		}).then((resp) => resp.json()).then(() => {

		}).finally(() => {
			setRefresh((i) => i + 1);
		});
	}

	function confirmAutomation(id) {
		fetch(`${configuration.serverAddress}/automazioni/confirm`, {
			method: 'POST',
			headers: new Headers({ Authorization: `Bearer ${jwt}`, ...configuration.defaultHeaders }),
			body: JSON.stringify({ id })
		}).then((resp) => resp.json()).then(() => {

		}).finally(() => {
			setRefresh((i) => i + 1);
		});
	}

	const { visibleColumns, allColumns, setVisible, visible, setOrder, sort, sortFilter, setSort } = useTableColumns("automazioni", [
		{
			key: "annulla",
			name: "",
			frozen: true,
			draggable: false,
			resizable: false,
			minWidth: 54,
			width: 54,
			formatter: ({ row }) => <IconButton disabled={row.stato !== "InAttesa"} onClick={() => deleteAutomation(row.id)}><i className="fa fa-times fa-fw" /></IconButton>
		},
		{
			key: "accetta",
			name: "",
			frozen: true,
			draggable: false,
			resizable: false,
			minWidth: 54,
			width: 54,
			formatter: ({ row }) => <IconButton disabled={row.stato !== "InAttesa"} onClick={() => confirmAutomation(row.id)}><i className="fa fa-check fa-fw" /></IconButton>
		},
		!enteOperatore && { key: "codiceEnte", name: "Codice ente", sortColumn: "ente.codiceEnte", sortable: true, frozen: false /*true*/, draggable: true, resizable: true, highlight: true, minWidth: 200, width: 240 },
		{ key: "creazione", name: "Data di creazione", sortColumn: "tsInserimento", sortable: true, frozen: false /*true*/, draggable: false, highlight: true, resizable: true, minWidth: 200, width: 200 },
		{ key: "stato", name: "Stato", sortColumn: "stato", sortable: true, draggable: false, resizable: true, width: 80, formatter: ({ row }) => <AutomationStatus status={row.stato} /> },
		{ key: "azione", name: "Descrizione azione", frozen: false, draggable: false, highlight: false, minWidth: 500, resizable: true, formatter: ({ row }) => <ActionDescription action={row.azione} /> }
	], ["annulla", "accetta", !enteOperatore && "codiceEnte", "creazione", "stato", "azione"]);

	useEffect(() => setQuery((q) => ({ ...q, orderBy: sortFilter })), [JSON.stringify(sortFilter)]);

	const tableData = data?.data?.map((r) => ({
		id: r.id,
		codiceEnte: r.ente ?? "",
		creazione: r.tsInserimento ? moment(r.tsInserimento).format("DD/MM/yyyy HH:mm:ss") : "N.D.",
		stato: r.stato ?? "",
		azione: r.azione ?? ""
	}));

	if (!data) return (<></>);

	return (
		<div style={{ paddingTop: "20px" }}>
			<ToolsArea
				className="mt-4 mb-3"
				disableDelete
				disableEdit
				disableNew
				disableSearch
				allColumns={allColumns}
				visibleColumns={visible}
				setVisibleColumns={setVisible}
			/>
			<DataGrid
				columns={visibleColumns}
				rows={tableData}
				query={query?.query?.split(" ") ?? []}
				onSortColumnsChange={(col) => setOrder(col.map((c) => c.key))}
				rowKeyGetter={(row) => row.id}
				totalResults={data.totalResults}
				selectedRows={selectedRows}
				setSelectedRows={setSelectedRows}
				setPage={(page) => setQuery({ ...query, page })}
				currPage={query?.page}
				sortData={sort}
				onSortDataChange={setSort}
			/>
			<br />
			<br />
			<br />
		</div>
	);
}
