/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	readedVersion: null
};

export const localStateSlice = createSlice({
	name: 'localState',
	initialState,
	reducers: {
		readVersion: (state, action) => {
			const value = action.payload;
			state.readedVersion = value;
		}
	}
});

export const { readVersion } = localStateSlice.actions;

export default localStateSlice.reducer;
