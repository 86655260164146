import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Modal, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";

export default function ModalAvvisi(props) {
	const { open, setOpen, onSubmit, loading } = props;

	const { register, handleSubmit } = useForm({
		defaultValues: {
			fileName: "Avviso [codFis]-[codDeb]"
		}
	});

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 600,
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 4
	};

	return (
		<Modal
			open={open}
		>
			<Box sx={style}>
				<h4>Formato nome avviso</h4>
				<form
					onSubmit={handleSubmit(onSubmit)}
				>
					<div style={{ display: "flex", flexDirection: "column" }}>
						<TextField {...register("fileName")} label="Scegli il formato del nome degli avvisi in download" fullWidth className="mt-2 mb-2" />
						<Accordion style={{ backgroundColor: "#f2f2f2" }} className="mt-2 mb-4">
							<AccordionSummary
								expandIcon={<i className="fas fa-chevron-down" />}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								<Typography>Istruzioni di compilazione</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Typography>
									Per inserire dei dati all&apos;interno del nome dei file,
									utilizza i codici seguenti.
									<ul>
										<li>Codice Fiscale: [codFis]</li>
										<li>Denominazione Utente: [denUtente]</li>
										<li>Denominazione Ente: [denEnte]</li>
										<li>Codice Debito: [codDeb]</li>
										<li>Causale: [causale]</li>
										<li>Data Inserimento: [dataIns]</li>
										<li>Data Inizio Validità: [dataInizioVal]</li>
									</ul>
								</Typography>
							</AccordionDetails>
						</Accordion>
						<LoadingButton type="submit" variant="contained" className="mb-2" loading={loading}>OK</LoadingButton>
						<Button variant="outlined" onClick={() => setOpen(false)}>Annulla</Button>
					</div>
				</form>
			</Box>
		</Modal>
	);
}
