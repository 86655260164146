/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable import/no-named-as-default */
/* eslint-disable arrow-body-style */
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";

import styles from './Nav.module.css';
import { useFetch } from "../../Hooks/useFetch";

export default function Nav({ permessi }) {
	// - Gestione della navigazione
	const history = useHistory();
	const path = history.location.pathname;

	const readedVersion = useSelector((state) => state.localState.readedVersion);
	const { data: patchnotes } = useFetch(`/patchnotes/query`, null, 'POST', { pageSize: 1, orderBy: [{ field: "dataRilascio", desc: true }] });

	const vociMenu = {
		Configurazione: [
			{
				label: 'Enti',
				permesso: ['GestioneEnti'],
				link: '/backoffice/enti'
			},
			{
				label: 'Operatori',
				permesso: ['GestioneOperatori', 'LetturaOperatori'],
				link: '/backoffice/operatori'
			},
			{
				label: 'Strutture organizzative',
				permesso: ['GestioneStrutture', 'LetturaStrutture'],
				link: '/backoffice/strutture'
			},
			{
				label: 'Tipologie di pagamento',
				permesso: ['GestioneTributi', 'LetturaTributi'],
				link: '/backoffice/tributi'
			},
			{
				label: 'POS',
				permesso: ['GestionePos', 'LetturaPos'],
				link: '/backoffice/pos'
			},
			{
				label: 'Tassi di interesse',
				permesso: ['GestioneTassi'],
				link: '/backoffice/tassi'
			},
			{
				label: 'Automazioni',
				permesso: ['LetturaAutomazioni'],
				link: '/backoffice/automazioni'
			}
		],
		'Debiti e pagamenti': [
			{
				label: 'Debiti',
				permesso: ['GestioneDebiti', 'LetturaDebiti'],
				link: '/backoffice/pendenze?spontaneo=false'
			},
			{
				label: 'Pagamenti',
				permesso: ['GestionePagamenti', 'LetturaPagamenti'],
				link: '/backoffice/pagamenti'
			},
			{
				label: 'Storico rendicontazioni',
				permesso: ['LetturaRendicontazioni'],
				link: '/backoffice/rendicontazioni'
			},
			{
				label: 'Caricamento Debiti',
				permesso: ['CaricamentoMassivoDebiti'],
				link: '/backoffice/caricamentoPendenze'
			},
			{
				label: 'Pagamento tramite pos',
				permesso: ['GestioneDebiti'],
				link: '/backoffice/pagamentoPos'
			}
		],
		'Documenti e comunicazioni': [
			{
				label: 'Reportistica',
				permesso: ['LetturaReportistica'],
				link: '/backoffice/reportistica'
			},
			{
				label: 'Export documenti',
				permesso: ['Export'],
				link: '/backoffice/export'
			},
			{
				label: 'Registro email',
				permesso: ['LetturaEmail'],
				link: '/backoffice/mail'
			},
			{
				label: 'Feedback utenti',
				permesso: ['Feedback'],
				link: '/backoffice/feedback'
			}
		],
		'Manuali e informazioni': [
			{
				label: 'Manuali operativi e API',
				permesso: [],
				link: '/backoffice/manuali'
			},
			{
				label: 'Versioni del software',
				permesso: [],
				link: '/backoffice/patchnotes'
			}
		]
	};

	const [open, setOpen] = useState(true);
	const [currentOpen, openMenu] = useState(Object.keys(vociMenu)
		.find((e) => vociMenu[e].some((v) => v.link.split('?')[0] === path)));

	useEffect(() => {
		openMenu(Object.keys(vociMenu)
			.find((e) => vociMenu[e].some((v) => v.link.split('?')[0] === path)));
	}, [path]);

	return (
		<nav className={styles.nav} style={!open ? { width: "63px" } : {}}>
			<ul>
				<li>
					<button type="button" onClick={() => setOpen((o) => !o)}><i className="fa fa-bars" />{open && "Nascondi menu"}</button>
				</li>
				{
					Object.entries(vociMenu).filter(([key, value]) => value.length > 0)
						.map(([key, value]) => {
							return (
								<li key={key} className={currentOpen === key ? styles.active : ''}>
									<button type="button" onClick={() => openMenu(key)}>
										{open && <i className={`fa fa-angle-right ${currentOpen === key ? 'fa-rotate-90' : ''}`} />}
										{open ? key : key.substring(0, 2)}
										{key === 'Manuali e informazioni' && open && patchnotes && (!readedVersion || patchnotes[0].versione > readedVersion) && (
											<span style={{
												marginLeft: "10px",
												fontSize: "11px",
												background: "white",
												color: "#1d2d7e",
												borderRadius: "15px",
												fontWeight: 600,
												padding: "2px 12px"
											}}>
												Novità
											</span>
										)}
									</button>
									<ul>
										{
											value.filter(({ permesso }) => currentOpen === key && (permesso.length === 0 || (permesso.some((p) => permessi.includes(p)) || permessi.includes('All'))))
												.map((entry) => (
													<Link key={entry.link} to={entry.link}>
														<li className={path === entry.link.split('?')[0] ? styles.active : ''}>
															{open ? entry.label : entry.label.substring(0, 2)}
															{entry.link === '/backoffice/patchnotes' && open && patchnotes && (!readedVersion || patchnotes[0].versione > readedVersion) && (
																<span style={{
																	marginLeft: "10px",
																	fontSize: "11px",
																	background: "#1d2d7e",
																	color: "white",
																	borderRadius: "15px",
																	fontWeight: 600,
																	padding: "2px 12px"
																}}>
																	Novità
																</span>
															)}
														</li>
													</Link>
												))
										}
									</ul>
								</li>
							);
						})
				}
			</ul>
		</nav>
	);
}
