import classNames from "classnames";
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';


/* eslint-disable jsx-a11y/anchor-is-valid */
export default function HeaderProfilo(props) {
	const {
		menuAttivo
	} = props;

	const { t } = useTranslation('translation');

	const user = useSelector((state) => state.authentication.user);

	const isTablet = useMediaQuery("(max-width: 768px)");

	const [dropOpen, setDropOpen] = useState(false);

	const menu = [
		{
			id: 'profilo',
			url: '/area-personale',
			titolo: t('headerProfilo.areaPersonale')
		},
		{
			id: 'dati-anagrafici',
			url: '/area-personale/dati-anagrafici',
			titolo: t('headerProfilo.datiAnagrafici')
		},
		{
			id: 'carrello',
			url: '/area-personale/carrello',
			titolo: t('headerProfilo.carrello')
		},
		{
			id: 'posizione',
			url: '/area-personale/posizione-debitoria',
			titolo: t('headerProfilo.debiti')
		},
		{
			id: 'posizione-spontanea',
			url: '/area-personale/posizione-debitoria-spontanea',
			titolo: t('headerProfilo.pagamentiSpontanei')
		},
		{
			id: 'storico',
			url: '/area-personale/storico-pagamenti',
			titolo: t('headerProfilo.storicoPagamenti')
		}
	];

	const elementiMenu = menu.map((m) => (
		<li className="nav-item" key={`link-profilo-${m.id}`}>
			<Link
				className={classNames({
					'nav-link': true,
					active: menuAttivo === m.id
				})}
				to={m.url}
			>
				{m.titolo}
			</Link>
		</li>
	));

	return (
		<>
			<div id="header-profilo">
				<section className="bg-lightblue pt-3 pb-4 sezione-consulta">
					<div className="container">
						{user && (
							<div id="header-profilo-titolo" className="row mt-4 bg-white p-4">
								<div className="col-12 text-center">
									<h1>
										{user.intestatario.ragioneSociale ?? `${user?.intestatario.nome} ${user?.intestatario.cognome}`}
									</h1>
								</div>
								<div className="col-12 text-center p-0">
									<h5>
										{t('headerProfilo.autenticato')}
										<b>{` ${user?.ruolo.nome}`}</b>
									</h5>
								</div>
								<div id="header-profilo-logout">
									<Link to="/autenticazione/cambiaRuolo" style={{ marginRight: "25px" }}>{t('headerProfilo.cambiaRuolo')}</Link>
									<Link to="/autenticazione/logout">{t('headerProfilo.esci')}</Link>
								</div>
							</div>
						)}
						<div className="row bg-lightgrey">
							{
								user && (
									!isTablet
										? (
											<div id="header-profilo-menu" className="col-12 p-0">
												<ul className="nav nav-tabs auto">
													{elementiMenu}
												</ul>
											</div>
										)
										: (
											<div className="header-nav-mobile">
												<div className="header-nav-mobile">
													<button type="button" onClick={() => setDropOpen(!dropOpen)} className="button-carrello">
														{menu.find((m) => m.id === menuAttivo).titolo}
													</button>
												</div>
												<div className="header-nav-list-div-mobile" style={{ display: dropOpen ? "flex" : "none" }}>
													<ul className="header-nav-list-mobile">
														{elementiMenu}
													</ul>
												</div>
											</div>
										)
								)
							}
						</div>
					</div>
				</section>
			</div>
		</>
	);
}
