import { TextField, Autocomplete } from '@mui/material';
import { useFetch } from '../../../Hooks/useFetch';

export default function StrutturaSelect({ disabled, onChange, value, required, strutture, style }) {
	const selectedStruttura = strutture?.find((v) => v.cdr === value);

	return strutture && (
		<Autocomplete
			disablePortal
			autoHighlight
			disabled={disabled}
			id="combo-box-demo"
			className="w-100 mt-2"
			style={style}
			options={strutture ? strutture
				.map((struttura) => ({
					label: struttura.nome,
					cdr: struttura.cdr
				})) : []}
			isOptionEqualToValue={(option, v) => option.cdr === v.split(" - ")[0]}
			onChange={(event, item) => onChange(item?.cdr)}
			value={(selectedStruttura && `${selectedStruttura.cdr} - ${selectedStruttura.nome}`) || null}
			renderOption={(props, option) => (
				<li {...props} key={option.cdr}>{option.label}</li>
			)}
			renderInput={(fieldParams) => (
				<TextField {...fieldParams} required={required} label="Struttura" variant="standard" />
			)}
			ListboxProps={{ style: { maxHeight: '250px' } }}
			noOptionsText="Nessuna Opzione"
		/>
	);
}
