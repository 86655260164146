/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
export default function TitleBar(props) {
	const {
		titolo,
		sottotitolo,
		contenutoAddizionale,
		shortColumn
	} = props;

	return (
		<section>
			<div className="container pt-2 pb-4">
				<div className="row justify-content-between">
					<div className={shortColumn ? "col-12 col-md-6 mb-4" : "col-12 mb-4"}>
						<h1>
							{titolo}
						</h1>
						<p>{sottotitolo}</p>
						{shortColumn ? { contenutoAddizionale } : <i>{contenutoAddizionale}</i>}
					</div>
				</div>
			</div>
		</section>
	);
}
