import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { Chip } from "@mui/material";

export default function StyledDropzone({ onChange, value, accept, text, singleImport }) {
	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject
	} = useDropzone({ accept, onDrop: (f) => {
		if (singleImport) onChange(f[0]);
		else onChange([...(value ?? []), ...f]);
	} });

	const baseStyle = {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '20px',
		borderWidth: 2,
		borderRadius: 2,
		borderColor: '#eeeeee',
		borderStyle: 'dashed',
		backgroundColor: '#fafafa',
		color: '#bdbdbd',
		outline: 'none',
		transition: 'border .24s ease-in-out'
	};

	const activeStyle = {
		borderColor: '#2196f3'
	};

	const acceptStyle = {
		borderColor: '#00e676'
	};

	const rejectStyle = {
		borderColor: '#ff1744'
	};

	const style = useMemo(() => ({
		...baseStyle,
		...(isDragActive ? activeStyle : {}),
		...(isDragAccept ? acceptStyle : {}),
		...(isDragReject ? rejectStyle : {})
	}), [
		isDragActive,
		isDragReject,
		isDragAccept
	]);

	return (
		<div style={{ maxWidth: "1500px" }}>
			<div
				{...getRootProps({ style })}
				className="dropzone"
			>
				<input {...getInputProps()} />
				<p style={{ color: "black", fontSize: "larger", textAlign: "center" }}>{text}</p>
				<div>
					{value
						&& (singleImport
							? <Chip label={value.name} onDelete={() => onChange(null)} />
							: (React.Children.toArray(value.map((x, i) =>
								/* eslint-disable-next-line */
								<Chip label={x.name} onDelete={() => onChange(value.filter((v, ii) => ii !== i))} />))))}
				</div>
			</div>
		</div>
	);
}
