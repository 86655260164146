import classNames from 'classnames';

export default function OnClickLink(props) {
	const {
		children,
		className,
		onClick,
		disabled,
		mainLink,
		style
	} = props;

	return (
		<button
			type="button"
			className={`${mainLink ? "" : "on-click-link "}${className}`}
			style={mainLink ? { ...style, textAlign: "left" } : { ...style, textAlign: "left" }}
			onClick={() => onClick()}
			disabled={disabled}
		>
			{children}
		</button>
	);
}
