import Footer from './Layout/Footer';
import Header from './Layout/Header';
import RouteSwitch from './Routes/RouteSwitch';
import Feedback from "./Components/Common/Feedback";

export default function Portal() {
	return (
		<>
			<Header />
			<main>
				<RouteSwitch />
				<Feedback popper tributi={[]} show={false} />
			</main>
			<Footer />
		</>
	);
}
