/* eslint-disable react/no-array-index-key */
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import BoxesHomepage from '../Components/Common/BoxesHomepage';
import Ricerca from '../Components/Common/Ricerca';
import PlaceholderPage from '../Components/Mockup/PlaceholderPage';

export default function Home({ user }) {
	return (
		<div>
			<Ricerca ifEmpty={<BoxesHomepage />} />
		</div>
	);
}
