import React from "react";
import { TextField } from "@mui/material";

export default function CampoMarcaBolloSetting({ value, onChange }) {
	return (
		<div>
			<TextField
				label="Importo"
				variant="standard"
				type="number"
				className="w-100"
				value={value.importo}
				onChange={(v) => onChange({ ...value, importo: v.target.value })}
			/>
			<TextField
				label="Tipo bollo"
				variant="standard"
				type="text"
				className="w-100 mt-3"
				value={value.tipoBollo}
				onChange={(v) => onChange({ ...value, tipoBollo: v.target.value })}
			/>
			<TextField
				label="Nome voce"
				variant="standard"
				type="text"
				className="w-100 mt-3"
				value={value.nomeVoce}
				onChange={(v) => onChange({ ...value, nomeVoce: v.target.value })}
			/>
		</div>
	);
}
