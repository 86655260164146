import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

export default function CampoCheckboxSetting({ value, onChange }) {
	return (
		<div className="w-100 text-center mt-3">
			<FormControlLabel
				control={(
					<Checkbox
						label="Valore di default"
						variant="standard"
						checked={value.defaultValue}
						onChange={(v) => onChange({ ...value, defaultValue: v.target.checked })}
					/>
				)}
				label="Valore predefinito"
			/>
		</div>
	);
}
