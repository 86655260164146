import React, { createContext, useEffect, useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, AlertTitle, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Modal from '../Modal';
import SpinnyPage from "../SpinnyPage";
import configuration from '../../../configuration';
import handleErrors from '../../Utils/HandleErrors';
import useToken from "../../../Hooks/useToken";
import { removeEmptyObjectsEditCreateForm } from "../../../Utils/RemoveObjectFormEditCreate";

export const EditFormContext = createContext([null, () => {}]);
const initialState = 0;

const reducer = (state, action) => {
	switch (action.type) {
		case 'AddCounter':
			return state + 1;
		case 'DecreaseCounter':
			return state - 1;
		case 'ResetCounter':
			return 0;
		default:
			return state;
	}
};

export default function EditModForm({
	formElements, path, mode, data, ente,
	onCancel, onSuccess, entityName, defaultValue = {}, extraFieldNextToButton
}) {
	const [submitState, setSubmitState] = useState('idle');

	const [state, dispatch] = useReducer(reducer, null, () => initialState);

	const {
		register, handleSubmit, setError, setValue, getValues,
		formState: { errors }, control, reset, clearErrors
	} = useForm();

	const jwt = useToken();

	const boxStyle = {
		position: 'absolute', top: '50%', left: '50%',
		maxHeight: "800px", overflowY: "scroll",
		transform: 'translate(-50%, -50%)',
		width: 900,
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 4
	};

	useEffect(() => (data ? reset(data) : reset(defaultValue)), [data]);
	useEffect(() => setValue("ente.codiceEnte", ente), [ente]);

	const onSubmit = (formData) => {
		setSubmitState('loading');

		const filteredData = Object.keys(formData).reduce((acc, key) => (
			{ ...acc, [key]: removeEmptyObjectsEditCreateForm(formData[key]) }), {});

		const codiceEnte = (mode === 'create' && path === '/ente') ? null : (formData.ente?.codiceEnte ?? formData.codiceEnte ?? null);
		const selectedEnte = codiceEnte ? { Ente: codiceEnte } : {};
		const enteHeader = ente ? { Ente: ente } : selectedEnte;

		fetch(`${configuration.serverAddress}${path}/${mode === 'edit' ? 'patch' : 'create'}`, {
			method: mode === 'edit' ? 'PATCH' : 'POST',
			body: JSON.stringify(filteredData),
			headers: new Headers({
				Authorization: `Bearer ${jwt}`,
				...enteHeader,
				...configuration.defaultHeaders
			})
		}).then(async (response) => {
			if (response.status === 201) {
				setSubmitState('idle');
				if (onSuccess) onSuccess(formData);
			}
			if (response.status >= 400) {
				throw await response.json();
			}
		}).catch((error) => {
			setSubmitState('idle');
			handleErrors(error, setError);
		});
	};

	return (
		<EditFormContext.Provider value={[state, dispatch]}>
			<SpinnyPage addStyle={{ display: state === 0 ? "none" : "block" }} />
			<Modal open={mode !== false} onClose={onCancel} addStyle={{ display: state === 0 ? "block" : "none" }}>
				<Box sx={boxStyle}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<h2>
							{mode === 'create' ? 'Creazione ' : 'Modifica '}
							{entityName}
						</h2>
						<hr />
						<div className="pt-3 d-flex flex-row justify-content-around">
							{React.createElement(formElements, {
								register, control, mode, reset, setValue, getValues, errors
							})}
						</div>
						{
							errors.general && (
								<Alert severity="error" className="mt-3">
									<AlertTitle>{errors.general.title}</AlertTitle>
									{errors.general.description}
								</Alert>
							)
						}
						{/* eslint-disable-next-line */}
						<div align="center" className="mt-4 mb-1">
							<LoadingButton type="submit" onClick={() => clearErrors()} loading={submitState === 'loading'} variant="contained" className="mr-2">Conferma</LoadingButton>
							<LoadingButton onClick={onCancel} disabled={submitState === 'loading'} variant="outlined" className="ml-2">Annulla</LoadingButton>
						</div>
					</form>
				</Box>
			</Modal>
		</EditFormContext.Provider>
	);
}
