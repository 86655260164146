import React, { useState } from "react";
import {
	Box, FormControl, IconButton, InputLabel,
	MenuItem,
	Select,
	Tab,
	Tabs
} from "@mui/material";

export default function RegolaIf({ value, onChange, meta, currentField, campi }) {
	const [azione, setAzione] = useState("AzioneSetValore");
	const [expanded, setExpanded] = useState(false);
	const [tab, setTab] = useState('0');

	const conditionName = value.condizione["@class"].split(".").pop();

	return (
		<div>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs value={tab} onChange={(_, v) => setTab(v)} centered>
					<Tab label="Condizione" value="0" />
					<Tab label="Azioni" value="1" />
				</Tabs>
			</Box>
			<div hidden={tab !== "0"} className="mt-3">
				<FormControl variant="standard" style={{ width: "200px" }}>
					<InputLabel>Tipo di condizione</InputLabel>
					<Select variant="standard" value={conditionName} onChange={(v) => onChange({ ...value, condizione: meta.condizioni[v.target.value].dv })}>
						{
							Object.values(meta.condizioni).map((v) => (
								<MenuItem value={v.value} key={v.value}>{v.label}</MenuItem>
							))
						}
					</Select>
				</FormControl>
				{
					React.createElement(meta.condizioni[conditionName].component, {
						value: value.condizione,
						meta,
						campi,
						currentField,
						onChange: (curr) => onChange({ ...value, condizione: curr })
					})
				}
			</div>
			<div hidden={tab !== "1"}>
				{
					(value.azioni ?? []).map((act, i) => {
						const actionName = act["@class"].split(".").pop();
						const Component = meta.azioni[actionName].component;
						return (
							<div>
								<Component
									value={act}
									meta={meta}
									campi={campi}
									onChange={(curr) => onChange({
										...value, azioni: value.azioni.map((old, c) => (c === i ? curr : old))
									})}
									onDelete={() => onChange({
										...value, azioni: value.azioni.filter((_, c) => (c !== i))
									})}
								/>
							</div>
						);
					})
				}
				<div className="float-right mt-3 mb-4">
					<Select style={{ width: "200px" }} variant="standard" label="Tipo azione" value={azione} onChange={(v) => setAzione(v.target.value)}>
						{
							Object.values(meta.azioni).map((v) => (
								<MenuItem value={v.value} key={v.value}>{v.label}</MenuItem>
							))
						}
					</Select>
					<IconButton onClick={() => onChange({ ...value, azioni: [...value.azioni, meta.azioni[azione].dv] })}><i className="fas fa-fw fa-plus-circle" /></IconButton>
				</div>
			</div>
		</div>
	);
}
