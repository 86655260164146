/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { Button } from 'design-react-kit';
import { Controller, useForm } from "react-hook-form";
import axios from 'axios';
import { TextField } from "@mui/material";
import Breadcrumbs from '../Components/Common/Breadcrumbs';
import TitleBar from '../Components/Common/TitleBar';
import ModaleServizio from '../Components/CarrelloPagamento/ModaleServizio';
import CampoRicercaEnte from '../Components/Common/CampoRicercaEnte';
import { selectionArrayToObject } from '../../Utils/Data';

export default function PosizioneDebitoria() {
	const [isOpenServ, setIsOpenServ] = useState(null);
	const [noResult, setNoResult] = useState(null);
	const {	register, handleSubmit, formState: { errors }, clearErrors, control } = useForm();

	const getPagamento = (data) => {
		setNoResult(null);
		if (data.ente && data.codice && data.codfis) {
			const axiosParams = {
				method: "GET",
				url: `/api/debiti/iuv/${data.ente.ente}/${data.codice}?cod_fiscale=${data.codfis}`,
				headers: { "Content-Type": "application/json" }
			};
			axios(axiosParams)
				.then((response) => {
					const pagamento = response.data;
					setNoResult(false);
					setIsOpenServ({
						ente: data.ente,
						iuv: data.iuv,
						codfis: data.codfis,
						idPayment: pagamento.stato && pagamento.stato === "Eseguito" ? pagamento.id : null
					});
				})
				.catch((e) => {
					setNoResult(true);
				});
		} else {
			setNoResult(true);
		}
	};

	const breadcrumbs = [
		{ titolo: 'posizione debitoria' }
	];

	return (
		<>
			<Breadcrumbs breadcrumbs={breadcrumbs} />
			<TitleBar titolo="Posizione Debitoria" />
			<section id="profilo-utente" className="pt-4 pb-4 sezione-consulta">
				<div className="container p-0">
					<div>
						<form onSubmit={handleSubmit(getPagamento)} className="row my-4">
							<div className="col-3">
								<Controller
									name="ente"
									control={control}
									defaultValue=""
									rules={{
										required: true,
										validate: (v) => v.ente !== undefined
									}}
									render={({ field: { onChange } }) => (
										<CampoRicercaEnte
											placeholder="Ente"
											onSelectionChange={(e) => {
												onChange(selectionArrayToObject(e));
											}}
										/>
									)}
								/>
								{errors.ente && <div role="alert"><p style={{ color: "red" }} className="my-1">Il campo ente è obbligatorio</p></div>}
							</div>
							<div className="col-3">
								<TextField
									{...register("codfis", { pattern: /^[0-9]{11}$|^[0-9A-Z]{16}$/, maxLength: 16, minLength: 11, required: true })}
									label="Codice fiscale"
									inputProps={{ maxLength: 16 }}
									variant="standard"
									className="w-100 input-con-descrizione"
								/>
								{errors.codfis && (
									<div role="alert">
										<p style={{ color: "red" }} className="my-1">Il codice fiscale inserito non è valido</p>
									</div>
								)}
							</div>
							<div className="flex-grow-1">
								<TextField
									{...register("codice", { pattern: /^[0-9]+$/, required: true, maxLength: 18, minLength: 15 })}
									inputProps={{ maxLength: 18 }}
									label="IUV / Numero avviso"
									variant="standard"
									className="w-100 input-con-descrizione"
								/>
								{errors.codice && (
									<div role="alert">
										<p style={{ color: "red" }} className="my-1">Il codice IUV / Numero avviso inserito non è valido</p>
									</div>
								)}
							</div>
							<div className="ml-4 mt-2">
								<Button type="submit" onClick={() => clearErrors()} variant="contained" color="primary">Cerca</Button>
							</div>
						</form>
					</div>
				</div>
				{noResult === true && (
					<div className="container p-0">
						<div className="row my-4">
							<div className="col-12 alert alert-info my-2" role="alert">
								Nessun risultato trovato.
							</div>
						</div>
					</div>
				)}
			</section>
			<ModaleServizio
				isOpen={isOpenServ != null}
				setIsOpen={() => { setIsOpenServ(null); }}
				paymentData={isOpenServ}
			/>
		</>
	);
}
