/* eslint-disable import/prefer-default-export */
/* eslint-disable no-useless-escape */
import * as yup from "yup";
import i18n from "../i18n";

export const regexCausale = /^[\w!@#$%^&*()+\-=\[\]{};':"\\| ,.<>\/?]*$/;
export const regexCodiceFiscaleIva = /^(?:([A-Z]{6}[0-9A-Z]{2}[A-Z][0-9A-Z]{2}[A-Z][0-9A-Z]{3}[A-Z])|([0-9]{11})|STRANIERO)$/;

export const eRequired = i18n.t('errors.required');
export const eCausaleReg = i18n.t('errors.causaleReg');
export const eCausaleLen = i18n.t('errors.causaleLen');
export const eComuneLen = i18n.t('errors.comuneLen');
export const eIndirizzoLen = i18n.t('errors.indirizzoLen');
export const eNoteVersanteLen = i18n.t('errors.noteVersanteLen');
export const eCodiceFiscaleIvaReg = i18n.t('errors.codiceFiscaleIvaReg');
export const eNomeLen = i18n.t('errors.nomeLen');
export const eCognomeLen = i18n.t('errors.cognomeLen');
export const eEmailVal = i18n.t('errors.emailVal');

export const vCdr = yup.string().default(null).required(i18n.t('errors.scegliereStruttura'));
export const vImporto = yup.number().required(eRequired).min(0.01, i18n.t('errors.importoMin')).default(0.01);
export const vCausale = yup.string().required(eRequired).matches(regexCausale, eCausaleReg).max(140, eCausaleLen);
export const vComune = yup.string().required(eRequired).max(35, eComuneLen);
export const vIndirizzo = yup.string().required(eRequired).max(70, eIndirizzoLen);
export const vNoteVersante = yup.string().max(2000, eNoteVersanteLen);
export const vEmail = yup.string(i18n.t('errors.required')).required(eRequired).email(eEmailVal);
export const vPrivacy = yup.boolean().oneOf([true], i18n.t('errors.privacyRequired'));

export const vCfIvaStraniero = yup.string().required(eRequired).default("");
export const vCfIva = yup.string().required(eRequired).matches(regexCodiceFiscaleIva, eCodiceFiscaleIvaReg).default("");
export const vNome = yup.string().required(eRequired).max(35, eNomeLen);
export const vCognome = yup.string().required(eRequired).max(35, eCognomeLen);

export const vDebitore = yup.object().shape({
	personaFisica: yup.boolean(),
	nome: yup.string().default("").when("personaFisica", { is: true, then: yup.string().required("Il campo nome è obbligatorio.") }),
	cognome: yup.string().default("").when("personaFisica", { is: true, then: yup.string().required("Il campo cognome è obbligatorio.") }),
	cfIva: yup.string().default("").when("personaFisica", { is: true, then: yup.string().default("").required("È necessario inserire la partita IVA o il codice fiscale.") }),
	denominazione: yup.string().default("").when("personaFisica", { is: false, then: yup.string().required("Inserire la denominazione della persona giuridica.") }),
	partitaIva: yup.string().default("").when(["personaFisica", "codiceFiscale"], { is: (a, b) => !a && (!b || b === ""), then: yup.string().required("È necessario inserire o la partita IVA o il codice fiscale.") }),
	codiceFiscale: yup.string().default("").when(["personaFisica", "partitaIva"], { is: (a, b) => !a && (!b || b === ""), then: yup.string().required("È necessario inserire o la partita IVA o il codice fiscale.") })
}, [['codiceFiscale', 'partitaIva']]).default({ });

export const defaultPaymentFormValidator = yup.object({
	importo: vImporto,
	causale: vCausale,
	debitore: vDebitore,
	comune: vComune,
	indirizzo: vIndirizzo,
	noteVersante: vNoteVersante
});

export const paymentValidationSet = yup.object({
	versante: yup.object({
		email: vEmail,
		cfIva: vCfIva
	})
});

export const paymentProcessValidation = yup.object({
	cfIva: vCfIva,
	email: vEmail.nullable()
});
