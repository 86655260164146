/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from "@mui/material";
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import Logo from './Logo';


/* eslint-disable react/jsx-one-expression-per-line */
export default function HeaderMenu(props) {
	const user = useSelector((state) => state.authentication.user);
	const { isSticky, setRicerca } = props;
	const [isOpen, setIsOpen] = useState(false);

	const path = useLocation().pathname;
	const menuMedia = useMediaQuery("(max-width: 700px)");
	const isTablet = useMediaQuery("(max-width: 768px)");
	const { t } = useTranslation('translation');

	useEffect(() => {
		function handeResize() {
			setIsOpen(false);
		}

		window.addEventListener('resize', handeResize);
		return () => {
			window.removeEventListener('resize', handeResize);
		};
	});

	return (
		<div className="it-header-navbar-wrapper d-print-none">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<nav className="navbar navbar-expand-lg has-megamenu navbar-burger">
							<button onClick={() => { setIsOpen(true); }} className="custom-navbar-toggler" type="button" aria-controls="nav10" aria-expanded="false" aria-label="Toggle navigation" data-target="#nav10">
								<img src="./images/hamburger.svg" alt="menu" className="icon icon-cerca" />
							</button>
							<div className={classNames({ 'navbar-collapsable': true, expanded: isOpen })} id="nav10" style={{ marginTop: isTablet ? "30px" : "" }}>
								<div className="overlay" onClick={() => { setIsOpen(false); }} />
								<div className="close-div" style={{ marginRight: menuMedia ? "7em" : "" }}>
									<button onClick={() => { setIsOpen(false); }} className="btn close-menu" type="button">
										<i className="fas fa-window-close" />
									</button>
								</div>
								<div className="menu-wrapper align-items-stretch" style={{ marginRight: menuMedia ? "7em" : "" }}>
									<div className={classNames({ 'menu-barra-logo': true, 'is-sticking': isSticky })}>
										<Logo />
									</div>
									<ul className="navbar-nav" style={{ flex: 1 }}>
										{user && (
											<li className="nav-item">
												<Link className={path.includes("/area-personale") && !path.endsWith("storico-pagamenti") ? "nav-link nav-link-active" : "nav-link"} to="/area-personale">
													<span>Home</span>
												</Link>
											</li>
										)}
										<li className="nav-item">
											<Link className={(path === "/" || path.startsWith("/pagamento")) ? "nav-link nav-link-active" : "nav-link"} to="/">
												<span>{t('headerMenu.navBarPagamento')}</span>
											</Link>
										</li>
										{user ? (
											<li className="nav-item">
												<Link className={path === "/area-personale/storico-pagamenti" ? "nav-link nav-link-active" : "nav-link"} to="/area-personale/storico-pagamenti">
													<span>{t('headerMenu.navBarStorico')}</span>
												</Link>
											</li>
										) : (
											<li className="nav-item">
												<Link className={path === "/storico-pagamenti" ? "nav-link nav-link-active" : "nav-link"} to="/storico-pagamenti">
													<span>{t('headerMenu.navBarStorico')}</span>
												</Link>
											</li>
										)}
										<li className="nav-item">
											<Link className={(path === "/enti" || path.startsWith("/servizi") || path.startsWith("/strutture")) ? "nav-link nav-link-active" : "nav-link"} to="/enti">
												<span>{t('headerMenu.navBarEnti')}</span>
											</Link>
										</li>
										<li className={`nav-item ${isTablet ? "" : "ml-auto"}`}>
											<Link className={path === "/progetto" ? "nav-link nav-link-active" : "nav-link"} to="/progetto">
												<span>{t('headerMenu.navBarProgetto')}</span>
											</Link>
										</li>
									</ul>
									<div className="it-search-wrapper d-none d-lg-flex justify-content-center" style={{ top: 0 }}>
										<a className="search-link rounded-icon" href="#" onClick={() => setRicerca(true)} aria-label="Cerca" data-toggle="modal" data-target="#ricercaModal">
											<i className="fa fa-search" />
										</a>
									</div>
								</div>
							</div>
						</nav>
					</div>
				</div>
			</div>
		</div>
	);
}
