/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-wrap-multilines */
import React from "react";
import { Tooltip } from "antd";
import { Button } from "design-react-kit";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import SpinnyPage from "../../../Backoffice/Components/SpinnyPage";

export default function DataViewer(
	{ head, data, buttons, tipo, totale }
) {
	const isMobileTablet = useMediaQuery("(max-width: 576px)");
	const isTablet = useMediaQuery("(max-width: 768px)");
	const isLaptop = useMediaQuery("(max-width: 1024px)");

	const header = React.Children.toArray(head.map((h) => (
		<th>{h}</th>
	)));

	const totaleImporti = totale ?? data?.reduce((acc, v) => acc + parseFloat(v.importo[0].split(" ")[0]), 0) ?? 0;

	const filterEmptyButtons = buttons.map((buttSet) => Object.keys(buttSet).reduce((acc, key) => {
		if (buttSet[key] != null) acc[key] = buttSet[key];
		return acc;
	}, {}));

	const allRows = React.Children.toArray(data?.map((d, i) => (
		<tr style={{ height: "80px" }}>
			{
				React.Children.toArray(Object.values(d)?.map((val, j) => (
					<td
						style={head[j] === "Causale" ? { verticalAlign: "middle", wordBreak: "break-word" } : { verticalAlign: "middle" }}
						className={val[1] === "large" ? "w-100" : "col-1"}
					>
						{val[0]}
					</td>
				)))
			}
			{filterEmptyButtons && filterEmptyButtons.length > 0
				&& (
					<td style={{ verticalAlign: "middle" }}>
						<div className="carrello-bottoni">
							{
								React.Children.toArray(Object.entries(filterEmptyButtons[i])
									.map(([key, value]) => (
										Object.keys(value).length > 0
											? key.startsWith("link")
												? (
													<Tooltip title={value.tooltip ?? null}>
														<span className="carrello-bottoni" style={isMobileTablet ? { width: "100%" } : {}}>
															<Link
																to={value.link}
																onClick={value.function}
																className={value.text === "Elimina" ? "btn-outline-danger nowrap" : "nowrap"}
															>
																{value.text}
															</Link>
														</span>
													</Tooltip>
												)
												: (
													<Tooltip title={value.tooltip ?? null}>
														<span className="carrello-bottoni" style={isMobileTablet ? { width: "100%" } : {}}>
															<Button
																onClick={value.function}
																disabled={value.function === null}
																{...(value.text === "Elimina" ? {} : { color: "primary" })}
																className={value.text === "Elimina" ? "btn-outline-danger data-viewer-button-cancel nowrap" : "nowrap"}
															>
																{value.text}
															</Button>
														</span>
													</Tooltip>
												)
											: <></>
									)))
							}
						</div>
					</td>
				)}
		</tr>
	)));

	const table = (
		<table className="table bg-white">
			<thead>
				<tr>{header}</tr>
			</thead>
			<tbody>
				{allRows}
				{tipo === "feedback"
					? (
						<>
							<tr className="table bg-white">
								<th colSpan="2" className="table-borderless">Totale pagamento</th>
								<td />
								<td />
								<td colSpan="2" className="table-borderless">
									<strong>{`${totaleImporti} €`}</strong>
								</td>
							</tr>
						</>
					)
					: <></>}
			</tbody>
		</table>
	);

	const cardsTable = (
		<>
			{React.Children.toArray(data?.map((d, i) => (
				<div className="col-12 carrello-elemento">
					{
						React.Children.toArray(Object.values(d).map((val, j) => (
							<div className="mt-3 mb-3">
								{
									isMobileTablet
										? (
											<>
												<p className="carrello-elemento-label">
													{head[j]}
												</p>
												{val[0]}
											</>
										)
										: (
											<>
												<span className="carrello-elemento-label">{`${head[j]}: `}</span>
												{val[0]}
											</>
										)
								}
							</div>
						)))
					}
					{filterEmptyButtons && filterEmptyButtons.length > 0
						&& (
							<div className="carrello-elemento-bottoni">
								{
									React.Children.toArray(Object.entries(filterEmptyButtons[i])
										.map(([key, value], j) => (
											Object.keys(value).length > 0
												? key.startsWith("link")
													? (
														<Tooltip title={value.tooltip ?? null}>
															<span className="carrello-bottoni" style={isMobileTablet ? { width: "100%" } : {}}>
																<Link
																	to={value.link}
																	onClick={value.function}
																	className={
																		j === Object.keys(filterEmptyButtons[i]).length - 1
																			? value.text === "Elimina"
																				? "carrello-elemento-bottone-bot-cancel btn-outline-danger nowrap"
																				: "carrello-elemento-bottone-bot nowrap"
																			: value.text === "Elimina"
																				? "carrello-elemento-bottone-top-cancel btn-outline-danger nowrap"
																				: "carrello-elemento-bottone-top nowrap"
																	}
																>
																	{value.text}
																</Link>
															</span>
														</Tooltip>
													)
													: (
														<Tooltip title={value.tooltip ?? null}>
															<span className="carrello-bottoni" style={isMobileTablet ? { width: "100%" } : {}}>
																<Button
																	onClick={value.function}
																	disabled={value.function === null}
																	{...(value.text === "Elimina" ? {} : { color: "primary" })}
																	className={
																		j === Object.keys(filterEmptyButtons[i]).length - 1
																			? value.text === "Elimina"
																				? "carrello-elemento-bottone-bot-cancel btn-outline-danger data-viewer-button-cancel nowrap"
																				: "carrello-elemento-bottone-bot nowrap"
																			: value.text === "Elimina"
																				? "carrello-elemento-bottone-top-cancel btn-outline-danger data-viewer-button-cancel nowrap"
																				: "carrello-elemento-bottone-top nowrap"
																	}
																>
																	{value.text}
																</Button>
															</span>
														</Tooltip>
													)
												: <></>
										)))
								}
							</div>
						)}
				</div>)))}
			{tipo === "feedback"
				? (
					<div className="carrello-elemento-totale">
						<p className="carrello-elemento-label" style={{ margin: "10px" }}>Totale pagamento</p>
						<strong>
							{`${totaleImporti} €`}
						</strong>
					</div>
				)
				: <></>}
		</>
	);

	return (isTablet || tipo === "cart" || (isLaptop && tipo === "feedback")) ? cardsTable : table;
}
