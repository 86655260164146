/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import ListaCard from '../Common/ListaCard';
import ListaCardSingola from '../Common/ListaCardSingola';

import Paginazione from '../Common/Paginazione';
import ListaAlberoStrutture from './ListaAlberoStrutture';
import { getRootStructures } from "./deepMapping";

const matchesSearch = (struttura, search) => {
	if (struttura.nome.includes(search)) {
		return true;
	}
	if (struttura.figli.length) {
		for (let i = 0; i < struttura.figli.length; i++) {
			if (matchesSearch(struttura.figli[i], search)) {
				return true;
			}
		}
	}
	return false;
};

export default function ListaStrutture(props) {
	const {
		codiceEnte,
		codiceTributo,
		strutture,
		searchString
	} = props;

	const pageSize = useSelector((state) => state.pagination.perPage);
	const [page, setPage] = useState(1);

	const numElements = strutture.length;
	const minIndex = pageSize * (page - 1);
	let maxIndex = pageSize * (page);
	if (maxIndex > numElements) {
		maxIndex = numElements;
	}

	const numRisultati = strutture.length;
	const finalStrutture = useMemo(() => getRootStructures(strutture), [strutture]);

	const lista = finalStrutture
		.filter((e) => matchesSearch(e, searchString))
		.slice(minIndex, maxIndex)
		.map((e) => (
			<ListaCardSingola
				nome={e.nome}
				link={`/pagamento/${codiceEnte}/${codiceTributo}/${e.cdr}`}
				testoLink="Vai al servizio"
				key={`struttura-${e.cdrEnte}`}
				linkDisabled={!strutture.find((x) => x.cdr === e.cdr)}
			>
				<div className="strutture-organizzative" style={{ maxHeight: "200px", overflowY: "auto" }}>
					<ListaAlberoStrutture
						strutture={e.figli}
						codiceEnte={codiceEnte}
						codiceTributo={codiceTributo}
						struttureTributo={strutture.map((x) => x.cdr)}
					/>
				</div>
			</ListaCardSingola>
		));

	return (
		<>
			<section className="section section-inset-shadow pb-0 pt-2 pb-5">
				<div className="container pt-2 pb-4 px-0 px-md-3" id="content">
					<ListaCard
						risultatiTotali={numRisultati}
						elementi={lista}
						paginaCorrente={page}
						perPagina={pageSize}
					/>
					<Paginazione
						paginaCorrente={page}
						perPagina={pageSize}
						risultatiTotali={finalStrutture.length}
						onPageChange={(p) => setPage(p)}
					/>
				</div>
			</section>
		</>
	);
}
