import React from "react";
import { Controller, useWatch } from "react-hook-form";
import { Autocomplete } from "@mui/lab";
import { TextField } from "@mui/material";

export default function StruttureOrganizzative({ control, str }) {
	return str && (
		<Controller
			name="struttureAmmesse"
			defaultValue={[]}
			control={control}
			onChange={([, d]) => d}
			render={({ field: { onChange, value } }) => (
				<Autocomplete
					multiple
					id="tags-standard"
					options={str}
					getOptionLabel={(option) => option.cdr}
					isOptionEqualToValue={(option, v) => option.cdr === v.cdr}
					value={value || ''}
					onChange={(e, d) => onChange(d)}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="standard"
							label="Strutture organizzative"
							placeholder=""
						/>
					)}
				/>
			)}
		/>
	);
}
