/* eslint-disable import/prefer-default-export */
import axios from "axios";

const customHeader = {
	"Content-Type": "application/json"
};

function* apiCreateRequest(method, baseUrl, apiUrl, queryParams, payload, headers) {
	let pathUrl = baseUrl;
	if (apiUrl && apiUrl !== undefined && apiUrl.length > 0) pathUrl += apiUrl;

	let apiHeaders = customHeader;
	if (headers && headers !== undefined) apiHeaders = { ...apiHeaders, ...headers };

	let queryParamsString = null;
	if (queryParams && queryParams !== undefined) {
		const queryParamsArray = [];
		Object.entries(queryParams).forEach((val) => {
			const [key, value] = val;
			queryParamsArray.push(`${key}=${value}`);
		});
		queryParamsString = `?${queryParamsArray.join('&')}`;
	}

	if (queryParamsString && queryParamsString !== undefined) pathUrl += queryParamsString;

	if (pathUrl) {
		const axiosParams = {
			method,
			url: pathUrl,
			headers: apiHeaders,
			...(payload && { data: payload })
		};
		return yield axios(axiosParams);
	}
	return { status: 400, message: "missing url parameter" };
}

export function* createRequest(method, baseUrl, apiUrl, queryParams, payload, headers) {
	try {
		const response = yield apiCreateRequest(method, baseUrl, apiUrl, queryParams, payload, headers);
		return response;
	} catch (e) {
		console.error(e);
		throw e;
	}
}
