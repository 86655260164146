/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import Highlighter from "react-highlight-words";
import {
	Alert,
	Divider,
	FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Switch, TextField, Typography
} from "@mui/material";
import moment from "moment";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";

import ToolsArea from "../Components/ToolsArea";
import { useFetch } from "../../Hooks/useFetch";
import BigDataTable from "../Components/Tables/BigDataTable";
import DataTableColumn from "../Components/Tables/DataTableColumn";
import EnteSelect from "../Components/Forms/EnteSelect";
import RangePicker from "../Components/Forms/RangePicker";
import TributoSelect from "../Components/Forms/TributoSelect";
import StrutturaSelect from "../Components/Forms/StrutturaSelect";
import Export from "../Components/Export";
import TransazioneFields from "../Utils/TransazioneFields";
import { removeEmptyObjects } from "../../Utils/Object";
import PluginManager from "../../Plugins/PluginManager";
import SpinnyPage from "../Components/SpinnyPage";
import DataGrid from "../Components/Tables/DataGrid";
import useTableColumns from "../Utils/useTableColumns";
import { DataGridSelectColumn } from "../Components/Tables/DataGridSelect";
import RendicontazioniFields from "../Utils/RendicontazioniFields";

export default function Rendicontazioni({ account }) {
	const [selectedRows, setSelectedRows] = useState(new Map());
	const [refresh, setRefresh] = useState(0);
	const [query, setQuery] = useState({ page: 0, pageSize: 20 });
	const [openExport, setOpenExport] = useState(false);
	const history = useHistory();

	const selected = [...selectedRows];

	const enteOperatore = account.ente?.codiceEnte;

	const filteredQuery = Object.keys(query).reduce((acc, key) => (
		{ ...acc, [key]: removeEmptyObjects(query[key]) }), {});

	const { data, status } = useFetch('/flussi/query', null, 'POST', filteredQuery, refresh);

	const { visibleColumns, allColumns, setVisible, visible, setOrder, sort, sortFilter, setSort } = useTableColumns("rendicontazioni", [
		{
			key: "dettagli",
			name: "",
			frozen: true,
			draggable: false,
			resizable: false,
			minWidth: 54,
			width: 54,
			formatter: ({ row }) => <IconButton style={{ fontSize: "20px" }} onClick={() => { history.push(`/backoffice/pagamenti?page=0&pageSize=20&transazione%5BallInterm%5D=true&versamento%5Bsospeso%5D%5Brendicontazione%5D%5BidentificativoFlusso%5D=${row.identificativoFlusso}`); }}><i className="fa fa-search" /></IconButton>
		},
		!enteOperatore && { key: "codiceEnte", name: "Codice ente", sortColumn: "ente.codiceEnte", sortable: true, frozen: false /*true*/, draggable: true, resizable: true, highlight: true, minWidth: 200, width: 240 },
		{ key: "identificativoFlusso", name: "Identificativo flusso", sortColumn: "identificativoFlusso", sortable: true, frozen: false /*true*/, draggable: true, resizable: true, highlight: true, minWidth: 300, width: 300 },
		{ key: "dataOra", name: "Ricezione", sortColumn: "dataOra", sortable: true, frozen: false, draggable: true, resizable: true, highlight: true, minWidth: 200, width: 200 },
		{ key: "importo", name: "Importo", sortColumn: "importo", sortable: true, frozen: false, draggable: true, resizable: true, highlight: true, minWidth: 100, width: 100 },
		{ key: "nomeMittente", name: "Nome banca", sortColumn: "nomeMittente", sortable: true, frozen: false, draggable: true, resizable: true, highlight: true, minWidth: 200 },
		{ key: "pagamenti", name: "Numero pagamenti", sortable: false, frozen: false, draggable: true, resizable: true, highlight: true, minWidth: 100, width: 150 }
	], ["dettagli", !enteOperatore && "codiceEnte", "identificativoFlusso", "dataOra", "importo", "nomeMittente", "pagamenti"], [{ columnKey: "dataOra", direction: "DESC" }]);

	useEffect(() => setQuery((q) => ({ ...q, orderBy: sortFilter })), [JSON.stringify(sortFilter)]);

	const tableData = data?.data?.map((r) => ({
		identificativoFlusso: r.identificativoFlusso,
		dataOra: moment(r.dataOra).format("DD/MM/yyyy HH:mm:SS"),
		codiceEnte: r.codiceEnte,
		importo: `${r.importo}€`,
		nomeMittente: r.nomeMittente,
		pagamenti: r.sospesi?.length.toString()
	}));

	const FilterFields = useMemo(() => ({ register: r, control: c, resetField }) => (
		<Grid container direction="column" width="100%" maxWidth="1200px" rowGap={3}>
			<Grid container direction="row" backgroundColor="#E8EDFC" borderRadius="5px" padding="0px 20px 10px 20px" flexWrap="nowrap" columnGap={3}>
				{!enteOperatore && (
					<Controller
						control={c}
						name="ente.codiceEnte"
						render={({ field: { onChange, value } }) => (
							<EnteSelect onChange={onChange} value={value} />
						)}
					/>
				)}
			</Grid>
			<Grid container direction="column" backgroundColor="#F7E8FC" borderRadius="5px" padding="5px 20px 10px 20px" flexWrap="nowrap" rowGap={3}>
				<Grid container direction="row" flexWrap="nowrap" columnGap={3} justifyContent="space-between" alignItems="center">
					<TextField {...r('identificativoFlusso')} label="Id flusso" variant="standard" style={{ flexShrink: 1, flexGrow: 1 }} />
				</Grid>
				<Grid container direction="row" flexWrap="nowrap" columnGap={3} justifyContent="space-between" alignItems="center">
					<Controller
						name="minImporto"
						control={c}
						required
						render={({ field: { onChange: change, v } }) => (
							<NumberFormat
								onValueChange={({ formattedValue, value }, sourceInfo) => change(value)}
								value={v}
								className=""
								label="Importo minimo"
								variant="standard"
								customInput={TextField}
								style={{ flexGrow: 1 }}
								prefix="€ "
								decimalScale={2}
								decimalSeparator=","
								thousandSeparator="."
							/>
						)}
					/>
					<Controller
						name="maxImporto"
						control={c}
						required
						render={({ field: { onChange: change, v } }) => (
							<NumberFormat
								onValueChange={({ formattedValue, value }, sourceInfo) => change(value)}
								value={v}
								className=""
								label="Importo massimo"
								variant="standard"
								customInput={TextField}
								style={{ flexGrow: 1 }}
								prefix="€ "
								decimalScale={2}
								decimalSeparator=","
								thousandSeparator="."
							/>
						)}
					/>
				</Grid>
				<Grid container direction="row" flexWrap="nowrap" columnGap={3} justifyContent="space-between" alignItems="center">
					<TextField {...r('nomeMittente')} label="Nome dell'istituto" variant="standard" style={{ flexShrink: 1, flexGrow: 1 }} />
					<RangePicker control={c} nameStart="inizioDataOra" nameEnd="fineDataOra" variant="standard" startText="Ricezione da" endText="Ricezione a" />
				</Grid>
			</Grid>
		</Grid>
	), [enteOperatore]);

	if (!data) return <SpinnyPage />;

	const canModify = ["All", "GestionePagamenti"].some((p) => account.ruolo.permessi.includes(p));

	return (
		<div>
			<Export
				open={openExport}
				columns={Object.values(RendicontazioniFields)}
				queryFilters={{ ...query, pageSize: null, page: null }}
				onDeny={() => setOpenExport(false)}
				onSuccess={() => history.push("/backoffice/export")}
				totalResults={data.totalResults}
				type={{
					name: "Rendicontazioni",
					apiName: "rendicontazioni",
					path: "it.ras.pagopa.shared.dto.system.prenotazione.PrenotazioneRendicontazione"
				}}
				initialSelected={[
					RendicontazioniFields.codiceEnte,
					RendicontazioniFields.identificativoFlusso,
					RendicontazioniFields.importo,
					RendicontazioniFields.dataOra,
					RendicontazioniFields.nomeMittente,
					RendicontazioniFields.numeroPagamenti
				]}
			/>
			<ToolsArea
				className="mt-4 mb-3"
				selected={selected}
				queryParameters={[
					"identificativoFlusso",
					"importo",
					"codiceMittente",
					"nomeMittente"
				]}
				disableDelete
				disableEdit
				disableNew
				enableExport
				exportCallback={() => setOpenExport(true)}
				filtersChanged={(filters) => setQuery({ ...query, ...filters })}
				fields={FilterFields}
				allColumns={allColumns}
				visibleColumns={visible}
				setVisibleColumns={setVisible}
				callStatus={status}
			/>
			<DataGrid
				columns={visibleColumns}
				rows={tableData}
				query={query?.query?.split(" ") ?? []}
				onSortColumnsChange={(col) => setOrder(col.map((c) => c.key))}
				rowKeyGetter={(row) => row.identificativoFlusso}
				totalResults={data.totalResults}
				selectedRows={selectedRows}
				setSelectedRows={setSelectedRows}
				setPage={(page) => setQuery({ ...query, page })}
				currPage={query?.page}
				sortData={sort}
				onSortDataChange={setSort}
			/>
			<br />
			<br />
			<br />
		</div>
	);
}
