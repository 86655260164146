/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import {
	FormControl,
	InputLabel,
	Autocomplete,
	MenuItem,
	Select,
	TextField
} from '@mui/material';
import Highlighter from "react-highlight-words";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useFetch } from '../../Hooks/useFetch';
import ToolsArea from '../Components/ToolsArea';
import DeleteItem from "../Components/DeleteItem";
import EditModForm, { EditFormContext } from "../Components/Forms/EditModForm";
import EnteSelect from "../Components/Forms/EnteSelect";
import useToken from "../../Hooks/useToken";
import configuration from "../../configuration";
import SpinnyPage from "../Components/SpinnyPage";
import useTableColumns from "../Utils/useTableColumns";
import { DataGridSelectColumn } from "../Components/Tables/DataGridSelect";
import DataGrid from "../Components/Tables/DataGrid";
import { DataGridExpandColumn } from "../Components/Tables/ExpandTreeGrid";
import ConfirmModal from "../Components/ConfirmModal";

const TipoStruttura = ({ control, nullValue }) => (
	<FormControl variant="standard" className="w-100">
		<InputLabel id="ruolo">Tipo struttura</InputLabel>
		<Controller
			name="tipo"
			control={control}
			defaultValue=""
			render={({ field: { onChange, value } }) => (
				<Select
					labelId="tipoEnte-label"
					id="tipo"
					label="Tipo"
					defaultValue=""
					inputProps={{ value: value === undefined ? (nullValue ? '' : 'Servizio') : value }}
					onChange={onChange}
				>
					{nullValue && <MenuItem value="">Nessun filtro</MenuItem>}
					<MenuItem value="Assessorato">Assessorato</MenuItem>
					<MenuItem value="DirezioneGenerale">Direzione generale</MenuItem>
					<MenuItem value="Servizio">Servizio</MenuItem>
				</Select>
			)}
		/>
	</FormControl>
);

const Stato = ({ control, nullValue }) => (
	<FormControl variant="standard" className="w-100">
		<InputLabel id="ruolo">Stato</InputLabel>
		<Controller
			name="stato"
			control={control}
			render={({ field: { onChange, value } }) => (
				<Select
					labelId="tipoEnte-label"
					id="stato"
					label="Stato"
					defaultValue="Attivo"
					inputProps={{ value: value === undefined ? (nullValue ? '' : 'Attivo') : value }}
					onChange={onChange}
				>
					{nullValue && <MenuItem value="">Nessun filtro</MenuItem>}
					<MenuItem value="Attivo">Attivo</MenuItem>
					<MenuItem value="Disattivo">Disattivo</MenuItem>
					<MenuItem value="Nascosto">Nascosto</MenuItem>
				</Select>
			)}
		/>
	</FormControl>
);

export default function Strutture({ account }) {
	const enteOperatore = account.ente?.codiceEnte;

	// - Table data
	const [refresh, setRefresh] = useState(0);
	const [query, setQuery] = useState({ page: 0, pageSize: 20 });
	const { data, errors, status: queryStatus } = useFetch('/struttura/query', null, 'POST', query, refresh);
	const [deleteOpen, setDeleteOpen] = useState(false);
	const [createEditMode, setCreateEditMode] = useState(false);
	const [selectedStruct, setSelectedStruct] = useState(null);
	const [selectedRows, setSelectedRows] = useState(new Map());

	const mapper = (r, level = 0, firstChild = false, lastChild = false) => ({
		id: `${r.cdr}///${r.codiceEnte}`,
		codiceEnte: r.codiceEnte ?? "",
		cdr: r.cdr ?? "",
		tipo: r.tipo ?? "",
		denominazione: r.nome ?? "",
		descrizione: r.descrizione ?? "",
		stato: r.stato ?? "",
		children: r.figli.map((d, i) => mapper(d, level + 1, i === 0, i === r.figli.length - 1)),
		firstChild,
		lastChild,
		isExpanded: false,
		level
	});

	const errorModal = errors && (
		<ul>
			{React.Children.toArray(errors.dettaglio.map((x) => <li>{x.description}</li>))}
		</ul>
	);

	const tableData = data?.data?.map((v, i) => mapper(v, 0, i === 0, i === data.data.length - 1));
	const maxDeep = (d) => 1 + Math.max(0, ...d.children.map((c) => maxDeep(c)));

	const { visibleColumns, allColumns, setVisible, visible, setOrder, sort, sortFilter, setSort } = useTableColumns("strutture", [
		{ ...DataGridExpandColumn },
		DataGridSelectColumn,
		!enteOperatore && { key: "codiceEnte", name: "Codice ente", sortColumn: "idStruttura.codiceEnte", sortable: true, frozen: false /*true*/, draggable: false, resizable: true, highlight: true, minWidth: 200, width: 240 },
		{
			key: "cdr",
			name: "CDR",
			sortColumn: "idStruttura.cdr",
			sortable: true,
			frozen: true,
			draggable: false,
			resizable: true,
			highlight: false,
			minWidth: 100,
			width: (Math.max(0, ...tableData?.map((c) => maxDeep(c)) ?? [0]) + 1) * 40,
			formatter: ({ row, query: q }) => (
				<span>
					{new Array(Math.max(0, row.level)).fill(0).map(() => "│  ")}
					<Highlighter searchWords={q} autoEscape textToHighlight={row.cdr} />
				</span>
			)
		},
		{ key: "tipo", name: "Tipo", sortColumn: "tipo", sortable: true, frozen: false, draggable: false, highlight: true, minWidth: 150, resizable: true, width: 150 },
		{ key: "denominazione", name: "Denominazione", sortColumn: "nome", sortable: true, draggable: false, resizable: true, highlight: true, minWidth: 300, width: 300 },
		{ key: "descrizione", name: "Descrizione", sortColumn: "descrizione", sortable: true, draggable: false, resizable: true, highlight: true },
		{ key: "stato", name: "Stato", sortColumn: "stato", sortable: true, draggable: false, resizable: true, width: 80 }
	], [DataGridExpandColumn.key, DataGridSelectColumn.key, !enteOperatore && "codiceEnte", "cdr", "tipo", "denominazione", "descrizione", "stato"]);

	useEffect(() => setQuery((q) => ({ ...q, orderBy: sortFilter })), [JSON.stringify(sortFilter)]);

	// - Prendo il token JWT
	const jwt = useToken();

	const linearizeData = (rows) => rows.reduce((acc, d) => [
		...acc, d, ...linearizeData(d.figli)
	], []);
	const linearizedData = data?.data ? linearizeData(data.data) : [];

	useEffect(() => {
		const action = async () => {
			if ([...selectedRows].length === 1) {
				const str = linearizedData.filter((d) => d.cdrEnte === [...selectedRows][0])[0];

				const response = await fetch(`${configuration.serverAddress}/struttura/${str.codiceEnte}/${str.cdr}`, {
					method: "GET",
					headers: new Headers({
						Authorization: `Bearer ${jwt}`,
						...configuration.defaultHeaders
					})
				});

				setSelectedStruct(await response.json());
			} else setSelectedStruct(null);
		};
		action();
	}, [createEditMode]);

	const editCreateForm = ({ register, control, mode }) => {
		const ente = useWatch({ name: 'ente.codiceEnte', control }) ?? account.ente?.codiceEnte;
		const { data: dataMenu, status } = useFetch('/struttura/query', ente, 'POST', { }, refresh);

		const [state, dispatch] = useContext(EditFormContext) ?? [null, () => {}];
		const previousState = useRef("");

		useEffect(() => {
			if (previousState.current !== status && status === "fetching") dispatch({ type: "AddCounter" });
			if (previousState.current !== status && status === "fetched") dispatch({ type: "DecreaseCounter" });
			if (previousState.current !== "fetching" && status === "idle") dispatch({ type: "ResetCounter" });
			previousState.current = status;
		}, [status]);

		const linearizeDataMenu = dataMenu?.data && linearizeData(dataMenu.data);

		return (
			<>
				<div className="w-100">
					{
						enteOperatore ?? (
							<Controller name="ente.codiceEnte" rules={{ required: true }} control={control} render={({ field: { onChange, value } }) => <EnteSelect onChange={onChange} value={value} disabled={mode === 'edit'} required />} />
						)
					}
					{(ente || enteOperatore) && (
						<Controller
							name="padre.cdr"
							rules={{ required: false }}
							render={({ field: { onChange, value } }) => (
								<Autocomplete
									required
									disablePortal
									autoHighlight
									disabled={mode === 'edit'}
									id="combo-box-demo"
									className="w-100 mt-2"
									options={linearizeDataMenu?.filter((x) => x.codiceEnte === ente)
										.map((str) => ({
											label: `${str.codiceEnte} | ${str.cdr} - ${str.nome}`,
											cdr: str.cdr
										})) ?? []}
									isOptionEqualToValue={(option, v) => option.cdr === v}
									onChange={(event, item) => {
										onChange(item && item.cdr);
									}}
									value={value || null}
									renderInput={(fieldParams) => (
										<TextField {...fieldParams} label="Struttura padre" variant="standard" />
									)}
								/>
							)}
							control={control}
						/>
					)}
					<TextField required {...register('cdr')} disabled={mode === 'edit'} label="CDR" inputProps={{ maxLength: 11 }} variant="standard" className="mt-2 w-100" id="cdr" />
					<TextField required {...register('nome')} label="Nome" inputProps={{ maxLength: 100 }} variant="standard" className="mt-2 w-100" id="nome" />
					<div className="mt-2"><TipoStruttura control={control} /></div>
					<div className="mt-2"><Stato control={control} /></div>
					<TextField required {...register('descrizione')} label="Descrizione" inputProps={{ maxLength: 2000 }} variant="standard" className="mt-2 w-100" id="descrizine" />
				</div>
			</>
		);
	};

	const FilterFields = [
		[
			(r) => (enteOperatore ? <></> : <TextField {...r('ente.codiceEnte')} label="Codice ente" variant="standard" inputProps={{ maxLength: 50 }} />),
			(r) => (<TextField {...r('cdr')} label="CDR" variant="standard" />),
			(r) => (<TextField {...r('nome')} label="Nome" variant="standard" />),
			(r, c) => (<div style={{ width: "190px" }}><TipoStruttura control={c} nullValue /></div>),
			(r, c) => (<div style={{ width: "150px" }}><Stato control={c} nullValue /></div>)
		]
	];

	const canModify = ["All", "GestioneStrutture"].some((p) => account.ruolo.permessi.includes(p));
	const selected = [...selectedRows];

	return (
		<div>
			<ConfirmModal
				open={errorModal}
				title="Errore nei campi ricerca"
				text={`Sono presenti i seguenti errori nei campi di ricerca avanzata:\n`}
				elements={errorModal}
				onConfirm={() => {
					setQuery({ page: 0, pageSize: 20 });
				}}
				confirmText="OK"
			/>
			<DeleteItem
				open={deleteOpen}
				query="/struttura/delete"
				entityNamePlural="strutture"
				entityNameSingle="struttura"
				items={selected.map((item) => {
					const str = linearizedData.find((x) => x.cdrEnte === item);
					return { cdr: str?.cdr, ente: { codiceEnte: str?.codiceEnte } };
				})}
				onCancel={() => setDeleteOpen(false)}
				onDeleted={() => {
					setDeleteOpen(false);
					setSelectedRows(new Map());
					setRefresh(refresh + 1);
				}}
				onError={() => setDeleteOpen(false)}
			/>
			<EditModForm
				path={`/struttura${createEditMode === 'edit' ? `/${linearizedData.find((x) => x.cdrEnte === selected[0]).codiceEnte}/${linearizedData.find((x) => x.cdrEnte === selected[0]).cdr}` : ''}`}
				entityName="Struttura organizzativa"
				ente={createEditMode === 'edit' ? linearizedData.find((x) => x.cdrEnte === selected[0]).codiceEnte : null}
				mode={createEditMode}
				data={createEditMode === 'edit' ? linearizedData.find((x) => x.cdrEnte === selected[0]) : null}
				onCancel={() => setCreateEditMode(false)}
				onSuccess={() => { setCreateEditMode(false); setRefresh(refresh + 1); }}
				formElements={editCreateForm}
			/>
			<ToolsArea
				className="mt-4 mb-3"
				selected={selected}
				setSelected={setSelectedRows} //Serve per svuotare gli operatori selezionati se effettuo una ricerca
				queryParameters={[
					'ente.codiceEnte', 'nome', 'descrizione', 'idStruttura.cdr'
				]}
				disableDelete={!canModify}
				disableEdit={!canModify}
				disableNew={!canModify}
				filtersChanged={(filters) => setQuery({ ...query, ...filters })}
				deleteCallback={() => setDeleteOpen(true)}
				createCallback={() => setCreateEditMode('create')}
				editCallback={() => setCreateEditMode('edit')}
				fields={FilterFields}
				allColumns={allColumns}
				visibleColumns={visible}
				setVisibleColumns={setVisible}
			/>
			{
				queryStatus === "fetching" ? <SpinnyPage /> : (
					<DataGrid
						columns={visibleColumns}
						rows={tableData ?? []}
						query={query?.query?.split(" ") ?? []}
						onSortColumnsChange={(col) => setOrder(col.map((c) => c.key))}
						rowKeyGetter={(row) => row.id}
						totalResults={data?.totalResults ?? 0}
						selectedRows={selectedRows}
						setSelectedRows={setSelectedRows}
						setPage={(page) => setQuery({ ...query, page })}
						currPage={query.page}
						sortData={sort}
						onSortDataChange={setSort}
					/>
				)
			}
			<br />
			<br />
			<br />
		</div>
	);
}
