/* eslint-disable  */
import { Controller, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import {
	Accordion, AccordionDetails,
	AccordionSummary, Button, Divider,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select, Switch, TextField,
	Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment as DateAdapter } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

export const TipoVoce = ({ ctr, name, onTypeChange }) => (
	<Controller
		name={name}
		defaultValue="Capitale"
		render={({ field: { onChange, value } }) => (
			<FormControl variant="standard" style={{ width: "140px" }}>
				<InputLabel id="tipoVoce">Tipo voce</InputLabel>
				<Select labelId="tipoVoce-label" id="tipoVoce" label="Tipo voce" value={value ?? 'Capitale'} onChange={(e) => { onChange(e); onTypeChange?.(e); }} defaultValue="Capitale">
					<MenuItem value="Capitale">Capitale</MenuItem>
					<MenuItem value="Codice">Codice</MenuItem>
					<MenuItem value="Interessi">Interessi</MenuItem>
					<MenuItem value="Sanzioni">Sanzioni</MenuItem>
					<MenuItem value="Altro">Altro</MenuItem>
				</Select>
			</FormControl>
		)}
	/>
);

export const StatoPagamento = ({ ctr, name, className, label }) => (
	<Controller
		name={name}
		defaultValue="Attiva"
		control={ctr}
		render={({ field: { onChange, value } }) => (
			<FormControl variant="standard" style={{ width: "140px" }} className={className}>
				<InputLabel id="statoPagamento">{label}</InputLabel>
				<Select labelId="statoPagamento-label" id="statoPagamento" label="Tipo voce" value={value ?? ''} onChange={onChange}>
					<MenuItem value="Attiva">Attiva</MenuItem>
					<MenuItem value="Annullata">Annullata</MenuItem>
				</Select>
			</FormControl>
		)}
	/>
);

export const IbanMultibeneficiario = ({ ctr, name, className, ibans }) => (
	<Controller
		name={name}
		defaultValue={null}
		control={ctr}
		render={({ field: { onChange, value } }) => (
			<FormControl variant="standard" className={className}>
				<InputLabel id="ibanMulti">Ente multibeneficiario</InputLabel>
				<Select labelId="ibanMulti-label" id="ibanMulti" label="Ente multibeneficiario" value={value ?? ''} onChange={onChange}>
					<MenuItem value={null}>Nessuno</MenuItem>
					{
						ibans
							&& Object.keys(ibans).map((iban) => <MenuItem value={iban}>{iban}</MenuItem>)
					}
				</Select>
			</FormControl>
		)}
	/>
);

export const FormVoce = ({ rataIndex }) => {
	const { register } = useFormContext();
	const { fields, append, remove } = useFieldArray({ name: `pendenze.${rataIndex}.dettagli` });

	const defaultVoce = (i) => ({
		beneficiario: null,
		capitoloEntrata: "",
		codiceAccertamento: "",
		codiceDettaglio: `Voce ${i}`,
		descrizione: "",
		importo: 0.01,
		posizioneAccertamento: "",
		stato: "Attiva",
		tipo: "Capitale",
		"@class": "it.ras.pagopa.shared.dto.pendenze.DettaglioNewDto"
	});

	useEffect(() => {
		if (fields.length === 0) append(defaultVoce(0));
	}, [fields.length]);

	const ibanAmmessi = useWatch({ name: "tributo.ibanAmmessi", defaultValue: true });

	const [voceOpen, setVoceOpen] = useState(0);

	const voci = useWatch({ name: `pendenze.${rataIndex}.dettagli` });

	return (
		<>
			{
				React.Children.toArray(fields.map((voce, i) => {
					const { identificativo, importo, tipo } = voci?.[i] ?? {};

					return (
						<Accordion expanded={voceOpen === i} onChange={() => setVoceOpen(i)} key={voce.id}>
							<AccordionSummary>
								<input type="hidden" {...register(`pendenze.${rataIndex}.dettagli.${i}.stato`, { value: 'Attiva' })} />
								<input type="hidden" {...register(`pendenze.${rataIndex}.dettagli.${i}.@class`, { value: 'it.ras.pagopa.shared.dto.pendenze.DettaglioNewDto' })} />
								<IconButton size="small" disabled={fields.length === 1} className="mr-2" onClick={() => remove(i)}>
									<i className="fas fa-fw fa-trash" />
								</IconButton>
								<Typography sx={{ width: '33%', flexShrink: 0, lineHeight: "28px" }}>
									<b>{tipo}</b>
									: €
									{importo}
								</Typography>
								<Typography sx={{ color: 'text.secondary', lineHeight: "28px" }}>{identificativo}</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<TipoVoce name={`pendenze.${rataIndex}.dettagli.${i}.tipo`} />
								<TextField {...register(`pendenze.${rataIndex}.dettagli.${i}.codiceDettaglio`)}
									required
									label="Codice voce"
									variant="standard"
									className="ml-4"
									style={{ width: "350px" }}
									defaultValue={`Voce Capitale ${i}`}
								/>
								<Controller
									name={`pendenze.${rataIndex}.dettagli.${i}.importo`}
									required
									render={({ field: { onChange: change, value: v } }) => (
										<NumberFormat
											onValueChange={({ value, floatValue }) => change(floatValue)}
											value={v}
											required
											id="filled-adornment-amount"
											className="mt-3 w-100"
											label="Importo"
											variant="standard"
											customInput={TextField}
											prefix="€ "
											decimalScale={2}
											decimalSeparator=","
											thousandSeparator="."
										/>
									)}
								/>
								<TextField
									{...register(`pendenze.${rataIndex}.dettagli.${i}.descrizione`)}
									label="Descrizione voce"
									variant="standard"
									className="mt-4 w-100"
								/>
								<TextField
									{...register(`pendenze.${rataIndex}.dettagli.${i}.capitoloEntrata`)}
									label="Capitolo entrata"
									variant="standard"
									className="mt-3"
									style={{ width: "150px " }}
								/>
								<TextField
									{...register(`pendenze.${rataIndex}.dettagli.${i}.codiceAccertamento`)}
									label="Cod. Accertamento"
									variant="standard"
									className="mt-3 ml-3"
									style={{ width: "200px " }}
								/>
								<TextField
									{...register(`pendenze.${rataIndex}.dettagli.${i}.posizioneAccertamento`)}
									label="# Accertamento"
									type="number"
									variant="standard"
									className="mt-3 ml-3"
									style={{ width: "150px " }}
								/>
								<IbanMultibeneficiario
									name={`pendenze.${rataIndex}.dettagli.${i}.beneficiario`}
									className="w-100 mt-3"
									ibans={ibanAmmessi}
								/>
							</AccordionDetails>
						</Accordion>
					);
				}))
			}
			<Button className="ml-auto ml-3 mt-3" onClick={() => append(defaultVoce(fields.length))}>
				AGGIUNGI VOCE
			</Button>
		</>
	);
};

export function DebitoreAnagrafica({ path }) {
	const personaFisica = useWatch({ name: `${path}.personaFisica` }) ?? true;
	const { register, setValue } = useFormContext();

	useEffect(() => {
		if (personaFisica) {
			setValue(`${path}.partitaIva`, "");
			setValue(`${path}.ragioneSociale`, "");
		} else {
			setValue(`${path}.codiceFiscale`, "");
			setValue(`${path}.nome`, "");
			setValue(`${path}.cognome`, "");
		}
	}, [personaFisica]);

	return (
		<>
			{personaFisica ? <b>Persona fisica</b> : "Persona fisica"}
			<Controller name={`${path}.personaFisica`} defaultValue render={({ field: { onChange, value } }) => <Switch onChange={(v) => onChange(!v.target.checked)} checked={!value} />} />
			{!personaFisica ? <b>Persona giuridica</b> : "Persona giuridica"}
			{
				personaFisica ? (
					<>
						<TextField {...register(`${path}.cognome`)} key="cognome" required label="Cognome debitore" inputProps={{ maxLength: 20 }} variant="standard" className="mt-1" style={{ width: "250px" }} />
						<TextField {...register(`${path}.nome`)} key="nome" required label="Nome debitore" inputProps={{ maxLength: 20 }} variant="standard" className="mt-1 ml-3" style={{ width: "220px" }} />
						<TextField {...register(`${path}.codiceFiscale`)} key="cf" required label="Codice Fiscale" inputProps={{ maxLength: 20 }} variant="standard" className="mt-2 w-100" />
					</>
				) : (
					<>
						<TextField {...register(`${path}.ragioneSociale`)} key="ragioneSociale" required label="Denominazione debitore" inputProps={{ maxLength: 256 }} variant="standard" className="mt-1 w-100" />
						<TextField {...register(`${path}.partitaIva`)} key="pIva" required label="Partita IVA" inputProps={{ maxLength: 11 }} variant="standard" className="mt-2 w-100" />
					</>
				)
			}
		</>
	);
}

export function AnagraficaDebitore({ enableMultidebitore }) {
	const [voceOpen, setVoceOpen] = useState(null);
	const { watch } = useFormContext();
	const { fields, append, remove } = useFieldArray({ name: "debitoriAlternativi" });

	return (
		<>
			<div className="w-100 mt-4 p-3 mb-3 rounded" style={{ border: "1px solid rgba(0,0,0,0.2)" }}>
				<DebitoreAnagrafica path="anagraficaDebitore" />
			</div>
			{
				enableMultidebitore && fields.map((value, index) => (
					<Accordion expanded={voceOpen === index} onChange={() => setVoceOpen(index === voceOpen ? null : index)}>
						<AccordionSummary style={{ alignItems: "center" }}>
							<IconButton size="small" className="mr-2" onClick={() => remove(index)}>
								<i className="fas fa-fw fa-trash m-2" />
							</IconButton>
							<p style={{ lineHeight: "20px", margin: "13px" }}>
								{((deb) => `${deb.nome != "" ? deb.nome : deb.ragioneSociale} ${deb.cognome} - ${deb.codiceFiscale != "" ? deb.codiceFiscale : deb.partitaIva}`)(watch(`debitoriAlternativi.${index}`))}
							</p>
						</AccordionSummary>
						<AccordionDetails>
							<DebitoreAnagrafica path={`debitoriAlternativi.${index}`} />
						</AccordionDetails>
					</Accordion>
				))
			}
			{enableMultidebitore && <Button className="mt-2 mb-4" onClick={() => append({ personaFisica: true, nome: "Nome", cognome: "Cognome", codiceFiscale: "CODICEFISCALE" })}>Aggiungi debitore</Button>}
		</>
	);
}

export function RateForm({ initialize }) {
	const { fields, append, remove } = useFieldArray({ name: "pendenze" });
	const { register } = useFormContext();
	const pendenze = useWatch({ name: `pendenze` });
	const [voceOpen, setVoceOpen] = useState(null);

	useEffect(() => {
		if (fields.length === 0 && initialize) {
			append({ numeroRata: 0, "@class": "it.ras.pagopa.shared.dto.pendenze.PendenzaNewDto", stato: "Attiva" });
		}
	}, []);

	useEffect(() => {
		if (fields.length === 1) {
			setVoceOpen(fields[0].id);
		}
	}, [fields]);

	return (
		<div>
			<Button className="mt-4 mb-4" variant="contained" onClick={() => append({ numeroRata: fields.length, stato: "Attiva", "@class": "it.ras.pagopa.shared.dto.pendenze.PendenzaNewDto" })}>Aggiungi rata</Button>
			{
				React.Children.toArray(fields.map((field, i) => (
					<Accordion expanded={voceOpen === field.id} onChange={() => setVoceOpen(field.id)}>
						<AccordionSummary style={{ alignItems: "center" }}>
							<IconButton
								size="small"
								disabled={i === 0}
								className="mr-2"
								onClick={() => remove(i)}
							>
								<i className="fas fa-fw fa-trash m-2" />
							</IconButton>
							<h4 style={{ lineHeight: "20px", marginTop: "10px" }}>
								{pendenze?.[i]?.numeroRata === 0 ? "Soluzione unica" : `Rata: ${pendenze?.[i]?.numeroRata}`}
								{pendenze?.[i]?.altRata <= 0 ? "" : ` - Alternativa: ${pendenze?.[i]?.altRata}`}
								<br />
								<span style={{ fontSize: "14px", fontWeight: "normal" }}>{pendenze?.[i]?.causale}</span>
							</h4>
							<Divider />
						</AccordionSummary>
						<AccordionDetails>
							<div className="d-flex flex-row mt-4">
								<div style={{ width: "570px" }} className="d-flex flex-column">
									<input type="hidden" {...register(`pendenze.${i}.stato`, { value: 'Attiva' })} />
									<div className="d-flex flex-row mt-2">
										<Controller
											name={`pendenze.${i}.numeroRata`}
											defaultValue="0"
											render={({ field: { onChange, value } }) => (
												<div className="d-flex flex-row">
													<FormControl variant="standard" style={{ width: "200px" }}>
														<InputLabel id="tipoPendenza">Tipo pendenza</InputLabel>
														<Select labelId="tipoPendenza-label" id="tipoPendenza" label="Tipo pendenza" value={value === 0 ? "SoluzioneUnica" : "Rata"} onChange={(v) => onChange(v.target.value === "SoluzioneUnica" ? 0 : 1)}>
															<MenuItem value="SoluzioneUnica">Soluzione unica</MenuItem>
															<MenuItem value="Rata">Rata</MenuItem>
														</Select>
													</FormControl>
													<TextField value={value} onChange={onChange} required label="Numero rata" type="number" inputProps={{ maxLength: 4 }} variant="standard" className="ml-4 w-50" />
												</div>
											)}
										/>

										<Controller
											name={`pendenze.${i}.altRata`}
											defaultValue="0"
											render={({ field: { onChange, value } }) => (
												<TextField value={value} onChange={onChange} required label="Rata alternativa" type="number" inputProps={{ maxLength: 4, readOnly: true }} variant="standard" className="ml-4 w-25" />
											)}
										/>
									</div>

									<LocalizationProvider dateAdapter={DateAdapter}>
										<div className="d-flex flex-row w-100 mt-4 mb-4 justify-content-between">
											<Controller
												name={`pendenze.${i}.inizioValidita`}
												defaultValue={moment().format("yyyy-MM-DD")}
												rules={{ required: true }}
												render={({ field: { onChange, value } }) => (
													<DesktopDatePicker
														label="Data di inizio validità *"
														inputFormat="DD/MM/yyyy"
														value={moment(value)}
														onChange={(v) => onChange(v.format("yyyy-MM-DD"))}
														renderInput={(params) => <TextField {...params} />}
													/>
												)}
											/>
											<Controller
												name={`pendenze.${i}.fineValidita`}
												defaultValue={moment().add(1, 'month').format("yyyy-MM-DD")}
												rules={{ required: true }}
												render={({ field: { onChange, value } }) => (
													<DesktopDatePicker
														label="Data di fine validità *"
														inputFormat="DD/MM/yyyy"
														value={moment(value)}
														onChange={(v) => onChange(v.format("yyyy-MM-DD"))}
														renderInput={(params) => <TextField {...params} />}
													/>
												)}
											/>
										</div>
										<Controller
											name={`pendenze.${i}.scadenza`}
											defaultValue={moment().add(1, 'week').format("yyyy-MM-DD")}
											rules={{ required: true }}
											render={({ field: { onChange, value } }) => (
												<DesktopDatePicker
													label="Data di scadenza *"
													inputFormat="DD/MM/yyyy"
													value={moment(value)}
													onChange={(v) => onChange(v.format("yyyy-MM-DD"))}
													renderInput={(params) => <TextField className="w-100 mt-3" {...params} />}
												/>
											)}
										/>
									</LocalizationProvider>
									<TextField {...register(`pendenze.${i}.causale`)} required label="Causale" inputProps={{ maxLength: 256 }} variant="standard" className="mb-3 mt-4 w-100" />
								</div>
								<div style={{ width: "570px" }} className="d-flex flex-column ml-5">
									<FormVoce rataIndex={i} />
								</div>
							</div>
						</AccordionDetails>
					</Accordion>
				)))
			}
		</div>
	);
}
