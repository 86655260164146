export default function InputConDescrizione(props) {
	const {
		titolo,
		obbligatorio,
		tipo,
		nome,
		placeholder,
		descrizione,
		readonly,
		value,
		onChange,
		onFocusChange,
		ref,
		errore,
		extraStyle,
		...rest
	} = props;

	return (
		// eslint-disable-next-line jsx-a11y/label-has-associated-control
		<label className="w-100 input-con-descrizione no-bot-margin" style={extraStyle}>
			{titolo && (
				<h5>
					{titolo}
					{obbligatorio && ' *'}
				</h5>
			)}
			<input
				{...rest}
				type={tipo}
				name={nome}
				placeholder={placeholder}
				className="form-control descrizione-input"
				readOnly={readonly}
				onBlur={() => { onFocusChange?.(false); }}
				onFocus={() => { onFocusChange?.(true); }}
				onChange={onChange}
				ref={ref}
				{...(value !== undefined ? { value } : {})}
			/>
			{
				(errore || descrizione) && (
					<p style={errore && { color: "red" }}>
						<i>{errore ?? descrizione}</i>
					</p>
				)
			}
		</label>
	);
}
