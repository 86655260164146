/* eslint-disable no-param-reassign */
import React, { useEffect, useMemo, useState } from "react";
import {
	Box,
	Divider,
	TextField
} from "@mui/material";
import * as yup from "yup";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { Alert, LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import NumberFormat from "react-number-format";
import PluginManager from "../../Plugins/PluginManager";
import EnteSelect from "../Components/Forms/EnteSelect";
import TributoSelect from "../Components/Forms/TributoSelect";
import StrutturaSelect from "../Components/Forms/StrutturaSelect";
import configuration from "../../configuration";
import { useFetch } from "../../Hooks/useFetch";
import PosSelect from "../Components/Forms/PosSelect";
import handleErrors from "../Utils/HandleErrors";
import useToken from "../../Hooks/useToken";
import { defaultPaymentFormValidator, vCdr, vPrivacy } from "../../DataModels/DebitoSpontaneo";
import BackofficeInputMap from "../../Plugins/Campi/Interfaces/BackofficeInputMap";

export default function PagamentoPos({ account }) {
	const [ente, setEnte] = useState(account.ente?.codiceEnte);
	const [tributo, setTributoCode] = useState(null);
	const [debitData, setDebitData] = useState(null);
	const jwt = useToken();

	// - Prendo tutti i plugin del tributo e li inizializzo
	const pluginManager = new PluginManager();
	const plugins = tributo?.plugins?.map((conf) => pluginManager.getPlugin(conf.codicePlugin, conf)) ?? [];

	const hasPluginCampi = plugins.filter((plugin) => plugin.configuration.codicePlugin === "CAMPI").length > 0;
	const hasStrutture = tributo?.struttureAmmesse?.length > 0;

	const baseValidator = {
		privacy: vPrivacy,
		...(hasPluginCampi ? {} : defaultPaymentFormValidator.fields),
		...(hasStrutture ? { cdr: vCdr } : {})
	};

	// - Prepara il validatore per il form tenendo conto dei plugin
	const formValidator = useMemo(() => plugins.reduce((validator, plugin) => yup.object({
		...validator.fields, ...plugin.backofficePaymentValidation(tributo).fields
	}), yup.object(baseValidator)), []);

	const paymentForm = useForm({
		resolver: yupResolver(formValidator),
		defaultValues: formValidator.getDefault()
	});

	useEffect(() => paymentForm.reset({ ...formValidator.getDefault(), tributo, codiceEnte: ente }), [tributo?.codiceTributo, ente]);

	const { data: posList } = useFetch('/pos/query', ente, 'POST', { page: 0, pageSize: null });
	let strutture = useWatch({ control: paymentForm.control, name: "tributo.struttureAmmesse", defaultValue: null });
	strutture = strutture?.filter((struttura) => (account?.strutture
		? Object.keys(account?.strutture).some((s) => s === struttura.cdr)
		: true));

	const submitFunction = async (data) => {
		const sendData = data;
		sendData.tributo = data.tributo.codiceTributo;

		// - Plugins pre submit elaboration
		const pluginElaboration = (reqData, plugin) => plugin.backofficePaymentSubmit(reqData);
		const requestData = plugins.reduce(pluginElaboration, { formValues: data, pluginData: [] });

		// - Reduce complex data into strings
		const { formValues } = requestData;
		const finalData = { pluginData: requestData.pluginData, formValues: Object.keys(formValues).reduce((fData, field) => {
			fData[field] = typeof formValues[field] === 'object' ? JSON.stringify(formValues[field]) : formValues[field];
			return fData;
		}, {}) };

		const response = await fetch(`${configuration.serverAddress}/pos/newDebit/${data.codiceEnte}/${data.tributo}`, {
			method: 'POST',
			body: JSON.stringify({ debito: finalData, idPos: data.idPos }),
			headers: new Headers({ Authorization: `Bearer ${jwt}`, 'Content-Type': 'application/json' })
		});

		if (response.status === 201) {
			setDebitData(response.json());
		}

		if (response.status >= 400) {
			const error = await response.json();
			if (error.dettaglio !== undefined) {
				error.dettaglio.forEach((errore) => paymentForm.setError(errore.field.split('.').pop(), {
					description: errore.description }));
			} else handleErrors(error, paymentForm.setError);
		}
	};

	return (
		<Box sx={{ background: "white", padding: "20px", marginTop: "20px", maxWidth: "900px" }}>
			<form onSubmit={paymentForm.handleSubmit(submitFunction)}>
				<FormProvider {...paymentForm}>
					<div className="mt-3">
						{account.ente?.codiceEnte ? "" : <EnteSelect required onChange={(e) => setEnte(e)} value={ente} />}
					</div>
					<div className="mt-3">
						<TributoSelect required onChange={setTributoCode} value={tributo} ente={ente} />
					</div>
					<div className="mt-3">
						<Controller name="cdr" render={({ field: { onChange, value } }) => <StrutturaSelect onChange={onChange} value={value} strutture={strutture ?? []} />} />
					</div>
					<div className="mt-3">
						<Controller name="idPos" render={({ field: { onChange, value } }) => <PosSelect onChange={onChange} required value={value} posList={posList?.data ?? []} />} />
					</div>
					<h3 className="mt-5">Dati pagamento</h3>
					<Divider className="mt-3" />
					{!hasPluginCampi && (
						<>
							<Controller
								name="importo"
								required
								render={({ field: { onChange: change, v } }) => (
									<NumberFormat
										onValueChange={({ formattedValue, value }, sourceInfo) => change(value)}
										value={v}
										required
										id="filled-adornment-amount"
										className="mt-3 w-50"
										label="Importo"
										variant="standard"
										defaultValue={null}
										customInput={TextField}
										prefix="€ "
										decimalScale={2}
										decimalSeparator=","
										thousandSeparator="."
									/>
								)}
							/>
							<TextField {...paymentForm.register("causale")} variant="standard" label="Causale del versamento" required className="w-100" />
							<Controller
								name="debitore"
								render={({ field: { onChange, value } }) => (
									<BackofficeInputMap.CampoCFIVA onChange={onChange} value={value} tipo="Entrambi" visible obbligatorio />
								)}
							/>
							<TextField {...paymentForm.register("comune")} variant="standard" label="Comune di residenza" required className="w-100" />
							<TextField {...paymentForm.register("indirizzo")} variant="standard" label="Indirizzo di residenza" required className="w-100" />
							<TextField {...paymentForm.register('noteVersante')} label="Note del debito" multiline rows={7} className="w-100 mt-4" />
						</>
					)}
					{plugins.map((plugin, i) => <plugin.backofficePaymentForm key={plugin.configuration.codicePlugin} config={plugin.configuration} path={`pluginData.${i}`} />)}
					<LoadingButton type="submit" onClick={() => paymentForm.clearErrors()} loading={paymentForm.formState.isSubmitting} variant="contained" className="ml-2 mt-3">Conferma</LoadingButton>
					{
						debitData && (
							<Alert severity="info" className="mt-3 w-100">
								Pos attivato sulla pendenza con
								<b>IUV: </b>
								{debitData.pendenze[0].iuv}
							</Alert>
						)
					}
					{
						Object.keys(paymentForm.formState.errors)?.map((k) => (
							<Alert severity="error" className="mt-3 w-100">
								{`${k}: ${paymentForm.formState.errors[k].description}`}
							</Alert>
						))
					}
				</FormProvider>
			</form>
		</Box>
	);
}
