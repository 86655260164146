import { useEffect } from "react";
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import Home from '../Pages/Home';
import Enti from '../Pages/Enti';
import Servizi from '../Pages/Servizi';
import NotFound from '../Pages/NotFound';
import ErrorPage from '../Pages/ErrorPage';

import ServizioPagamento from '../Pages/ServizioPagamento';
import PaginaProfilo from '../Pages/PaginaProfilo';
import Paga from '../Pages/Paga';
import PosizioneDebitoria from '../Pages/PosizioneDebitoria';
import StoricoPagamenti from '../Pages/StoricoPagamenti';
import Strutture from '../Pages/Strutture';
import Progetto from "../Pages/Progetto";
import EsitoPagamento from "../Pages/EsitoPagamento";

export default function Portal() {
	const { path, url } = useRouteMatch();
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	return (
		<Switch>
			<Route exact path={path}>
				<Home />
			</Route>
			<Route exact path={`${path}posizione-debitoria`}>
				<PosizioneDebitoria />
			</Route>
			<Route exact path={`${path}storico-pagamenti`}>
				<StoricoPagamenti portal />
			</Route>
			<Route exact path={`${path}storico-pagamenti/:codiceEnte/:codFis/:ccp`}>
				<StoricoPagamenti portal={false} />
			</Route>
			<Route exact path={`${path}progetto`}>
				<Progetto />
			</Route>
			<Route exact path={`${path}paga/:page?`}>
				<Paga />
			</Route>
			<Route exact path={`${path}enti/:page?`}>
				<Enti />
			</Route>
			<Route exact path={`${path}servizi/:ente/elenco/:page?`}>
				<Servizi />
			</Route>
			<Route exact path={`${path}servizi/elenco/:page?`}>
				<Servizi />
			</Route>
			<Route exact path={`${path}strutture/:ente/:servizio/:page?`}>
				<Strutture />
			</Route>
			<Route exact path={`${path}pagamento/:ente/:servizio/:struttura?`}>
				<ServizioPagamento />
			</Route>
			<Route path={`${path}area-personale`}>
				<PaginaProfilo />
			</Route>
			<Route path={`${path}esito`}>
				<EsitoPagamento />
			</Route>
			<Route path="*">
				<ErrorPage />
			</Route>
		</Switch>
	);
}
