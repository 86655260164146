import { useFetch } from "../Hooks/useFetch";

export function useProvince() {
	const { data } = useFetch("/comuni/province", null, "POST", {});
	return data?.sort() ?? [];
}

export function useComuni({ provincia, belfioreFilter }) {
	const { data } = useFetch("/comuni/query", null, "POST", { provincia, belfiore: belfioreFilter });
	return data?.data ?? [];
}
