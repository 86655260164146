/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import qs from "qs";
import {
	Alert,
	Divider,
	Grid, IconButton, Switch, TextField
} from "@mui/material";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import NumberFormat from "react-number-format";
import ToolsArea from "../Components/ToolsArea";
import { useFetch } from "../../Hooks/useFetch";
import EnteSelect from "../Components/Forms/EnteSelect";
import RangePicker from "../Components/Forms/RangePicker";
import Export from "../Components/Export";
import VociExportFields from "../Utils/VociExportFields";
import configuration from "../../configuration";
import useToken from "../../Hooks/useToken";
import ConfirmModal from "../Components/ConfirmModal";
import ModalAvvisi from "../Components/ModalAvvisi";
import ModalMail from "../Components/ModalMail";
import { Soluzione, StatoPagamento, StrutturaOrganizzativa, StatoAvvisiLabel } from "../Components/SearchFilters";
import SpinnyPage from "../Components/SpinnyPage";
import DataGrid from "../Components/Tables/DataGrid";
import useTableColumns from "../Utils/useTableColumns";
import { DataGridSelectColumn } from "../Components/Tables/DataGridSelect";
import DataLabel from "../../Utils/DataLabel";
import { removeEmptyObjects } from "../../Utils/Object";
import TributoSelect from "../Components/Forms/TributoSelect";
import FiltersViewer from "../Components/FiltersViewer";
// eslint-disable-next-line import/no-named-as-default
import PluginManager from "../../Plugins/PluginManager";

function TributoPick({ control, enteOperatore, fieldWatchName, name }) {
	const ente = useWatch({ control, name: fieldWatchName }) ?? enteOperatore;

	return (
		<Controller
			render={({ field: { onChange, value } }) => (
				<TributoSelect
					onChange={(v) => onChange(v)}
					value={value}
					style={{ flexShrink: 1 }}
					ente={ente}
					ricerca
				/>
			)}
			name={name}
			control={control}
		/>
	);
}

const FilterFields = ({ register: r, control: c, setValue, resetField }) => {
	const [personaFisica, setPersonaFisica] = useState(true);
	const tributo = useWatch({ control: c, name: "tributo" });
	const enteOperatore = useWatch({ control: c, name: "enteOperatore" });
	const codiceEnte = useWatch({ control: c, name: "ente.codiceEnte" });

	const codiciTributo = Array.isArray(tributo?.codiceTributo)
		? tributo?.codiceTributo.map((t) => t?.codiceTributo).filter((codice) => codice)
		: [];
	const ultimoTributo = codiciTributo.length > 0 ? codiciTributo[codiciTributo.length - 1] : null;

	const { data: tributoFetchInt } = useFetch(tributo?.codiceTributo && `/tributo/${enteOperatore ?? codiceEnte}/${ultimoTributo}/all`, null, 'GET');

	//const { data: tributoFetchInt } = useFetch(tributo?.codiceTributo && `/tributo/${enteOperatore ?? codiceEnte}/${tributo?.codiceTributo}/all`, null, 'GET');

	const plMan = new PluginManager();

	// - Recupero la configurazione del plugin e genero il plugin
	const pConf = tributoFetchInt?.plugins?.find((p) => p["@class"].split('.').pop() === 'CampiConfiguration');
	const currP = pConf ? plMan.getPlugin("CAMPI", pConf) : null;

	useEffect(() => {
		resetField("pluginData");
	}, [tributo?.codiceTributo]);

	useEffect(() => {
		resetField("tributo");
	}, [codiceEnte]);

	return (
		<Grid container direction="column" width="100%" maxWidth="1200px" rowGap={3}>
			<Grid container direction="row" backgroundColor="#E8EDFC" borderRadius="5px" padding="0px 20px 10px 20px" flexWrap="nowrap" columnGap={3}>
				{!enteOperatore && (
					<Controller
						control={c}
						name="ente.codiceEnte"
						render={({ field: { onChange, value } }) => (
							<EnteSelect onChange={onChange} value={value} />
						)}
					/>
				)}
				<TributoPick control={c} enteOperatore={enteOperatore} fieldWatchName="ente.codiceEnte" name="tributo.codiceTributo" />
				<StrutturaOrganizzativa name="struttura.cdr" ctr={c} enteOperatore={enteOperatore} />
			</Grid>
			<Grid container direction="column" backgroundColor="#F7E8FC" borderRadius="5px" padding="5px 20px 10px 20px" flexWrap="nowrap" rowGap={3}>
				<Grid container direction="row" flexWrap="nowrap" columnGap={3} justifyContent="space-between" alignItems="center">
					<Soluzione ctr={c} name="rate" />
					<Controller control={c} name="codiceDebito" render={({ field: { value, onChange } }) => <TextField value={value ?? ""} onChange={onChange} label="Id debito" variant="standard" style={{ flexShrink: 1, flexGrow: 1 }} />} />
					<RangePicker control={c} nameStart="inizioCreazione" nameEnd="fineCreazione" variant="standard" startText="Creazione da" endText="Creazione a" />
				</Grid>
				<Grid container direction="row" flexWrap="nowrap" columnGap={3} justifyContent="space-between" alignItems="center">
					<Controller
						render={({ field: { onChange, value } }) => (
							<TextField
								onChange={(v) => onChange(v.target.value === "" ? null : v)}
								value={value ?? ""}
								variant="standard"
								style={{ flexShrink: 1, flexGrow: 1 }}
								label="IUV"
							/>
						)}
						name="pendenze.0.iuv"
						control={c}
					/>
					<RangePicker control={c} nameStart="inizioInsorgenza" nameEnd="fineInsorgenza" variant="standard" startText="Insorgenza da" endText="Insorgenza a" />
				</Grid>
			</Grid>
			<Grid container direction="column" backgroundColor="#F7E8FC" borderRadius="5px" padding="5px 20px 10px 20px" flexWrap="nowrap" rowGap={2} alignItems="center">
				<Grid container direction="row" flexWrap="nowrap" columnGap={3} alignItems="center">
					<Grid container direction="row" margin="0px 0px 0px 0px" alignItems="center" flexShrink="1" maxWidth="220px" height="30px">
						{personaFisica ? "Giuridica" : <b>Giuridica</b>}
						<Switch onChange={(e, v) => setPersonaFisica(v)} checked={personaFisica} />
						{!personaFisica ? "Fisica" : <b>Fisica</b>}
						<Divider orientation="vertical" style={{ marginLeft: "20px" }} />
					</Grid>
					<Controller control={c} name="anagraficaDebitore.codiceFiscale" render={({ field: { value, onChange } }) => <TextField value={value ?? ""} onChange={onChange} label="Codice fiscale" variant="standard" style={{ flexGrow: 1 }} />} />
					<Controller control={c} name="anagraficaDebitore.partitaIva" render={({ field: { value, onChange } }) => <TextField value={value ?? ""} onChange={onChange} label="Partita IVA" variant="standard" style={{ flexGrow: 1 }} />} />
					{personaFisica && (<Controller control={c} name="anagraficaDebitore.zipCode" render={({ field: { value, onChange } }) => <TextField value={value ?? ""} onChange={onChange} label="Tax/health identification code" variant="standard" style={{ flexGrow: 1 }} />} />)}
				</Grid>
				<Grid container direction="row" flexWrap="nowrap" columnGap={3} alignItems="center">
					{
						personaFisica && (
							<>
								<Controller control={c} name="anagraficaDebitore.cognome" render={({ field: { value, onChange } }) => <TextField value={value ?? ""} onChange={onChange} on label="Cognome" variant="standard" style={{ flexGrow: 1 }} />} />
								<Controller control={c} name="anagraficaDebitore.nome" render={({ field: { value, onChange } }) => <TextField value={value ?? ""} onChange={onChange} label="Nome" variant="standard" style={{ flexGrow: 1 }} />} />
							</>
						)
					}
					{
						!personaFisica && (
							<Controller control={c} name="anagraficaDebitore.ragioneSociale" render={({ field: { value, onChange } }) => <TextField value={value ?? ""} onChange={onChange} label="Denominazione" variant="standard" style={{ flexGrow: 1 }} />} />
						)
					}
				</Grid>
				<Grid container direction="row" flexWrap="nowrap" columnGap={3} alignItems="center">
					<Controller control={c} name="anagraficaDebitore.email" render={({ field: { value, onChange } }) => <TextField value={value ?? ""} onChange={onChange} label="Email del debitore" variant="standard" style={{ flexGrow: 1 }} />} />
					<Controller control={c} name="anagraficaDebitore.comune" render={({ field: { value, onChange } }) => <TextField value={value ?? ""} onChange={onChange} label="Comune del debitore" variant="standard" style={{ flexGrow: 1 }} />} />
					<Controller control={c} name="anagraficaDebitore.indirizzo" render={({ field: { value, onChange } }) => <TextField value={value ?? ""} onChange={onChange} label="Indirizzo del debitore" variant="standard" style={{ flexGrow: 1 }} />} />
				</Grid>
			</Grid>
			<Grid container direction="column" backgroundColor="#F7E8FC" borderRadius="5px" padding="5px 20px 10px 20px" flexWrap="nowrap" rowGap={3} alignItems="center">
				<Grid container direction="row" flexWrap="nowrap" columnGap={3} justifyContent="space-between" alignItems="center">
					<StatoPagamento ctr={c} name="pagato" />
					<Controller
						name="importoMin"
						control={c}
						required
						render={({ field: { onChange: change, value: v } }) => (
							<NumberFormat
								onValueChange={({ formattedValue, value }, sourceInfo) => change(value)}
								value={v ?? ""}
								className=""
								label="Importo minimo"
								variant="standard"
								customInput={TextField}
								style={{ flexGrow: 1 }}
								prefix="€ "
								decimalScale={2}
								decimalSeparator=","
								thousandSeparator="."
							/>
						)}
					/>
					<Controller
						name="importoMax"
						control={c}
						required
						render={({ field: { onChange: change, value: v } }) => (
							<NumberFormat
								onValueChange={({ formattedValue, value }, sourceInfo) => change(value)}
								value={v ?? ""}
								className=""
								label="Importo massimo"
								variant="standard"
								customInput={TextField}
								style={{ flexGrow: 1 }}
								prefix="€ "
								decimalScale={2}
								decimalSeparator=","
								thousandSeparator="."
							/>
						)}
					/>
				</Grid>
				<Grid container direction="row" flexWrap="nowrap" columnGap={3} alignItems="center">
					<RangePicker control={c} nameStart="pendenze.0.inizioScadenza" nameEnd="pendenze.0.fineScadenza" variant="standard" startText="Scadenza da" endText="Scadenza a" />
					<RangePicker control={c} nameStart="pendenze.0.inizioFineValidita" nameEnd="pendenze.0.fineFineValidita" variant="standard" startText="Fine validità da" endText="Fine validità a" />
					<Grid container direction="row" margin="0px 0px 0px 0px" alignItems="center" flexShrink="1" maxWidth="220px" height="30px">
						<Controller
							control={c}
							defaultValue={false}
							render={({ field: { onChange, value } }) => (
								<>
									{value === 'null' ? <b>Mostra spontanei</b> : "Mostra spontanei"}
									<Switch onChange={(e, v) => onChange(v ? 'null' : false)} checked={value === 'null'} />
								</>
							)}
							name="spontaneo"
						/>
					</Grid>
				</Grid>
			</Grid>
			{
				currP && (
					<Grid container direction="row" backgroundColor="#FCE8E8" borderRadius="5px" padding="5px 20px 10px 20px" flexWrap="nowrap" columnGap={3} alignItems="center">
						{
							React.createElement(currP.searchFormRender({
								control: c,
								register: r,
								configuration: currP.configuration
							}), {})
						}
					</Grid>
				)
			}
		</Grid>
	);
};

export default function Pendenze({ account }) {
	const canModify = ["All", "GestioneDebiti"].some((p) => account.ruolo.permessi.includes(p));
	const canAvvisi = ["All", "GestioneAvvisi"].some((p) => account.ruolo.permessi.includes(p));

	const [refresh, setRefresh] = useState(0);
	//const [query, setQuery1] = useState({ page: 0, pageSize: 20, spontaneo: false });
	const [openExport, setOpenExport] = useState(false);
	const history = useHistory();
	const jwt = useToken();

	// LIMITI DI SELEZIONE PER DOWNLOAD AVVISI E INVIO EMAIL
	const AVVISI_SELECTION_LIMIT = 8000;
	const MAIL_SELECTION_LIMIT = 3000;

	const queryparams = [
		'ente.codiceEnte', 'tributo.idTributo.codiceTributo', 'struttura.idStruttura.cdr', 'idDebito.codiceDebito', 'importo', 'causale',
		'intestatarioDebitore.codiceFiscale', 'intestatarioDebitore.partitaIva', "intestatarioDebitore.nome", "intestatarioDebitore.cognome",
		"intestatarioDebitore.ragioneSociale"
	];

	// STATI PER DOWNLOAD/INVIO AVVISI
	// Select and Select All
	const [selectedRows, setSelectedRows] = useState(new Map());
	const [flagAllSelected, setFlagAllSelected] = useState(false);
	const [allSelectionModal, setAllSelectionModal] = useState(false);
	const [allDeselectionModal, setAllDeselectionModal] = useState(false);
	// Modale per selezione oltre il limite
	const [avvisiOverSelectionModal, setAvvisiOverSelectionModal] = useState(false);
	const [mailOverSelectionModal, setMailOverSelectionModal] = useState(false);
	// Modale per rinominare avvisi e stato Loading per download
	const [renameAvvisiModal, setRenameAvvisiModal] = useState(false);
	const [loadingAvvisi, setLoadingAvvisi] = useState(false);
	// Modale per email mancanti e conferma per procedere
	const [missingEmailModal, setMissingEmailModal] = useState(false);
	const [missingEmailCF, setMissingEmailCF] = useState([]);
	const [noDebitSelected, setNoDebitSelected] = useState(false);
	// Modale per compilazione mail e stato Loading per invio
	const [mailModal, setMailModal] = useState(false);
	const [loadingEmail, setLoadingEmail] = useState(false);
	// Modale per invio email avvenuto e riepilogo
	const [emailSentModal, setEmailSentModal] = useState(false);
	const [emailFailModal, setEmailFailModal] = useState(false);

	const enteOperatore = account.ente?.codiceEnte;

	const defaultQuery = useMemo(() => ({
		inizioCreazione: moment().subtract(enteOperatore ? 30 : 1, 'days').format("YYYY-MM-DD")
	}), []);

	const setQuery = useCallback(({ queryParameters, ...qUrl }) => {
		const queryString = qs.stringify({ page: 0, pageSize: 20, spontaneo: "null", ...removeEmptyObjects(qUrl) });
		history.push({ search: queryString });
	}, []);

	const { search } = useLocation();
	const query = useMemo(() => ({
		page: 0,
		pageSize: 20,
		spontaneo: "null",
		queryParameters: queryparams,
		enteOperatore,
		...defaultQuery,
		...(qs.parse(search.substr(1)))
	}), [search, defaultQuery]);

	const selected = [...selectedRows];

	const pluginManager = new PluginManager();

	// - Recupero la configurazione del plugin e genero il plugin
	const tribCod = query?.tributo?.codiceTributo.length > 0 ? (query?.tributo?.codiceTributo ?? null) : null;
	const codiciTributo = tribCod ? tribCod.map((t) => t?.codiceTributo).filter((codice) => codice) : [];

	const { data: tributoFetch } = useFetch(tribCod && `/tributo/${enteOperatore ?? query?.ente?.codiceEnte}/${codiciTributo}/all`, null, 'GET');

	//const { data: tributoFetch } = useFetch(query?.tributo?.codiceTributo && `/tributo/${enteOperatore ?? query?.ente?.codiceEnte}/${query?.tributo?.codiceTributo}/all`, null, 'GET');
	const pluginConf = tributoFetch?.plugins?.find((p) => p["@class"].split('.').pop() === 'CampiConfiguration');
	const currPlugin = pluginConf ? pluginManager.getPlugin("CAMPI", pluginConf) : null;
	const pluginCols = (currPlugin && currPlugin.getColumnsBackoffice(currPlugin.configuration)) ?? [];

	const { visibleColumns, allColumns, setVisible, visible, setOrder, sort, sortFilter, setSort } = useTableColumns("pendenze", [
		DataGridSelectColumn,
		{
			key: "dettagli",
			name: "",
			frozen: true,
			draggable: false,
			resizable: false,
			minWidth: 54,
			width: 54,
			formatter: ({ row }) => {
				if (row.codiceDebito) {
					return (
						<IconButton
							style={{ fontSize: "20px" }}
							onClick={() => {
								history.push(encodeURI(`debito/${encodeURIComponent(row.codiceEnte)}/${encodeURIComponent(row.codiceTributo)}/${encodeURIComponent(row.codiceDebito).replaceAll("%2F", "%252F")}${row.altDebito ? `/${row.altDebito}` : ""}`));
							}}>
							<i className="fa fa-search" />
						</IconButton>
					);
				}
				return <></>;
			}
		},
		{
			key: "edit",
			name: "",
			frozen: true,
			draggable: false,
			resizable: false,
			minWidth: 54,
			width: 54,
			formatter: ({ row }) => row.stato !== 'Pagato' && canModify && (<IconButton style={{ fontSize: "20px" }} onClick={() => { history.push(encodeURI(`/backoffice/debito/${row.codiceEnte}/${row.codiceTributo}/${row.codiceDebito}/${row.altDebito}/edit`)); }}><i className="fa fa-pencil-alt" /></IconButton>)
		},
		!enteOperatore && { key: "codiceEnte", name: "Ente", sortColumn: "idDebito.codiceEnte", sortable: true, frozen: false /*true*/, draggable: false, resizable: true, highlight: true, minWidth: 200, width: 200 },
		{ key: "codiceTributo", name: "Tributo", sortColumn: "idDebito.codiceTributo", sortable: true, frozen: false /*true*/, draggable: true, resizable: true, highlight: true, minWidth: 200, width: 240 },
		{ key: "cdr", name: "CDR", sortColumn: "struttura.idStruttura.cdr", sortable: true, frozen: false /*true*/, draggable: false, resizable: true, highlight: true, minWidth: 100, width: 100 },
		{ key: "codiceDebito", name: "Codice debito", sortColumn: "idDebito.codiceDebito", sortable: true, frozen: false /*true*/, draggable: true, resizable: true, highlight: true, minWidth: 250, width: 250 },

		{ key: "codiceFiscale", name: "Codice fiscale / IVA", sortable: false, frozen: false /*true*/, draggable: true, resizable: true, minWidth: 250, width: 250,
			formatter: ({ row, column }) => (
				<>
					{row?.altDebito > 0 && <span style={{ background: "#67A0A5", color: "white", borderRadius: "3px", padding: "0 5px 0 5px", marginRight: "5px" }}><b>OBB</b></span>}
					{row[column.key]}
				</>
			)
		},
		{ key: "denominazione", name: "Denominazione", sortable: false, frozen: false /*true*/, draggable: true, resizable: true, highlight: true, minWidth: 250, width: 250 },

		{ key: "tsInserimento", name: "Data inserimento", sortColumn: "tsInserimento", sortable: true, frozen: false, draggable: false, resizable: true, highlight: true, minWidth: 150, width: 150 },
		{ key: "dataInsorgenza", name: "Data insorgenza", sortColumn: "dataInsorgenza", sortable: true, frozen: false, draggable: false, resizable: true, highlight: true, minWidth: 150, width: 150 },
		{ key: "importo", name: "Importo", sortColumn: "importo", sortable: true, frozen: false, draggable: false, resizable: true, highlight: true, minWidth: 100, width: 100 },
		{ key: "stato", name: "Stato", sortColumn: "pagata", sortable: true, frozen: false, draggable: true, resizable: true, highlight: true, minWidth: 100, width: 100 },
		{
			key: "avviso",
			name: "Avviso",
			frozen: false,
			draggable: true,
			resizable: true,
			highlight: false,
			minWidth: 140,
			width: 140,
			formatter: ({ row, column }) => <StatoAvvisiLabel statoAvviso={row[column.key]} />
		},
		{ key: "causale", name: "Causale", sortColumn: "causale", sortable: true, frozen: false, draggable: true, resizable: true, highlight: true },
		...pluginCols
	], [DataGridSelectColumn.key, "denominazione", "codiceFiscale", "dettagli", "edit", !enteOperatore && "codiceEnte", "codiceTributo", "cdr", "codiceDebito", "tsInserimento", "dataInsorgenza", "importo", "stato", "avviso", "causale", ...pluginCols.map((r) => r.key)], [{ columnKey: "tsInserimento", direction: "DESC" }]);

	const ultimoTributo = codiciTributo.length > 0 ? codiciTributo[codiciTributo.length - 1] : null;
	const updatedQuery = {
		...query,
		tributo: {
			codiceTributo: ultimoTributo
		},
		tributi: tribCod,
		spontaneo: query.spontaneo === 'null' ? null : query.spontaneo,
		orderBy: sortFilter
	};
	const { data, status } = useFetch('/debiti/query', null, 'POST', updatedQuery, refresh);

	if (data) {
		data.data = data.data.map((x) => ({
			...x,
			id: `${x.codiceEnte}///${x.codiceTributo}///${x.codiceDebito}///${x.altDebito}`
		}));
	}

	const tableData = data?.data?.map((r) => ({
		id: r.id,
		altDebito: r.altDebito ?? 0,
		codiceEnte: r.codiceEnte ?? "",
		codiceTributo: r.codiceTributo ?? "",
		cdr: r.cdr ?? "",
		codiceDebito: r.codiceDebito ?? "",
		tsInserimento: moment(r.inserimento).format("DD/MM/yyyy HH:mm:SS"),
		dataInsorgenza: (r.dataInsorgenza && moment(r.dataInsorgenza).format("DD/MM/yyyy")) ?? "",
		importo: `€ ${r.importo?.toString()}`,
		stato: r.pagato ? "Pagato" : "Da pagare",
		codiceFiscale: r?.anagraficaDebitore?.codiceFiscale || r?.anagraficaDebitore?.partitaIva || "N.D.",
		denominazione: (r?.anagraficaDebitore?.nome || r?.anagraficaDebitore?.cognome) ? `${r?.anagraficaDebitore?.nome} ${r?.anagraficaDebitore?.cognome}` : r?.anagraficaDebitore?.ragioneSociale,
		avviso: r.statoEmail ? r.statoEmail : (r.anagraficaDebitore.emailUtente || r.anagraficaDebitore.email ? "EmailPresente" : "NoMail"),
		causale: r.causale ?? "",
		...(Object.entries(r.pluginData.find((p) => p.codicePlugin === "CAMPI")?.valueMap ?? {}).reduce((acc, [k, v]) => {
			acc[`p_${k}`] = v;
			return acc;
		}, {}))
	}));

	const downloadAvvisi = async (avvisiTitle) => {
		setLoadingAvvisi(true);
		const avvisiData = selected.map((x) => ({
			codiceEnte: x.split('///')[0],
			codiceTributo: x.split('///')[1],
			codiceDebito: x.split('///')[2],
			altDebito: x.split('///')[3]
		}));

		const body = {
			selected: avvisiData,
			filename: avvisiTitle.fileName,
			flagAllSelected,
			query: {
				...updatedQuery,
				page: undefined,
				pageSize: undefined
			}
		};

		const response = await fetch(`${configuration.serverAddress}/debiti/avviso/downloadBackoffice`, {
			method: 'POST',
			body: JSON.stringify(body),
			headers: new Headers({ Authorization: `Bearer ${jwt}`, 'Content-Type': 'application/json' })
		});

		// TO - DO
		const [_, filename] = response.headers.get('content-disposition').split('filename=');

		const blob = await response.blob();

		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		// TO - DO
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		a.remove();
		setLoadingAvvisi(false);
		setRenameAvvisiModal(false);
	};

	const checkEmails = async () => {
		const avvisiData = selected.map((x) => ({
			codiceEnte: x.split('///')[0],
			codiceTributo: x.split('///')[1],
			codiceDebito: x.split('///')[2],
			altDebito: x.split('///')[3]
		}));

		const body = {
			flagAllSelected,
			selected: avvisiData,
			query: {
				...updatedQuery,
				page: undefined,
				pageSize: undefined
			}
		};

		const response = await fetch(`${configuration.serverAddress}/debiti/avviso/checkEmails`, {
			method: 'POST',
			body: JSON.stringify(body),
			headers: new Headers({ Authorization: `Bearer ${jwt}`, 'Content-Type': 'application/json' })
		});

		response.json()
			.then((result) => {
				setSelectedRows(new Set(result.selected));
				//setFlagAllSelected(false);
				setMissingEmailCF(result.cfList);
				if (result.cfList.length > 0) setMissingEmailModal(true);
				else setMailModal(true);
			});
	};

	const sendEmail = async (emailData) => {
		const form = new FormData();
		if (emailData.allegati !== undefined) {
			for (let i = 0; i < emailData.allegati.length; i++) {
				form.append(`file-${i}`, emailData.allegati[i]);
			}
		}

		await fetch(`${configuration.serverAddress}/debiti/avviso/createAllegati`, {
			method: 'POST',
			body: form,
			headers: new Headers({ Authorization: `Bearer ${jwt}` })
		}).then(async (ris) => {
			if (ris.status >= 400) {
				throw await ris.json();
			}
			ris.json().then(async (resp) => {
				const body = {
					idDebiti: selected.map((x) => ({
						codiceEnte: x.split('///')[0],
						codiceTributo: x.split('///')[1],
						codiceDebito: x.split('///')[2],
						altDebito: x.split('///')[3]
					})),
					oggetto: emailData.mailObject,
					contenuto: emailData.mailBody,
					filename: emailData.fileName,
					allegati: resp.idAllegati,
					operatore: { id: account.id },
					flagAllSelected,
					query: {
						...updatedQuery,
						page: undefined,
						pageSize: undefined
					}
				};

				setLoadingEmail(true);
				await fetch(`${configuration.serverAddress}/debiti/avviso/generateEmails`, {
					method: 'POST',
					body: JSON.stringify(body),
					headers: new Headers({ Authorization: `Bearer ${jwt}`, 'Content-Type': 'application/json' })
				}).then(async (res) => {
					setMissingEmailCF([]);
					setMailModal(false);
					setLoadingEmail(false);
					if (res.status !== 200) setEmailFailModal(true);
					else setEmailSentModal(true);
				});
			});
		});
	};

	const selectAll = () => {
		setAllSelectionModal(false);
		setFlagAllSelected(true);
		setSelectedRows(new Set());
	};

	const deselectAll = () => {
		setAllDeselectionModal(false);
		setFlagAllSelected(false);
		setSelectedRows(new Set());
	};

	return (
		<div>
			<ConfirmModal
				open={allSelectionModal}
				title="Conferma selezione"
				text="Vuoi selezionare anche tutti gli elementi delle altre pagine?"
				onConfirm={selectAll}
				onDeny={() => {
					setAllSelectionModal(false);
					setSelectedRows(new Set([...selected, ...(data?.data.map((x) => x.id) ?? [])]));
				}}
				confirmText="Sì"
				denyText="No"
				width={600}
			/>
			<ConfirmModal
				open={allDeselectionModal}
				title="Conferma deselezione"
				text="Vuoi deselezionare anche tutti gli elementi delle altre pagine?"
				onConfirm={deselectAll}
				onDeny={() => {
					setAllDeselectionModal(false);
					if (flagAllSelected) setSelectedRows(new Set([...selected, ...(data?.data.map((item) => item.id) ?? [])]));
					else setSelectedRows(new Set(selected.filter((el) => data?.data.some((item) => el.id === item.id))));
				}}
				confirmText="Sì"
				denyText="No"
				width={600}
			/>
			<ModalAvvisi
				open={renameAvvisiModal}
				setOpen={setRenameAvvisiModal}
				onSubmit={downloadAvvisi}
				loading={loadingAvvisi}
			/>
			<ConfirmModal
				open={missingEmailModal}
				title="Email Assenti"
				text={(
					<>
						Non è stato trovato alcun indirizzo Email relativo ai seguenti debitori:
						<br />
						<br />
						<div style={{ maxHeight: "150px", overflowY: "auto" }}>
							<ul>
								{React.Children.toArray(missingEmailCF.map((x) => <li>{x}</li>))}
							</ul>
						</div>
						<br />
						Desideri procedere ugualmente con l&apos;invio degli avvisi?
						Verranno automaticamente deselezionati gli elementi privi di Email.
					</>
				)}
				onConfirm={() => {
					setMissingEmailModal(false);
					if (selected.length > 0) setMailModal(true);
					else setNoDebitSelected(true);
				}}
				onDeny={() => {
					setMissingEmailModal(false);
				}}
				confirmText="Sì"
				denyText="No"
				width={600}
			/>
			<ConfirmModal
				open={noDebitSelected}
				title="Nessun debito selezionato"
				text={(
					<>
						L&apos;indirizzo email del debitore non è disponibile per nessuno dei debiti selezionati. Non è possibile proseguire con l&apos;operazione.
						<br />
						Si consiglia di effettuare nuovamente la selezione verificando nella colonna &quot;Avviso&quot; la disponibilità dell&apos;indirizzo email del debitore.
					</>
				)}
				onConfirm={() => setNoDebitSelected(false)}
				confirmText="OK"
				width={600}
			/>
			<ModalMail
				open={mailModal}
				setOpen={setMailModal}
				onSubmit={sendEmail}
				onDeny={() => setMissingEmailCF([])}
				loading={loadingEmail}
			/>
			<ConfirmModal
				open={emailSentModal}
				title="Generazione Email Avvisi"
				text={(
					<>
						Le Email sono state generate correttamente.
						<br />
						Visita la sezione Documenti e Comunicazioni -&gt; Registro email per verificare lo stato di invio.
					</>
				)}
				onConfirm={() => setEmailSentModal(false)}
				confirmText="OK"
				width={600}
			/>
			<ConfirmModal
				open={emailFailModal}
				title="Generazione Email Avvisi"
				text={(
					<>
						La generazione non è andata a buon fine.
						<br />
						Se il problema persiste si prega di contattare l&apos;assistenza.
					</>
				)}
				onConfirm={() => setEmailFailModal(false)}
				confirmText="OK"
				width={600}
			/>
			<ConfirmModal
				open={avvisiOverSelectionModal}
				title="Troppi elementi selezionati per il Download Avvisi"
				text={(
					<>
						Il Download Avvisi consente un limite di
						{" "}
						{AVVISI_SELECTION_LIMIT}
						{" "}
						elementi selezionati.
						<br />
						Hai selezionato
						{" "}
						{data?.totalResults - selected.length}
						{" "}
						elementi, rimuovi
						{" "}
						{data?.totalResults - selected.length - AVVISI_SELECTION_LIMIT}
						{" "}
						elementi per proseguire.
					</>
				)}
				onConfirm={() => setAvvisiOverSelectionModal(false)}
				confirmText="OK"
				width={600}
			/>
			<ConfirmModal
				open={mailOverSelectionModal}
				title="Troppi elementi selezionati per l'Invio Email"
				text={(
					<>
						L&apos;Invio di Email consente un limite di
						{" "}
						{MAIL_SELECTION_LIMIT}
						{" "}
						elementi selezionati.
						<br />
						Hai selezionato
						{" "}
						{data?.totalResults - selected.length}
						{" "}
						elementi, rimuovi
						{" "}
						{data?.totalResults - selected.length - MAIL_SELECTION_LIMIT}
						{" "}
						elementi per proseguire.
					</>
				)}
				onConfirm={() => setMailOverSelectionModal(false)}
				confirmText="OK"
				width={600}
			/>
			<Export
				open={openExport}
				columns={[...Object.values(VociExportFields), ...(pluginCols?.map((f) => new DataLabel(f.name, `PLUGIN|${f.intKey}`)) ?? [])]}
				queryFilters={{ ...updatedQuery, pageSize: null, page: null }}
				onDeny={() => setOpenExport(false)}
				onSuccess={() => history.push("/backoffice/export")}
				totalResults={data ? data.totalResults : 0}
				type={{
					name: "Dettagli",
					apiName: "dettagli",
					path: "it.ras.pagopa.shared.dto.system.prenotazione.PrenotazioneDettaglio"
				}}
				initialSelected={[
					VociExportFields.numeroRata, VociExportFields.iuv,
					VociExportFields.scadenza, VociExportFields.inizioValidita,
					VociExportFields.fineValidita, VociExportFields.causalePendenza,
					VociExportFields.codiceDebito,
					VociExportFields.causaleDebito, VociExportFields.denominazioneEnte,
					VociExportFields.denominazioneTributo,
					VociExportFields.codiceFiscaleDeb, VociExportFields.partitaIva,
					VociExportFields.nome, VociExportFields.cognome,
					VociExportFields.ragioneSociale, VociExportFields.codiceDettaglio,
					VociExportFields.tipo, VociExportFields.importo,
					VociExportFields.stato
				]}
			/>
			<ToolsArea
				className="mt-4 mb-3"
				selected={selected}
				queryParameters={queryparams}
				disableDelete
				disableEdit
				disableNew
				enableExport
				exportCallback={() => setOpenExport(true)}
				filtersChanged={(filters) => setQuery({ ...query, ...filters })}
				actualFilters={query}
				fields={FilterFields}
				callStatus={status}
				enableAvvisi={canAvvisi
					&& ((flagAllSelected && selected.length !== (data?.totalResults ?? 0)) || (!flagAllSelected && selected.length > 0))}
				avvisiCallback={() => {
					if (flagAllSelected && data?.totalResults - selected.length > AVVISI_SELECTION_LIMIT) {
						setAvvisiOverSelectionModal(true);
					} else setRenameAvvisiModal(true);
				}}
				mailCallback={() => {
					if (flagAllSelected && data?.totalResults - selected.length > MAIL_SELECTION_LIMIT) {
						setMailOverSelectionModal(true);
					} else checkEmails();
				}}
				allColumns={allColumns}
				visibleColumns={visible}
				setVisibleColumns={setVisible}
			/>
			<FiltersViewer filters={query} setFilters={setQuery} />
			<br />
			{!data || status === "fetching" ? <SpinnyPage /> : (
				<DataGrid
					columns={visibleColumns}
					rows={tableData}
					query={query?.query?.split(" ") ?? []}
					onSortColumnsChange={(col) => setOrder(col.map((c) => c.key))}
					rowKeyGetter={(row) => row.id}
					totalResults={data.totalResults}
					selectedRows={selectedRows}
					setSelectedRows={setSelectedRows}
					setPage={(page) => setQuery({ ...query, page })}
					currPage={parseInt(query.page, 10)}
					setAllSelectionModal={setAllSelectionModal}
					setAllDeselectionModal={setAllDeselectionModal}
					allSelected={flagAllSelected}
					sortData={sort}
					onSortDataChange={setSort}
				/>
			)}
			<br />
			<div style={{ display: "flex" }}>
				{currPlugin && <Alert severity="info">* Campo non standard relativo al form personalizzato.</Alert>}
			</div>
			<br />
			<br />
		</div>
	);
}
