import CampiPlugin from "./Campi/CampiPlugin";
import InteressiPlugin from "./Interessi/InteressiPlugin";
import ImportoDelegatoPlugin from "./ImportoDelegato/ImportoDelegatoPlugin";
import SanzioniPlugin from "./Sanzioni/SanzioniPlugin";
import SovratassePlugin from "./Sovratasse/SovratassePlugin";
import ServizioPSP from "./ServizioPSP/ServizioPSP";


export default class PluginManager {
	/**
	 * Lista dei plugin configurabili.
	 */
	plugins = {
		CAMPI: { nome: "Campi personalizzabili", codice: "CAMPI", Entry: CampiPlugin },
		SANZIONI: { nome: "Gestione sanzioni", codice: "SANZIONI", Entry: SanzioniPlugin },
		INTERESSI: { nome: "Interessi legali", codice: "INTERESSI", Entry: InteressiPlugin },
		IMPORTO_DELEGATO: { nome: "Importo delegato", codice: "IMPORTO_DELEGATO", Entry: ImportoDelegatoPlugin },
		SOVRATASSE: { nome: "Gestione sovratasse", codice: "SOVRATASSE", Entry: SovratassePlugin },
		SERVIZIOPSP: { nome: "Servizio PSP", codice: "SERVIZIOPSP", Entry: ServizioPSP }
	};

	getAllPlugins() {
		return Object.values(this.plugins);
	}

	getPlugin(codice, configuration) {
		return new this.plugins[codice].Entry(configuration);
	}
}
