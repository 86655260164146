/* eslint-disable */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import ElementoAlberoStrutture from './ElementoAlberoStrutture';
import { STRUTTURA } from "../../../Store/Ricerca";

export default function ListaAlberoStrutture(props) {
	const {
		codiceEnte,
		codiceTributo,
		strutture,
		struttureTributo,
		onClick
	} = props;

	const lista = strutture.map((e) => (
		<ElementoAlberoStrutture
			struttura={e}
			codiceEnte={codiceEnte}
			codiceTributo={codiceTributo}
			key={`struttura-${e.cdrEnte}`}
			linkDisabled={struttureTributo ? !struttureTributo.find((x) => x === e.cdr) : false}
			struttureTributo={struttureTributo && null}
			onClick={onClick}
		/>
	));

	return (
		<>
			<ul style={{ paddingLeft: "20px" }}>
				{lista}
			</ul>
		</>
	);
}
