export default function Currency(props) {
	const {
		children
	} = props;

	let value = parseFloat(children);
	value = `${value.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '1.')} €`;

	return <span className="currency">{value}</span>;
}
