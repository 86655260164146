/* eslint-disable */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Link } from 'react-router-dom';
import ListaAlberoStrutture from './ListaAlberoStrutture';
import OnClickLink from "../Common/OnClickLink";
import { STRUTTURA } from "../../../Store/Ricerca";

export default function ElementoAlberoStrutture(props) {
	const {
		codiceEnte,
		codiceTributo,
		struttura,
		linkDisabled,
		struttureTributo,
		onClick
	} = props;

	return (
		<>
			<li>
				{onClick
					? (
						<OnClickLink
							className="link-underline"
							onClick={() => onClick(STRUTTURA, struttura)}
							disabled={linkDisabled}
						>
							{struttura.nome}
						</OnClickLink>
					) : (
						<Link
							className="link-underline"
							to={linkDisabled ? "/" : `/pagamento/${codiceEnte}/${codiceTributo}/${struttura.cdr}`}
							onClick={linkDisabled ? (e) => e.preventDefault() : null}
							style={linkDisabled ? { color: "grey", cursor: "default" } : {}}
						>{struttura.nome}</Link>
					)}
				{struttura.figli.length > 0 && (
					<ListaAlberoStrutture
						strutture={struttura.figli}
						codiceEnte={codiceEnte}
						codiceTributo={codiceTributo}
						struttureTributo={struttureTributo}
						onClick={onClick}
					/>
				)}
			</li>
		</>
	);
}
