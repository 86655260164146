import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	setOrder as orderAction,
	setVisible as visibleAction,
	setSort as sortAction,
	setAllPreferences
} from "../../Store/Tables";
import debounce from "./Debounce";
import configuration from "../../configuration";
import useToken from "../../Hooks/useToken";
import { useFetch } from "../../Hooks/useFetch";

export default function useTableColumns(tableName, columns = [], defaultVisible = [], defaultSort = []) {
	const didMountRef = useRef(false);
	const table = useSelector((s) => s.tables[tableName]);
	const tables = useSelector((s) => s.tables);
	const { user } = useSelector((s) => s.authentication);
	const dispatch = useDispatch();

	const { data: accountInfo } = useFetch('/user/info', null);

	const order = table?.order ?? columns.map((c) => c.key);
	const setOrder = (v) => dispatch(orderAction({ tableName, order: v }));

	const sort = table?.sort ?? [];
	const setSortInt = (v) => dispatch(sortAction({ tableName, sort: v }));

	const visible = table?.visible ?? defaultVisible;
	const setVisible = (v) => dispatch(visibleAction({ tableName, visible: v }));

	const jwt = useToken();

	useEffect(() => { if (didMountRef.current) setVisible(defaultVisible); }, [JSON.stringify(defaultVisible)]);
	useEffect(() => { if (didMountRef.current) setOrder(columns.map((c) => c.key)); }, [JSON.stringify(columns)]);

	useEffect(() => {
		if (!table) {
			dispatch(orderAction({ tableName, order: columns.map((c) => c.key) }));
			dispatch(sortAction({ tableName, sort: defaultSort }));
			dispatch(visibleAction({ tableName, visible: defaultVisible }));
		}
		didMountRef.current = true;
	}, [table]);

	useEffect(debounce(1000, () => {
		if (user?.ruolo?.codice === "AMMINISTRATORE") return;
		fetch(`${configuration.serverAddress}/operatore/setPreferences`, {
			method: 'PATCH',
			body: JSON.stringify({ preferences: { tables } }),
			headers: new Headers({
				Authorization: `Bearer ${jwt}`,
				...configuration.defaultHeaders
			})
		}).then(async (response) => {
			if (response.status >= 400) {
				throw await response.json();
			}
			return response.json();
		}).then((d) => {
		}).catch((error) => {
		});
	}), [JSON.stringify(table)]);

	useEffect(() => {
		if (accountInfo?.preferences?.tables) dispatch(setAllPreferences(accountInfo?.preferences?.tables));
	}, [accountInfo]);

	columns.sort((a, b) => order.indexOf(a.key) - order.indexOf(b.key));
	const visibleColumns = columns.filter((c) => visible.includes(c?.key));
	const sortFilter = [...sort, ...defaultSort].filter((s) => columns.find((c) => c.key === s.columnKey)?.sortColumn)
		.map((s) => ({ field: columns.find((c) => c.key === s.columnKey)?.sortColumn, desc: s.direction === "DESC" }))
		.slice(0, 1);
	const setSort = (v) => {
		setSortInt(v.filter((s) => columns.find((c) => c.key === s.columnKey)));
	};

	return { visibleColumns, order, allColumns: columns, visible, setVisible, setOrder, setSort, sortFilter, sort };
}
