import DataLabel from "../../Utils/DataLabel";

export default {
	ccp: new DataLabel("Codice transazione", "ccp"),
	riaccreditato: new DataLabel("Riaccreditato", "riaccreditato"),
	identificativoPsp: new DataLabel("Identificativo PSP", "identificativoPsp"),
	codiceFiscalePsp: new DataLabel("Codice fiscale PSP", "codiceFiscalePsp"),
	partitaIvaPsp: new DataLabel("Partita IVA PSP", "partitaIvaPsp"),
	nomePsp: new DataLabel("Nome PSP", "nomePsp"),
	tassePsp: new DataLabel("Tasse PSP", "tassePsp"),
	dataPagamento: new DataLabel("Data pagamento", "dataPagamento"),
	dataTrasferimento: new DataLabel("Data trasferimento", "dataTrasferimento"),
	identificativoCanale: new DataLabel("Identificativo canale", "identificativoCanale"),
	descrizioneCanale: new DataLabel("Descrizione canale", "descrizioneCanale"),
	codiceFiscaleVers: new DataLabel("Codice fiscale versante", "codiceFiscaleVers"),
	partitaIvaVers: new DataLabel("Partita IVA versante", "partitaIvaVers"),
	nomeVers: new DataLabel("Nome versante", "nomeVers"),
	cognomeVers: new DataLabel("Cognome versante", "cognomeVers"),
	ragioneSocialeVers: new DataLabel("Ragione sociale versante", "ragioneSocialeVers"),
	emailVers: new DataLabel("Email versante", "emailVers"),
	identificativoRicevuta: new DataLabel("Identificativo ricevuta", "identificativoRicevuta"),
	nav: new DataLabel("Numero avviso", "nav"),
	stato: new DataLabel("Stato pagamento", "stato"),
	importo: new DataLabel("Importo pagato", "importo"),
	numeroRata: new DataLabel("Numero rata", "numeroRata"),
	iuv: new DataLabel("IUV", "iuv"),
	scadenza: new DataLabel("Data scadenza", "scadenza"),
	inizioValidita: new DataLabel("Data inizio validità", "inizioValidita"),
	fineValidita: new DataLabel("Data fine validità", "fineValidita"),
	causalePendenza: new DataLabel("Causale pendenza", "causalePendenza"),
	codiceDebito: new DataLabel("Codice debito", "codiceDebito"),
	annoRiferimento: new DataLabel("Anno riferimento", "annoRiferimento"),
	insorgenza: new DataLabel("Insorgenza", "insorgenza"),
	causaleDebito: new DataLabel("Causale", "causaleDebito"),
	note: new DataLabel("Note", "note"),
	denominazioneTributo: new DataLabel("Denominazione tributo", "denominazioneTributo"),
	cdrStruttura: new DataLabel("Cdr struttura", "cdrStruttura"),
	nomeStruttura: new DataLabel("Nome struttura", "nomeStruttura"),
	descrizioneStruttura: new DataLabel("Descrizione struttura", "descrizioneStruttura"),
	codiceFiscaleDeb: new DataLabel("Codice fiscale debitore", "codiceFiscaleDeb"),
	partitaIvaDeb: new DataLabel("Partita IVA debitore", "partitaIvaDeb"),
	nomeDeb: new DataLabel("Nome debitore", "nomeDeb"),
	cognomeDeb: new DataLabel("Cognome debitore", "cognomeDeb"),
	ragioneSocialeDeb: new DataLabel("Ragione sociale debitore", "ragioneSocialeDeb"),
	comuneResidenzaDeb: new DataLabel("Comune residenza debitore", "comuneResidenzaDeb"),
	indirizzoResidenzaDeb: new DataLabel("Indirizzo residenza debitore", "indirizzoResidenzaDeb"),
	iur: new DataLabel("Identificativo univoco riscossione", "iur"),
	esitoSospeso: new DataLabel("Esito sospeso", "esitoSospeso"),
	data: new DataLabel("Data sospeso", "data"),
	identificativoFlusso: new DataLabel("Identificativo flusso", "identificativoFlusso"),
	identificativoRegolamento: new DataLabel("Identificativo regolamento", "identificativoRegolamento"),
	dataOra: new DataLabel("Data ora ricezione flusso", "dataOra"),
	dataRegolamento: new DataLabel("Data regolamento flusso", "dataRegolamento"),
	pspBic: new DataLabel("PSP BIC", "pspBic"),
	entiBeneficiari: new DataLabel("Altri enti beneficiari", "entiBeneficiari")
};
