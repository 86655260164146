import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';

import moment from "moment";
import { Grid, Switch } from "@mui/material";
import NumberFormat from "react-number-format";
import { useProvince, useComuni } from "../../../Utils/Belfiore";
import { buf2hex } from "../../../Utils/Files";

import InputConDescrizione from "../../../Portal/Components/Common/InputConDescrizione";
import Select from "../../../Portal/Components/Common/Select";

const scrollToError = (inputStringa) => {
	const yOffset = -100;
	const y = inputStringa.current?.getBoundingClientRect().top + window.pageYOffset + yOffset;

	window.scrollTo({ top: y, behavior: 'smooth' });
};

export default {
	CampoStringa: ({ value, onChange, label, readonly, obbligatorio, visible, nascostoSpontaneo, suggerimento, placeholder, error, scrollTo, key }) => {
		const { t } = useTranslation('translation');

		const inputStringa = useRef(null);
		useEffect(() => {
			if (scrollTo) scrollToError(inputStringa);
		}, [scrollTo]);
		const titolo = t(`tributi.${key}`, { defaultValue: label });

		let suggerimentoCampo = t(`suggerimento.${key}`, { defaultValue: suggerimento });
		if (key === undefined || key === 'causale' || key === 'importo') {
			suggerimentoCampo = suggerimento;
		}

		return (
			<div ref={inputStringa} className={`col-12 col-md-9 pt-3 ${(!visible || nascostoSpontaneo) ? "d-none" : ""}`}>
				<InputConDescrizione
					titolo={titolo}
					obbligatorio={obbligatorio}
					readonly={readonly}
					onChange={(e) => onChange(e.target.value)}
					value={value ?? ""}
					tipo="text"
					placeholder={placeholder}
					descrizione={suggerimentoCampo}
					errore={error?.message}
				/>
			</div>
		);
	},
	CampoSelect: ({ value, onChange, valori, label, nome, readonly, obbligatorio, visible, nascostoSpontaneo, error, suggerimento, placeholder, scrollTo, key }) => {
		const { t } = useTranslation('translation');

		const inputStringa = useRef(null);
		useEffect(() => {
			if (scrollTo) scrollToError(inputStringa);
		}, [scrollTo]);
		const titolo = t(`tributi.${key}`, { defaultValue: label });


		return (
			<div
				className={`col-12 col-md-9 pt-3 bootstrap-select-wrapper input-con-descrizione w-100  ${(!visible || nascostoSpontaneo) ? "d-none" : ""}`}
				ref={inputStringa}
			>
				<Select
					titolo={titolo}
					obbligatorio={obbligatorio}
					valoreSelezionato={value}
					opzioneVuotaPresente
					testoOpzioneVuota={placeholder}
					nome={nome}
					onChange={onChange}
					placeholder={placeholder}
					descrizione={suggerimento}
					errore={error?.message}
					scelte={valori.reduce((acc, v) => ({ ...acc, [v.value]: v.label }), {})}
					elementiNascosti={valori.reduce((acc, v) => [...acc, v.hidden], [])}
				/>
			</div>
		);
	},
	CampoBelfiore: ({ value, onChange, valori, label, nome, readonly, obbligatorio, visible, nascostoSpontaneo, error, suggerimento, placeholder, scrollTo, key }) => {
		const { t } = useTranslation('translation');

		const [provincia, setProvincia] = useState("");
		const province = useProvince();
		const comuni = useComuni({ provincia });
		const inputStringa = useRef(null);
		useEffect(() => {
			if (scrollTo) scrollToError(inputStringa);
		}, [scrollTo]);
		const titolo = t(`tributi.${key}`, { defaultValue: label });

		return (
			<div className={`col-12 col-md-9 pt-3 bootstrap-select-wrapper input-con-descrizione w-100  ${(!visible || nascostoSpontaneo) ? "d-none" : ""}`}>
				<h5>
					{titolo && `Luogo ${titolo}`}
					{obbligatorio && ' *'}
				</h5>
				<div style={{ columnGap: "10px" }} className="d-flex" ref={inputStringa}>
					<div className="w-25">
						<Select
							obbligatorio={obbligatorio}
							valoreSelezionato={provincia}
							opzioneVuotaPresente
							testoOpzioneVuota={placeholder}
							nome={nome}
							onChange={setProvincia}
							placeholder={placeholder}
							scelte={province.reduce((acc, v) => ({ ...acc, [v]: v }), { "": "" })}
							elementiNascosti={[]}
						/>
					</div>
					<div className="w-75">
						<Select
							obbligatorio={obbligatorio}
							valoreSelezionato={value}
							opzioneVuotaPresente
							testoOpzioneVuota={placeholder}
							nome={nome}
							onChange={onChange}
							placeholder={placeholder}
							scelte={comuni.reduce((acc, v) => ({ ...acc, [v.belfiore]: v.comune }), { 0: "" })}
							elementiNascosti={[]}
						/>
					</div>
				</div>
				<p style={error && { color: "red" }}><i>{error?.message ?? error ?? suggerimento}</i></p>
			</div>
		);
	},
	CampoCheckbox: ({ value, onChange, label, nome, readonly, obbligatorio, visible, nascostoSpontaneo, error, suggerimento, scrollTo, key }) => {
		const { t } = useTranslation('translation');

		const inputStringa = useRef(null);
		useEffect(() => {
			if (scrollTo) scrollToError(inputStringa);
		}, [scrollTo]);
		const titolo = t(`tributi.${key}`, { defaultValue: label });

		return (
			<div ref={inputStringa} className={`form-check col-12 col-md-9 pt-3 ml-3 w-100 ${(!visible || nascostoSpontaneo) ? "d-none" : ""}`}>
				<input
					id={`${nome}Id`}
					required={obbligatorio}
					type="checkbox"
					readOnly={readonly}
					checked={value ?? false}
					onChange={(e) => onChange(e.target.checked)}
				/>
				<label htmlFor={`${nome}Id`}>{titolo}</label>
				<p style={error && { color: "red" }}><i>{error?.message ?? suggerimento}</i></p>
			</div>
		);
	},
	CampoNumerico: ({ value, onChange, label, readonly, obbligatorio, visible, nascostoSpontaneo, suggerimento, placeholder, error, scrollTo }) => {
		const inputStringa = useRef(null);
		useEffect(() => {
			if (scrollTo) scrollToError(inputStringa);
		}, [scrollTo]);

		return (
			<div ref={inputStringa} className={`col-12 col-md-9 pt-3 ${(!visible || nascostoSpontaneo) ? "d-none" : ""}`}>
				<InputConDescrizione
					titolo={label}
					obbligatorio={obbligatorio}
					readonly={readonly}
					onChange={(e) => onChange(e.target.value)}
					value={value}
					tipo="number"
					placeholder={placeholder}
					descrizione={suggerimento}
					errore={error?.message}
				/>
			</div>
		);
	},
	CampoImporto: ({ value, onChange, label, readonly, obbligatorio, visible, nascostoSpontaneo, suggerimento, placeholder, error, min, max, scrollTo, nome, key }) => {
		const { t } = useTranslation('translation');

		const inputStringa = useRef(null);
		const [focus, setFocus] = useState(false);
		useEffect(() => {
			if (scrollTo) scrollToError(inputStringa);
		}, [scrollTo]);
		const titolo = t(`tributi.${key}`, { defaultValue: label });


		return (
			<div ref={inputStringa} className={`col-12 col-md-9 pt-3 ${(!visible || nascostoSpontaneo) ? "d-none" : ""}`}>
				<NumberFormat
					onValueChange={({ floatValue: v }) => {
						onChange(v ?? null);
					}}
					onClick={() => !readonly && onChange("")}
					allowEmptyFormatting
					fixedDecimalScale={!focus}
					allowedDecimalSeparators={[',']}
					onFocusChange={(f) => { setFocus(f); }}
					value={value}
					titolo={titolo}
					obbligatorio={obbligatorio}
					readonly={readonly}
					tipo="text"
					placeholder={placeholder}
					descrizione={suggerimento}
					min={min}
					max={max}
					errore={error?.message}
					defaultValue={null}
					customInput={InputConDescrizione}
					onKeyPress={(e) => e.key === '.' && e.preventDefault()}
					prefix="€ "
					decimalScale={2}
					decimalSeparator=","
					thousandSeparator="."
				/>
			</div>
		);
	},
	CampoEmail: ({ value, onChange, label, readonly, obbligatorio, visible, nascostoSpontaneo, suggerimento, placeholder, error, scrollTo, key }) => {
		const { t } = useTranslation('translation');

		const inputStringa = useRef(null);
		useEffect(() => {
			if (scrollTo) scrollToError(inputStringa);
		}, [scrollTo]);
		const titolo = t(`tributi.${key}`, { defaultValue: label });

		return (
			<div ref={inputStringa} className={`col-12 col-md-9 pt-3 ${(!visible || nascostoSpontaneo) ? "d-none" : ""}`}>
				<InputConDescrizione
					titolo={titolo}
					obbligatorio={obbligatorio}
					readonly={readonly}
					onChange={(e) => onChange(e.target.value)}
					value={value}
					tipo="text"
					placeholder={placeholder}
					descrizione={suggerimento}
					errore={error?.message}
				/>
			</div>
		);
	},
	CampoCFIVA: ({ value = {}, onChange, readonly, obbligatorio, tipo = "PersonaFisica", visible, nascostoSpontaneo, error, scrollTo }) => {
		const { t } = useTranslation('translation');

		const inputStringa = useRef(null);
		const { personaFisica } = value;
		const setPersonaFisica = (v) => onChange({ ...value, personaFisica: v });
		useEffect(() => {
			if (scrollTo) scrollToError(inputStringa);
		}, [scrollTo]);
		useEffect(() => {
			if (personaFisica) {
				onChange({
					...value,
					denominazione: "",
					codiceFiscale: "",
					partitaIva: ""
				});
			} else onChange({ ...value, nome: "", cognome: "", cfIva: "" });
		}, [personaFisica]);
		useEffect(() => {
			if (personaFisica && tipo === "PersonaGiuridica") onChange({ ...value, personaFisica: false });
			if (!personaFisica && tipo === "PersonaFisica") onChange({ ...value, personaFisica: true });
		}, [tipo]);

		return (
			<>
				{
					tipo === "Entrambi" && (
						<div
							className={`col-12 col-md-9 pt-3 bootstrap-select-wrapper input-con-descrizione w-100  ${(!visible || nascostoSpontaneo) ? "d-none" : ""}`}
						>
							<Select
								titolo={t('portalInputMap.tipoPersona')}
								obbligatorio={obbligatorio}
								valoreSelezionato={personaFisica}
								nome="Tipo persona"
								onChange={(v) => setPersonaFisica(v === "true")}
								scelte={{ [true]: t('portalInputMap.personaFisica'), [false]: t('portalInputMap.personaGiuridica') }}
							/>
						</div>
					)
				}
				<div ref={inputStringa} className={`col-12 col-md-9 ${(!visible || nascostoSpontaneo) ? "d-none" : ""}`}>
					{
						personaFisica && (
							<Grid container direction="column" rowGap="20px">
								<div>
									<Switch
										onChange={(v) => onChange({ ...value, straniero: v.target.checked })}
										checked={value.straniero}
										defaultChecked={false}
									/>
									<b>Non sono un cittadino italiano (I am not an Italian citizen)</b>
									<InputConDescrizione
										//titolo={value.straniero ? "Tax/health identification number" : "Codice Fiscale oppure P.IVA"}
										titolo={t('portalInputMap.codFiscPIva')}
										obbligatorio={obbligatorio}
										onChange={(e) => {
											const start = e.target.selectionStart;
											const end = e.target.selectionEnd;
											e.target.value = e.target.value.toUpperCase();
											e.target.setSelectionRange(start, end);
											onChange({ ...value, cfIva: e.target.value.toUpperCase() });
										}}
										value={value.cfIva}
										tipo="text"
										// placeholder={value.straniero ? "Your tax or health identification number" : "Inserire il Codice Fiscale o P.IVA"}
										placeholder={t('portalInputMap.placeholderCodFisc')}
										descrizione=""
										errore={error?.cfIva?.message}
									/>
								</div>
								<InputConDescrizione
									titolo={t('portalInputMap.cognome')}
									obbligatorio={obbligatorio}
									readonly={readonly}
									onChange={(e) => onChange({ ...value, cognome: e.target.value })}
									value={value.cognome ?? ""}
									tipo="text"
									placeholder={t('portalInputMap.placeholderCognome')}
									descrizione=""
									errore={error?.cognome?.message}
								/>
								<InputConDescrizione
									titolo={t('portalInputMap.nome')}
									obbligatorio={obbligatorio}
									readonly={readonly}
									onChange={(e) => onChange({ ...value, nome: e.target.value })}
									value={value.nome ?? ""}
									tipo="text"
									placeholder={t('portalInputMap.placeholderNome')}
									descrizione=""
									errore={error?.nome?.message}
								/>
							</Grid>
						)
					}
					{
						!personaFisica && (
							<Grid container direction="column" rowGap="20px">
								<InputConDescrizione
									titolo={t('portalInputMap.codFisc')}
									readonly={readonly}
									onChange={(e) => {
										const start = e.target.selectionStart;
										const end = e.target.selectionEnd;
										e.target.value = e.target.value.toUpperCase();
										e.target.setSelectionRange(start, end);
										onChange({ ...value, codiceFiscale: e.target.value.toUpperCase() });
									}}
									value={value.codiceFiscale}
									obbligatorio={!value.partitaIva}
									tipo="text"
									placeholder={t('portalInputMap.placeholderCodFisc2')}
									descrizione=""
									errore={error?.codiceFiscale?.message}
								/>
								<InputConDescrizione
									titolo={t('portalInputMap.pIVA')}
									readonly={readonly}
									onChange={(e) => {
										const start = e.target.selectionStart;
										const end = e.target.selectionEnd;
										e.target.value = e.target.value.toUpperCase();
										e.target.setSelectionRange(start, end);
										onChange({ ...value, partitaIva: e.target.value.toUpperCase() });
									}}
									value={value.partitaIva}
									obbligatorio={!value.codiceFiscale}
									tipo="text"
									placeholder={t('portalInputMap.placeholderPIVA')}
									descrizione=""
									errore={error?.partitaIva?.message}
								/>
								<InputConDescrizione
									titolo={t('portalInputMap.denominazione')}
									obbligatorio={obbligatorio}
									readonly={readonly}
									onChange={(e) => onChange({ ...value, denominazione: e.target.value })}
									value={value.denominazione ?? ""}
									tipo="text"
									placeholder={t('portalInputMap.placeholderDenominazione')}
									descrizione=""
									errore={error?.denominazione?.message}
								/>
							</Grid>
						)
					}
				</div>
			</>
		);
	},
	CampoData: ({ value, onChange, label, readonly, obbligatorio, visible, nascostoSpontaneo, suggerimento, placeholder, error, scrollTo, key }) => {
		const { t } = useTranslation('translation');

		const inputStringa = useRef(null);
		useEffect(() => {
			if (scrollTo) {
				scrollToError(inputStringa);
			}
		}, [scrollTo]);
		const titolo = t(`tributi.${key}`, { defaultValue: label });

		return (
			<div ref={inputStringa} className={`col-12 col-md-9 pt-3 ${(!visible || nascostoSpontaneo) ? "d-none" : ""}`}>
				<label htmlFor="dateStandard" className="w-100 input-con-descrizione no-bot-margin active">
					{label && (
						<h5>
							{titolo}
							{obbligatorio && ' *'}
						</h5>
					)}
					<input
						type="date"
						id="datePicker"
						name="dateStandard"
						readOnly={readonly}
						onChange={(v) => {
							onChange(moment(v.target.value).format("DD-MM-YYYY"));
						}}
						value={value ? moment(value, "DD-MM-YYYY").format("YYYY-MM-DD") : null}
						placeholder={placeholder}
					/>
					{
						(error?.message || suggerimento) && (
							<p style={error?.message && { color: "red" }}>
								<i>{error?.message ?? suggerimento}</i>
							</p>
						)
					}
				</label>
			</div>
		);
	},
	CampoMarcaBollo: ({ value, onChange, label, readonly, obbligatorio, visible, nascostoSpontaneo, suggerimento, placeholder, error, scrollTo, key }) => {
		const { t } = useTranslation('translation');

		const [provincia, setProvincia] = useState("");
		const province = useProvince();
		const inputStringa = useRef(null);
		useEffect(() => {
			if (scrollTo) scrollToError(inputStringa);
		}, [scrollTo]);
		const titolo = t(`tributi.${key}`, { defaultValue: label });

		const changeFile = useMemo(() => (ev) => {
			const fr = new FileReader();
			fr.onload = () => {
				const result = new Uint8Array(fr.result);
				window.crypto.subtle.digest('SHA-256', result).then((digested) => {
					const digestedRes = new Uint8Array(digested);
					const hex = buf2hex(digestedRes);
					onChange({ ...value, hash: hex });
				});
			};

			fr.readAsArrayBuffer(ev.target.files[0]);
		}, [value]);

		return (
			<div ref={inputStringa} className={`col-12 col-md-9 pt-3 ${(!visible || nascostoSpontaneo) ? "d-none" : ""}`}>
				{label && (
					<h5>
						{titolo}
						{obbligatorio && ' *'}
					</h5>
				)}
				<div className="d-flex flex-row justify-content-center w-100" style={{ columnGap: "30px", alignItems: "baseline" }}>
					<div style={{ maxWidth: "220px" }}>
						<Select
							titolo=""
							sx
							obbligatorio={obbligatorio}
							valoreSelezionato={value?.provResidenza ?? ""}
							opzioneVuotaPresente
							testoOpzioneVuota={placeholder}
							nome="provResidenza"
							onChange={(v) => onChange({ ...value, provResidenza: v })}
							placeholder={placeholder}
							scelte={province.reduce((acc, v) => ({ ...acc, [v]: v }), { "": t('portalInputMap.selezionaProvincia') })}
							elementiNascosti={[]}
						/>
					</div>
					<input type="file" className="mr-auto" onChange={changeFile} />
				</div>
				Hash:
				{` ${(value?.hash ?? t('portalInputMap.selezionaFile'))}`}
				{
					(error?.message || suggerimento) && (
						<p style={error?.message && { color: "red" }}>
							<i>{error?.message ?? suggerimento}</i>
						</p>
					)
				}
			</div>
		);
	}
};
