import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';


import Breadcrumbs from '../Components/Common/Breadcrumbs';
import TitleWithSearch from '../Components/Common/TitleBarWithSearch';
import ListaEnti from '../Components/Enti/ListaEnti';

export default function Enti() {
	const [search, setSearch] = useState('');

	const breadcrumbs = [
		{ titolo: 'enti' }
	];
	const { t } = useTranslation('translation');

	useEffect(() => window.scrollTo(0, 0), []);

	return (
		<>
			<Breadcrumbs breadcrumbs={breadcrumbs} />
			<TitleWithSearch
				titolo={t('enti.titoloEnte')}
				sottotitolo={t('enti.sottotitolo')}
				placeholderRicerca={t('enti.avvisoDenominazione')}
				searchChange={(e) => setSearch(e)}
			/>
			<div style={{ minHeight: "700px" }}>
				<ListaEnti t={t} searchString={search} />
			</div>
		</>
	);
}
