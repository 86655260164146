/* eslint-disable react/jsx-one-expression-per-line */
import { Link } from 'react-router-dom';
import OnClickLink from './OnClickLink';

export default function ListaCardSingola(props) {
	const {
		nome,
		link,
		children,
		testoLink,
		imageLink,
		onClick,
		linkDisabled
	} = props;

	return (
		<div className="col-12 col-md-12 col-lg-6 col-xl-4 py-2">
			<div className="card-wrapper card-space">
				<div className="card card-bg no-after">
					{!onClick && link && (
						<Link
							className="card-header bg-primary text-white font-weight-bold"
							style={linkDisabled ? { pointerEvents: "none" } : {}}
							to={linkDisabled ? "/" : link}
							title={nome}
						>
							{nome}
						</Link>
					)}
					{onClick && (
						<OnClickLink
							className="card-header bg-primary text-white font-weight-bold"
							style={linkDisabled ? { pointerEvents: "none" } : {}}
							onClick={onClick}
							disabled={linkDisabled}
							mainLink
						>
							{nome}
						</OnClickLink>
					)}
					{!children && (
						<div className="card-body empty" />
					)}
					{children && (
						<div className="card-body">
							{children}
						</div>
					)}
					<div className="it-card-footer">
						<div className="separator">
							{!onClick && link && (
								<Link
									className="read-more"
									style={linkDisabled ? { color: "grey", pointerEvents: "none" } : {}}
									to={linkDisabled ? "/" : link}
								>
									<span className="text" style={{ paddingRight: "10px" }}>{testoLink}</span>
									{imageLink}
								</Link>
							)}
							{onClick && (
								<OnClickLink
									className="read-more"
									onClick={onClick}
									disabled={linkDisabled}
								>
									<span className="text" style={{ paddingRight: "10px" }}>{testoLink}</span>
									{imageLink}
								</OnClickLink>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
