/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Divider, IconButton, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Highlighter from "react-highlight-words";
import moment from "moment";
import BigDataTable from "../Components/Tables/BigDataTable";
import DataTableColumn from "../Components/Tables/DataTableColumn";
import ToolsArea from "../Components/ToolsArea";
import EnteSelect from "../Components/Forms/EnteSelect";
import RangePicker from "../Components/Forms/RangePicker";
import { StatoInvioEmail, TributoPick } from "../Components/SearchFilters";
import { useFetch } from "../../Hooks/useFetch";
import SpinnyPage from "../Components/SpinnyPage";
import DataGrid from "../Components/Tables/DataGrid";
import useTableColumns from "../Utils/useTableColumns";


function StatoLabel({ stato }) {
	switch (stato) {
		case "DaInviare": return (<span style={{ background: "#bfc9d9", borderRadius: "3px", padding: "0 5px 0 5px" }}><b>Da inviare</b></span>);
		case "InElaborazione": return (<span style={{ background: "orange", borderRadius: "3px", padding: "0 5px 0 5px" }}><b>Invio in corso</b></span>);
		case "Inviata": return (<span style={{ background: "#6fed7e", borderRadius: "3px", padding: "0 5px 0 5px" }}><b>Inviata</b></span>);
		case "ErroreInviato":
		case "Errore": return (<span style={{ background: "#ff8c8c", borderRadius: "3px", padding: "0 5px 0 5px" }}><b>Invio fallito</b></span>);
		default:
	}
}

export default function Email({ account }) {
	const [refresh, setRefresh] = useState(0);
	const [query, setQuery] = useState({
		page: 0,
		pageSize: 20,
		idOperatore: account.id
	});
	const history = useHistory();

	const enteOperatore = account.ente?.codiceEnte;

	const { data, status } = useFetch('/email/query', null, 'POST', query, refresh);

	const { visibleColumns, allColumns, setVisible, visible, setOrder, sort, sortFilter, setSort } = useTableColumns("email", [
		{
			key: "dettagli",
			name: "",
			frozen: true,
			draggable: false,
			resizable: false,
			minWidth: 54,
			width: 54,
			formatter: ({ row }) => <IconButton style={{ fontSize: "20px" }} onClick={() => { history.push(row.idDebito ? `mail/${row.ente}/${row.idTributo}/${row.idDebito}` : `mail/${row.id}`); }}><i className="fa fa-search" /></IconButton>
		},
		!enteOperatore && { key: "ente", name: "Ente", sortColumn: "ente.codiceEnte", sortable: true, frozen: false /*true*/, draggable: false, resizable: true, highlight: true, minWidth: 200, width: 240 },
		{ key: "id", name: "Id", sortColumn: "id", sortable: true, frozen: false /*true*/, draggable: false, resizable: true, highlight: true, minWidth: 100, width: 100 },
		{
			key: "stato",
			name: "Stato",
			sortColumn: "stato",
			sortable: true,
			frozen: false,
			draggable: true,
			highlight: false,
			resizable: true,
			minWidth: 150,
			width: 150,
			formatter: ({ row }) => <StatoLabel stato={row.stato} />
		},
		{ key: "dataCaricamento", name: "Data Caricamento", sortColumn: "tsInserimento", sortable: true, draggable: true, resizable: true, highlight: true, minWidth: 180, width: 180 },
		{ key: "dataInvio", name: "Data Invio", sortColumn: "tsAggiornamento", sortable: true, draggable: true, resizable: true, width: 200 },
		{ key: "oggetto", name: "Oggetto", sortColumn: "oggetto", sortable: true, frozen: false, draggable: true, highlight: true, minWidth: 260, resizable: true },
		{ key: "destinatario", name: "Destinatario", sortColumn: "emailDestinatario", sortable: true, frozen: false, draggable: true, highlight: true, minWidth: 260, resizable: true }
	], ["dettagli", !enteOperatore && "ente", "id", "stato", "destinatario", "dataCaricamento", "dataInvio", "oggetto"]);

	useEffect(() => setQuery((q) => ({ ...q, orderBy: sortFilter })), [JSON.stringify(sortFilter)]);

	const tableData = data?.data?.map((r) => ({
		ente: r.idEnte ?? "",
		idTributo: r?.idTributo ?? "",
		idDebito: r?.idDebito ?? "",
		id: r.idEmail.toString() ?? "",
		stato: r.stato ?? "",
		oggetto: r.oggetto ?? "",
		destinatario: r.emailDestinatario ?? "",
		dataCaricamento: moment(r.timestampCreazione).format("DD/MM/yyyy HH:mm:SS") ?? "",
		dataInvio: r.timestampInvio ? new Date(r.timestampInvio).toLocaleString() : "--Non inviata--"
	}));

	const refresher = useRef(null);

	useEffect(() => {
		const needRefreshes = data?.data?.some((v) => v.stato === "DaInviare") ?? false;

		if (refresher.current == null && needRefreshes) {
			refresher.current = setInterval(() => {
				setRefresh(Date.now());
			}, 2000);
		}

		if (refresher.current != null && !needRefreshes) {
			clearInterval(refresher.current);
			refresher.current = null;
		}
	}, [data]);

	if (!data) return (<SpinnyPage />);

	const FilterFields = [
		[
			() => <Divider><h3>Informazioni generali</h3></Divider>
		],
		[
			...(enteOperatore ? [] : [(r, c) => (
				<div style={{ width: "300px" }}>
					<Controller render={({ field: { onChange, value } }) => (<EnteSelect onChange={onChange} value={value} />)} name="idEnte" control={c} />
				</div>
			)]),
			(r, c) => (
				<div style={{ width: "300px" }}>
					<TributoPick control={c} enteOperatore={enteOperatore} fieldWatchName="idEnte" name="tributo" />
				</div>
			)
		],
		[
			(r) => (<TextField {...r('idEmail')} label="Id email" variant="standard" style={{ width: "100px" }} />),
			(r) => (<TextField {...r('idDebito')} label="Id debito" variant="standard" style={{ width: "500px" }} />)
		],
		[
			() => <Divider className="mt-3" />
		],
		[
			(r, c) => (<RangePicker control={c} nameStart="inizioTimestampCreazione" nameEnd="fineTimestampCreazione" variant="standard" startText="Creazione da" endText="Creazione a" />)
		],
		[
			(r, c) => (<RangePicker control={c} nameStart="inizioTimestampInvio" nameEnd="fineTimestampInvio" variant="standard" startText="Invio da" endText="Invio a" />)
		],
		[
			() => <Divider className="mt-3" />
		],
		[
			(r, c) => (<StatoInvioEmail ctr={c} name="stato" />)
		]
	];

	return (
		<div>
			<ToolsArea
				className="mt-4 mb-3"
				queryParameters={[
					'idEnte', 'tributo.idTributo.codiceTributo', 'idDebito', 'stato', "idEmail"
				]}
				disableDelete
				disableEdit
				disableNew
				filtersChanged={(filters) => setQuery({ ...query, ...filters })}
				fields={FilterFields}
				callStatus={status}
				allColumns={allColumns}
				visibleColumns={visible}
				setVisibleColumns={setVisible}
			/>
			{!data || status === "fetching" ? <SpinnyPage /> : (
				<DataGrid
					columns={visibleColumns}
					rows={tableData}
					query={query?.query?.split(" ") ?? []}
					onSortColumnsChange={(col) => setOrder(col.map((c) => c.key))}
					rowKeyGetter={(row) => row.codiceEnte}
					totalResults={data.totalResults}
					setPage={(page) => setQuery({ ...query, page })}
					currPage={query.page}
					sortData={sort}
					onSortDataChange={setSort}
				/>
			)}
			<br />
			<br />
			<br />
		</div>
	);
}
