import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from "react-redux";
import Feedback from "./Components/Feedback";

// - Utils
import { useFetch } from '../Hooks/useFetch';

// - Layout
import Header from './Layout/Header';
import Nav from './Layout/Nav';

// - Pages
import Pendenze from "./Pages/Pendenze";
import Enti from './Pages/Enti';
import Operatori from './Pages/Operatori';
import Strutture from './Pages/Strutture';
import Tributi from './Pages/Tributi';
import Pos from "./Pages/Pos";
import Tassi from "./Pages/Tassi";
import Export from "./Pages/Export";
import Pagamenti from "./Pages/Pagamenti";
import DettaglioDebito from "./Pages/DettaglioDebito";
import PagamentoPos from "./Pages/PagamentoPos";
import Index from "./Pages/CaricamentoPendenze";
import Cruscotto from "./Pages/Cruscotto";
import Automazioni from "./Pages/Automazioni";
import Email from "./Pages/Email";
import DettaglioMail from "./Pages/DettaglioMail";
import FeedbackUtenti from "./Pages/FeedbackUtenti";
import EditDebito from "./Pages/EditDebito";
import Rendicontazioni from "./Pages/Rendicontazioni";
import Reportistica from "./Pages/Reportistica";

import './index.css';
import Manuali from "./Pages/Manuali";
import Patchnotes from "./Pages/Patchnotes";

export default function Backoffice() {
	const { path, url } = useRouteMatch();

	const accessToken = useSelector((state) => state.authentication.accessToken);
	const history = useHistory();

	if (!accessToken) history.push("/");

	// - Effettuo la chiamata api per ricevere le informazioni dell'utente
	const { data: accountInfo } = useFetch('/user/info', null);

	// - Non si mostra nulla se l'utente non è stato fetchato
	if (!accountInfo) return (<></>);

	return (
		<div className="d-flex flex-column" style={{ height: '100%', width: "100%" }}>
			<Header anagrafica={accountInfo.intestatario} link={path} />
			<div className="d-flex flex-row" style={{ flex: '1 0 auto', width: "100%" }}>
				<Nav permessi={accountInfo.ruolo.permessi} />
				<main style={{ background: '#E9E9E9', flexGrow: 1, minWidth: 0, padding: '0 20px' }}>
					<Route exact path={path}><Cruscotto account={accountInfo} /></Route>
					<Route exact path={`${path}/enti`}><Enti account={accountInfo} /></Route>
					<Route exact path={`${path}/operatori`}><Operatori account={accountInfo} /></Route>
					<Route exact path={`${path}/strutture`}><Strutture account={accountInfo} /></Route>
					<Route exact path={`${path}/tributi`}><Tributi account={accountInfo} /></Route>
					<Route exact path={`${path}/pos`}><Pos account={accountInfo} /></Route>
					<Route exact path={`${path}/pendenze`}><Pendenze account={accountInfo} /></Route>
					<Route exact path={`${path}/pagamenti`}><Pagamenti account={accountInfo} /></Route>
					<Route exact path={`${path}/rendicontazioni`}><Rendicontazioni account={accountInfo} /></Route>
					<Route exact path={`${path}/caricamentoPendenze`}><Index account={accountInfo} /></Route>
					<Route exact path={`${path}/export`}><Export account={accountInfo} /></Route>
					<Route exact path={`${path}/feedback`}><FeedbackUtenti account={accountInfo} /></Route>
					<Route exact path={`${path}/tassi`}><Tassi account={accountInfo} /></Route>
					<Route exact path={`${path}/pagamentoPos`}><PagamentoPos account={accountInfo} /></Route>
					<Route exact path={`${path}/automazioni`}><Automazioni account={accountInfo} /></Route>
					<Route exact path={`${path}/mail`}><Email account={accountInfo} /></Route>
					<Route exact path={`${path}/reportistica`}><Reportistica account={accountInfo} /></Route>
					<Route exact path={`${path}/manuali`}><Manuali account={accountInfo} /></Route>
					<Route exact path={`${path}/patchnotes`}><Patchnotes account={accountInfo} /></Route>
					<Route path={`${path}/mail/:ente/:tributo/:codice`}><DettaglioMail /></Route>
					<Route path={`${path}/mail/:id`}><DettaglioMail /></Route>
					<Route exact path={`${path}/debito/:ente/:tributo/:codice`}><DettaglioDebito account={accountInfo} /></Route>
					<Route exact path={`${path}/debito/:ente/:tributo/:codice/:altDebito`}><DettaglioDebito account={accountInfo} /></Route>
					<Route exact path={`${path}/debito/:ente/:tributo/:codice/:altDebito/edit`}><EditDebito account={accountInfo} /></Route>
					<Feedback popper tributi={[]} show={false} />
				</main>
			</div>
		</div>
	);
}
