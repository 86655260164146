/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react';
import configuration from '../configuration';
import useToken from "./useToken";

const useFetch = (query, ente, method, body, refresh) => {
	const [status, setStatus] = useState('idle');
	const [errors, setErrors] = useState(null);
	const [data, setData] = useState(null);

	// - Prendo il token JWT
	const jwt = useToken();

	useEffect(() => {
		const abortController = new AbortController();

		const fetchData = async () => {
			setErrors(null);

			if (!query) {
				setData(null);
				setStatus("idle");
				return;
			}

			const enteHeader = ente ? { Ente: ente } : {};
			const optionalBody = body ? { body: JSON.stringify(body) } : {};

			setStatus('fetching');

			const response = await fetch(`${configuration.serverAddress}${query}`, {
				method,
				...optionalBody,
				headers: new Headers({
					...(jwt ? { Authorization: `Bearer ${jwt}` } : {}),
					...enteHeader,
					...configuration.defaultHeaders
				}),
				signal: abortController.signal
			});

			const jsonResponse = await response.json();

			setStatus('fetched');

			if (response.status >= 400) {
				setErrors(jsonResponse);
				setData(null);
				return;
			}

			setData(jsonResponse);
		};

		fetchData();

		return () => abortController.abort();
	}, [query, ente, JSON.stringify(body), refresh, jwt]);

	return { status, errors, data };
};

export { useFetch };
