import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
	TextareaAutosize,
	Rating,
	Popper,
	Box,
	Snackbar,
	Alert,
	Fade,
	IconButton,
	ClickAwayListener,
	useMediaQuery,
	Tooltip
} from "@mui/material";
import { Button, ModalHeader, ModalBody, Modal, ModalFooter } from "design-react-kit";
import axios from "axios";
import Captcha from "./Captcha";

export default function Feedback({ modal, show, tributi, popper }) {
	const [isOpen, setIsOpen] = useState(false);
	const [isHover, setIsHover] = useState(false);
	const [isSent, setIsSent] = useState(false);
	const [showNotification, setShowNotification] = useState(false);
	const [ratingValue, setRatingValue] = useState(null);
	const [feedbackText, setFeedbackText] = useState("");
	const [id, setId] = useState("idPopperFeedback");
	const [anchorEl, setAnchorEl] = useState(null);
	const [captcha, setCaptcha] = useState(null);
	const isMediaMobile = useMediaQuery("(max-width: 445px) or (max-height: 500px)");
	const isMediaHeight = useMediaQuery("(max-height: 550px)");
	const isMediaLimit = useMediaQuery("(max-height: 429px) or (max-width: 359px)");

	const user = useSelector((state) => state.authentication.user);
	const captchaRef = useRef();

	const handleOpen = (event) => {
		setAnchorEl(event.currentTarget);
		setIsOpen(!isOpen);
	};
	const handleHover = (event) => {
		setAnchorEl(event.currentTarget);
		setIsHover(true);
	};

	const handleClose = () => {
		setIsOpen(false);
		setIsHover(false);
		setRatingValue(null);
	};

	const handleNotificationClose = () => {
		setShowNotification(false);
	};

	useEffect(() => {
		setIsOpen(show);
	}, [show]);

	const sendFeedback = () => {
		const axiosParams = {
			method: "POST",
			url: '/api/feedback/create',
			headers: new Headers({
				"Content-Type": "application/json",
				...(captcha ? { Captcha: captcha } : {})
			}),
			data: {
				valutazione: ratingValue,
				descrizione: feedbackText,
				tributi: [...tributi]
			}
		};
		axios(axiosParams)
			.then((response) => {
				setIsOpen(false);
				setIsSent(true);
				setShowNotification(true);
			})
			.catch((e) => {
				captchaRef.current?.reset();
				setCaptcha(null);
				setIsSent(false);
				setIsOpen(false);
				setShowNotification(true);
			});
	};

	const handleChangeDesc = (e) => setFeedbackText(e.target.value);
	const handleChangeRating = (event, newValue) => {
		setRatingValue(newValue);
	};

	const feedBackBox = (
		<div>
			<p className="mb-0 pt-2">Puoi lasciare un voto e dei suggerimenti completamente anonimi.</p>
			<div className="row justify-content-center pt-2">
				<Rating
					id="rating-feedback-id"
					name="hover-feedback"
					size="large"
					onChange={handleChangeRating}
					icon={<i className="fas fa-star fa-xs p-1" />}
					emptyIcon={<i className="fas fa-star fa-xs p-1" />}
				/>
			</div>
			<div className="pt-1">
				<p className="mb-0"><strong>Suggerimenti:</strong></p>
				<TextareaAutosize
					minRows={3}
					className="px-3 rounded"
					onChange={handleChangeDesc}
					maxLength={1000}
				/>
			</div>
			<p className="mb-1">
				Ai suggerimenti non verrà fornita risposta, ma
				saranno presi in considerazione per migliorare il servizio.
			</p>
		</div>
	);

	const SnackbarBox = () => (
		<Snackbar
			open={showNotification}
			autoHideDuration={6000}
			onClose={handleNotificationClose}
			key="bottomright"
			anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
		>
			<Alert
				onClose={handleNotificationClose}
				severity={isSent ? "success" : "error"}
				sx={{ width: '100%' }}
			>
				{ isSent ? "Feedback inviato!" : "Feedback non inviato!" }
			</Alert>
		</Snackbar>
	);

	if (modal) {
		return (
			<>
				<Modal
					className="modal-dialog"
					isOpen={isOpen && !isSent && show}
					size="md"
					toggle={() => setIsOpen(!isOpen)}
					labelledBy="form-feedback"
				>
					<ModalHeader id="form-feedback" toggle={() => setIsOpen(!isOpen)} className="modal-header">
						Valutazione pagoPA Sardegna
					</ModalHeader>
					<ModalBody>
						{feedBackBox}
						{!user && <Captcha doneCallback={setCaptcha} captchaRef={captchaRef} />}
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={() => sendFeedback()} disabled={isSent || (!user && !captcha)} title={isSent ? "Feedback già inviato" : "Invia feedback"}>
							Invia
						</Button>
					</ModalFooter>
				</Modal>
				<SnackbarBox />
			</>
		);
	}

	if (popper) {
		return (
			<>
				{
					!isSent && (
						<ClickAwayListener onClickAway={handleClose}>
							<div
								className="position-fixed mr-4 mb-4"
								style={{
									right: "0",
									bottom: "0"
								}}
							>
								<Tooltip title="Lascia un feedback" placement="left">
									<IconButton
										id="icon-button-feedback"
										aria-describedby={id}
										onClick={handleOpen}
										className="rounded-circle bg-white shadow-sm"
										onMouseOver={handleHover}
										style={{
											display: isMediaLimit ? "none" : "",
											color: "#1d2d7e"
										}}
									>
										<i className="fas fa-thumbs-up fa-sm p-1" />
									</IconButton>
								</Tooltip>
								<Popper
									id={id}
									open={isOpen && isHover}
									anchorEl={anchorEl}
									placement="top"
									transition
									style={isMediaMobile ? { width: "100%", height: isMediaHeight ? "70%" : "" } : {}}
								>
									{({ TransitionProps }) => (
										<Fade {...TransitionProps} timeout={500}>
											<Box className="bg-white p-3 rounded shadow mr-4 mb-3" sx={{ width: isMediaMobile ? "100%" : 350 }} style={{ display: isMediaLimit ? "none" : "block" }} fontSize="15px">
												<div className="d-flex">
													<h5 className="text-center mb-2">Valutazione pagoPA Sardegna</h5>
													<IconButton aria-label="close" onClick={handleClose} size="small" style={{ right: 1, top: 0 }} className="position-absolute mr-4 mt-2">
														<svg className="icon"><use xlinkHref="./bootstrap-italia/svg/sprite.svg#it-close" /></svg>
													</IconButton>
												</div>
												{feedBackBox}
												{!user && <Captcha doneCallback={setCaptcha} captchaRef={captchaRef} />}
												<div className="d-flex justify-content-end">
													<Button
														color="primary"
														onClick={() => sendFeedback()}
														disabled={isSent || (!user && !captcha)}
														title={isSent ? "Feedback già inviato" : "Invia feedback"}
														className="btn-xs"
													>
														Invia
													</Button>
												</div>
											</Box>
										</Fade>
									)}
								</Popper>
							</div>
						</ClickAwayListener>
					)
				}
				<SnackbarBox />
			</>
		);
	}
}
