import React from "react";
import { Controller } from "react-hook-form";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useFetch } from "../../../Hooks/useFetch";

export default function CategoriaTributo({ control, nullItem = false, required }) {
	const { data: categorie } = useFetch('/tributo/categorie', null, 'GET');

	return categorie && (
		<Controller
			name="categoria.codiceCategoria"
			required={required}
			control={control}
			render={({ field: { onChange, value } }) => (
				<FormControl variant="standard" className="w-100" required={required}>
					<InputLabel id="tipoTributo">Categoria tributo</InputLabel>
					<Select labelId="tipoTributo-label" id="tipoTributo" label="Categoria tipologia di pagamento" required={required} value={value || ''} onChange={onChange}>
						{nullItem && (<MenuItem key={null} value={null}>Nessun filtro</MenuItem>)}
						{
							categorie && categorie.map((categoria) => (
								<MenuItem
									key={categoria.codiceCategoria}
									value={categoria.codiceCategoria}
								>
									{categoria.nome}
								</MenuItem>
							))
						}
					</Select>
				</FormControl>
			)}
		/>
	);
}
