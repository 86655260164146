import React, { useState } from "react";
import {
	Box,
	Tab,
	Tabs
} from "@mui/material";
import CaricamentoCsv from "./CaricamentoCsv";
import CaricamentoForm from "./CaricamentoForm";

export default function Index({ account }) {
	const [value, setValue] = useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box className="bg-white mt-4 p-3">
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs value={value} onChange={handleChange}>
					<Tab label="Caricamento singolo" />
					<Tab label="Caricamento massivo" />
				</Tabs>
			</Box>
			<div hidden={value !== 0}>
				<CaricamentoForm account={account} />
			</div>
			<div hidden={value !== 1} className="pt-3">
				<CaricamentoCsv account={account} />
			</div>
		</Box>
	);
}
