/* eslint-disable eqeqeq */
import { FormControl, IconButton, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

export default function AzioneHideSelectOptions({ onDelete, value, onChange, campi }) {
	const campo = campi.find((f) => f.nome === value.nomeCampoTarget);
	const valori = value?.valore.length > 0 ? value.valore : campo?.valori.map((val) => val.hidden);

	return (
		<div className="d-flex flex-row bg-lightblue rounded mt-2 mb-2 pl-3 pr-3 pb-2 pt-1 align-middle">
			<IconButton size="small" className="mr-2" onClick={onDelete}>
				<i className="fas fa-fw fa-trash" />
			</IconButton>

			<FormControl variant="standard" className="ml-3" style={{ width: "300px" }}>
				<InputLabel>Campo</InputLabel>
				<Select variant="standard" value={value.nomeCampoTarget} onChange={(v) => onChange({ ...value, nomeCampoTarget: v.target.value })}>
					{campi.filter((c) => c["@class"].split(".").pop() === "CampoSelect").map((v) => (<MenuItem value={v.nome} key={v.nome}>{v.label}</MenuItem>))}
				</Select>
			</FormControl>

			<FormControl variant="standard" className="w-100 ml-5" style={{ width: "200px" }}>
				<div style={{ display: "flex" }}>
					<div className="m-1">
						<h5><b>Opzione</b></h5>
						{
							campo?.valori.map((item) => (
								<div>
									{item.label}
								</div>
							))
						}
					</div>
					<div className="m-1" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
						<h5><b>Nascosto</b></h5>
						{
							campo?.valori.map((item, i) => (
								<div>
									<input
										type="checkbox"
										style={{ transform: "scale(1.5)" }}
										checked={valori[i]}
										onChange={(v) => {
											onChange({ ...value, valore: valori
												.map((val, ii) => (i === ii ? v.target.checked : val)) });
										}}
									/>
								</div>
							))
						}
					</div>

				</div>
			</FormControl>
		</div>
	);
}
