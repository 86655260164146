/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";

export default function Breadcrumbs(props) {
	const user = useSelector((state) => state.authentication.user);
	const {
		breadcrumbs
	} = props;

	let lista = '';

	if (breadcrumbs) {
		lista = breadcrumbs.map((b, i) => (
			// eslint-disable-next-line react/no-array-index-key
			<li className="breadcrumb-item" key={`breadcrumb${i}`}>
				{b.titolo}
			</li>
		)).reduce((acc, x) => (
			acc === null ? x
				: (
					<>
						{acc}
						<li className="breadcrumb-item">
							<span className="separator">/</span>
						</li>
						{x}
					</>
				)
		), null);
	}

	return (
		<>
			<div className="container py-2 d-print-none" id="breadcrumbs">
				<nav className="breadcrumb-container" aria-label="breadcrumb">
					<ol className="breadcrumb mb-0 mt-2">
						{user && (
							<li className="breadcrumb-item">
								<Link to="/paga" title="Vai alla pagina: Home" className="">paga </Link>
							</li>
						)}
						{!user && (
							<li className="breadcrumb-item">
								<Link to="/" title="Vai alla pagina: Home" className="">home </Link>
							</li>
						)}
						<li className="breadcrumb-item">
							<span className="separator">/</span>
						</li>
						{lista}
					</ol>
				</nav>
			</div>
		</>
	);
}
