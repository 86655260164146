import React from "react";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import NumberFormat from "react-number-format";

export default function CampoImportoSetting({ value: objValue, onChange }) {
	return (
		<div>
			<NumberFormat
				value={objValue.defaultValue}
				onValueChange={({ floatValue, value }, sourceInfo) => {
					onChange({ ...objValue, defaultValue: floatValue });
				}}
				className="w-100"
				label="Importo di default"
				variant="standard"
				defaultValue={null}
				customInput={TextField}
				prefix="€ "
				decimalScale={2}
				decimalSeparator=","
				thousandSeparator="."
			/>
			<NumberFormat
				value={objValue.min}
				onValueChange={({ formattedValue, value }, sourceInfo) => onChange({
					...objValue, min: formattedValue })}
				className="w-25 mt-3"
				label="Importo minimo"
				variant="standard"
				defaultValue={null}
				customInput={TextField}
				prefix="€ "
				decimalScale={2}
				decimalSeparator=","
				thousandSeparator="."
			/>
			<NumberFormat
				value={objValue.max}
				onValueChange={({ formattedValue, value }, sourceInfo) => onChange({
					...objValue, max: formattedValue })}
				className="w-25 ml-4 mt-3"
				label="Importo massimo"
				variant="standard"
				defaultValue={null}
				customInput={TextField}
				prefix="€ "
				decimalScale={2}
				decimalSeparator=","
				thousandSeparator="."
			/>
			<br />
			<FormControl variant="standard" className="mt-3" style={{ width: "200px" }}>
				<InputLabel>Tipo voce</InputLabel>
				<Select variant="standard" value={objValue.tipoDettaglio} onChange={(v) => onChange({ ...objValue, tipoDettaglio: v.target.value })}>
					<MenuItem value="Capitale">Capitale</MenuItem>
					<MenuItem value="Codice">Codice</MenuItem>
					<MenuItem value="Interessi">Interessi</MenuItem>
					<MenuItem value="Sanzioni">Sanzioni</MenuItem>
					<MenuItem value="Altro">Altro</MenuItem>
				</Select>
			</FormControl>

			<TextField
				label="Capitolo entrata"
				variant="standard"
				className="w-25 ml-3 mt-3"
				value={objValue.capitoloEntrata}
				onChange={(v) => onChange({ ...objValue, capitoloEntrata: v.target.value })}
			/>

			<TextField
				label="Codice voce"
				variant="standard"
				className="ml-3 mt-3"
				style={{ width: "300px" }}
				value={objValue.codiceDettaglio}
				onChange={(v) => onChange({ ...objValue, codiceDettaglio: v.target.value })}
			/>

			<TextField
				label="Descrizione voce"
				variant="standard"
				className="w-100 mt-3"
				value={objValue.nomeDettaglio}
				onChange={(v) => onChange({ ...objValue, nomeDettaglio: v.target.value })}
			/>
		</div>
	);
}
