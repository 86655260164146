/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import ListaCard from '../Common/ListaCard';
import ListaCardSingola from '../Common/ListaCardSingola';

import { useFetch } from "../../../Hooks/useFetch";
import Paginazione from '../Common/Paginazione';
import SpinnyPage from "../../../Backoffice/Components/SpinnyPage";

export default function ListaEnti(props) {
	const {
		searchString
	} = props;
	const { t } = useTranslation('translation');

	const pageSize = useSelector((state) => state.pagination.perPage);
	const [page, setPage] = useState(1);

	useEffect(() => setPage(1), [searchString]);

	const { data, status } = useFetch('/ente/query-public', null, 'POST', {
		pageSize,
		page: page - 1,
		filtraNoTributi: true,
		query: searchString ?? '',
		stato: "Attivo",
		queryMandatoryFindAll: true,
		queryParameters: ["intestatario.denominazione"],
		orderBy: [{ field: "intestatario.denominazione", desc: false }]
	});

	const numRisultati = data?.totalResults ? data?.totalResults : 0;

	const lista = data?.data.map((e) => (
		<ListaCardSingola
			nome={e.intestatario.denominazione}
			link={`/servizi/${e.codiceEnte}/elenco`}
			//testoLink={`${e.totTributi} tipologi${e.totTributi === 1 ? "a" : "e"} di pagamento`}
			testoLink={t('card.tipologiaPag', { count: e.totTributi, type: e.totTributi === 1 ? 'a' : 'e' })}
			key={`ente-${e.codiceEnte}`}
		/>
	)) ?? <></>;

	if (status === "fetching") {
		return <SpinnyPage />;
	}

	return (
		<>
			<section className="section section-inset-shadow pb-0 pt-2 pb-5">
				<div className="container pt-2 pb-4 px-0 px-md-3" id="content">
					<ListaCard
						risultatiTotali={numRisultati}
						elementi={lista}
						paginaCorrente={page}
						perPagina={pageSize}
					/>
					<Paginazione
						paginaCorrente={page}
						perPagina={pageSize}
						risultatiTotali={numRisultati}
						onPageChange={(p) => setPage(p)}
					/>
				</div>
			</section>
		</>
	);
}
