/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import { getCurrentPageNumber, urlToPageNumber } from '../../../Utils/Pagination';

import { setPerPage } from '../../../Store/Pagination';
import Select from './Select';
import OnClickLink from './OnClickLink';

const MAX_NUMBERS_EACH_SIDE = 2;

export default function Paginazione(props) {
	const dispatch = useDispatch();

	const {
		risultatiTotali,
		paginaCorrente,
		perPagina,
		onPageChange
	} = props;
	const { t } = useTranslation('translation');

	const minIndex = perPagina * (paginaCorrente - 1) + 1;
	let maxIndex = perPagina * (paginaCorrente);

	if (maxIndex > risultatiTotali) {
		maxIndex = risultatiTotali;
	}

	const previous = paginaCorrente - 1;
	const maxPage = Math.ceil(risultatiTotali / perPagina);
	const next = paginaCorrente < maxPage ? paginaCorrente + 1 : 0;

	let startLoopAt = paginaCorrente - MAX_NUMBERS_EACH_SIDE;
	if (startLoopAt <= 0) {
		startLoopAt = 1;
	}

	let endLoopAt = paginaCorrente + MAX_NUMBERS_EACH_SIDE;
	if (endLoopAt > maxPage) {
		endLoopAt = maxPage;
	}

	console.log(paginaCorrente);

	const links = [];
	for (let i = startLoopAt; i <= endLoopAt; i++) {
		console.log(i, paginaCorrente === i ? "bold" : "normal");
		links.push(
			<li className="page-item" key={`paginazione${i}`}>
				<OnClickLink className="page-link" style={{ fontWeight: paginaCorrente === i ? "bold" : "normal" }} onClick={() => onPageChange(i)}>{i}</OnClickLink>
			</li>
		);
	}

	return (
		<div>
			<nav className="pagination-wrapper">
				{risultatiTotali > 0 ? (

					<div className="paginazione-conteggio-elementi">
						{minIndex}-{maxIndex} di {risultatiTotali}
					</div>
				) : (
					<div className="paginazione-conteggio-elementi">
						{t('homePageCard.nessunRisultato')}
					</div>
				)}
				<ul className="pagination">
					{previous > 0 && (
						<li className="page-item" key="paginazione-prev">
							<OnClickLink className="page-link" onClick={() => onPageChange(previous)}>
								<svg className="icon icon-primary"><use xlinkHref="./bootstrap-italia/svg/sprite.svg#it-chevron-left" /></svg>
								<span className="sr-only">Pagina precedente</span>
							</OnClickLink>
						</li>
					)}

					{links}

					{next > 0 && (
						<li className="page-item" key="paginazione-next">
							<OnClickLink className="page-link" onClick={() => onPageChange(next)}>
								<span className="sr-only">Pagina successiva</span>
								<svg className="icon icon-primary"><use xlinkHref="./bootstrap-italia/svg/sprite.svg#it-chevron-right" /></svg>
							</OnClickLink>
						</li>
					)}
				</ul>
				<div className="selezione-per-pagina bootstrap-select-wrapper">
					<label htmlFor="paginazione-per-pagina">
						{t('homePageCard.risultati')}
					</label>
					<Select
						nome="paginazione"
						valoreSelezionato={perPagina}
						onChange={(event) => { dispatch(setPerPage(event)); }}
						scelte={{
							15: 15,
							30: 30,
							45: 45,
							60: 60
						}}
					/>
				</div>
			</nav>
		</div>
	);
}
