import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, IconButton, MenuItem, Select, Typography } from "@mui/material";
import ValidatoreCodiceFiscale from "./Editor/Aggregatori/ValidatoreCodiceFiscale";

export default function AggregatoriManager({ value, campi, onChange }) {
	const [expanded, setExpanded] = useState(false);
	const [selectedType, setSelectedType] = useState("ValidatoreCodiceFiscale");

	const createAggregatore = (type) => {
		let typeFields = {};

		if (type === "ValidatoreCodiceFiscale") {
			typeFields = {
				campoCodiceFiscale: null,
				campoNome: null,
				campoCognome: null,
				campoLuogoDiNascita: null,
				campoDataDiNascita: null
			};
		}

		return {
			"@class": `it.ras.pagopa.shared.plugins.pagamenti.campi.metadata.aggregatore.${type}`,
			...typeFields
		};
	};

	return (
		<div>
			{
				value?.map((aggregatore, i) => (
					<Accordion
						key={`field-${i + 1}`}
						expanded={expanded === i}
						onChange={(_, isExpanded) => setExpanded(isExpanded ? i : false)}
					>
						<AccordionSummary>
							<IconButton
								size="small"
								className="mr-2"
								onClick={() => { onChange(value.filter((x, idx) => idx !== i)); }}
							>
								<i className="fas fa-fw fa-trash" />
							</IconButton>
							<Typography noWrap sx={{ overflow: "hidden", textOverflow: "ellipsis", width: '250px', lineHeight: "32px", flexShrink: 0 }}>
								<b className="mr-3">{aggregatore["@class"].split(".").pop()}</b>
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							{
								{
									ValidatoreCodiceFiscale: <ValidatoreCodiceFiscale value={aggregatore} campi={campi} onChange={(newValue) => onChange(value.map((agg, idx) => (idx === i ? newValue : agg)))} />
								}[aggregatore["@class"].split('.').pop()]
							}
						</AccordionDetails>
					</Accordion>
				))
			}

			<div className="d-inline-flex flex-row align-middle mt-4">
				<Select style={{ width: "500px" }} variant="standard" value={selectedType} onChange={(v) => setSelectedType(v.target.value)}>
					<MenuItem defaultChecked value="ValidatoreCodiceFiscale">Validatore codice fiscale</MenuItem>
				</Select>
				<IconButton onClick={() => onChange([...value, createAggregatore(selectedType)])}><i className="fas fa-fw fa-plus-circle" /></IconButton>
			</div>
		</div>
	);
}
