/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import { TextField } from '@mui/material';
import moment from "moment";
import NumberFormat from "react-number-format";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import DateAdapter from "@mui/lab/AdapterMoment";
import { useFetch } from '../../Hooks/useFetch';
import ToolsArea from '../Components/ToolsArea';
import DeleteItem from "../Components/DeleteItem";
import EditModForm from "../Components/Forms/EditModForm";
import SpinnyPage from "../Components/SpinnyPage";
import DataGrid from "../Components/Tables/DataGrid";
import useTableColumns from "../Utils/useTableColumns";
import { DataGridSelectColumn } from "../Components/Tables/DataGridSelect";


export default function Tassi({ account }) {
	// - Table data
	const [refresh, setRefresh] = useState(0);
	const [query, setQuery] = useState({ page: 0, pageSize: 20, orderBy: [{ field: "anno", desc: true }] });
	const { data } = useFetch('/tassi/query', null, 'POST', query, refresh);
	const [deleteOpen, setDeleteOpen] = useState(false);
	const [createEditMode, setCreateEditMode] = useState(false);
	const [selectedRows, setSelectedRows] = useState(new Map());

	const selected = [...selectedRows];

	const { visibleColumns, allColumns, sort, sortFilter, setSort, visible, setVisible, setOrder } = useTableColumns("tassi", [
		DataGridSelectColumn,
		{ key: "anno", name: "Anno", sortColumn: "anno", sortable: true, frozen: false /*true*/, draggable: false, resizable: true, highlight: true, minWidth: 200, width: 240 },
		{ key: "valore", name: "Valore", sortColumn: "valore", sortable: true, frozen: false, draggable: false, highlight: true, resizable: true, minWidth: 200, width: 200 },
		{ key: "decorrenza", name: "Decorrenza", sortColumn: "decorrenza", sortable: true, frozen: false, draggable: false, highlight: true, resizable: true }
	], [DataGridSelectColumn.key, "anno", "valore", "decorrenza"]);

	useEffect(() => setQuery((q) => ({ ...q, orderBy: sortFilter })), [JSON.stringify(sortFilter)]);

	const tableData = data?.data?.map((r) => ({
		anno: r.anno.toString() ?? "",
		valore: r.valore.toString() ?? "",
		decorrenza: moment(r.decorrenza).format("DD/MM/yyyy") ?? ""
	}));

	const editCreateForm = ({ register, control }) => (
		<div className="flex-column d-flex ">
			<TextField {...register('anno')} required style={{ width: "300px" }} type="number" label="Anno" variant="standard" className="mt-2" id="anno" />
			<Controller
				name="valore"
				control={control}
				defaultValue={null}
				required
				render={({ field: { onChange: change, v } }) => (
					<NumberFormat
						onValueChange={({ formattedValue, value }, sourceInfo) => change(value)}
						value={v}
						required
						id="filled-adornment-amount"
						className="mt-3 w-50"
						label="Valore"
						variant="standard"
						customInput={TextField}
						decimalSeparator=","
						thousandSeparator="."
					/>
				)}
			/>
			<div className="mt-4" style={{ width: "300px" }}>
				<LocalizationProvider dateAdapter={DateAdapter}>
					<Controller
						name="decorrenza"
						control={control}
						defaultValue={null}
						required
						render={({ field: { onChange, value } }) => (
							<DesktopDatePicker
								label="Data di decorrenza"
								inputFormat="DD/MM/yyyy"
								value={moment(value, "yyyy-MM-DD")}
								onChange={(v) => onChange(v.format("yyyy-MM-DD"))}
								renderInput={(params) => <TextField {...params} />}
							/>
						)}
					/>
				</LocalizationProvider>
			</div>
		</div>
	);

	if (!data) return <SpinnyPage />;

	return (
		<div>
			<DeleteItem
				open={deleteOpen}
				query="/tassi/delete"
				entityNamePlural="tassi"
				entityNameSingle="tasso"
				items={selected.map((item) => ({ anno: item }))}
				onCancel={() => setDeleteOpen(false)}
				onDeleted={() => { setDeleteOpen(false); setRefresh(refresh + 1); setSelectedRows(new Set()); }}
				onError={() => setDeleteOpen(false)}
			/>
			<EditModForm
				path="/tassi"
				entityName="Tasso"
				defaultValue={{}}
				ente={createEditMode === 'edit' ? data.data.filter((d) => d.id === selected[0])[0].ente.codiceEnte : null}
				mode={createEditMode}
				data={createEditMode === 'edit' ? data.data.filter((d) => d.id === selected[0])[0] : null}
				onCancel={() => setCreateEditMode(false)}
				onSuccess={() => { setCreateEditMode(false); setRefresh(refresh + 1); }}
				formElements={editCreateForm}
			/>
			<ToolsArea
				className="mt-4 mb-3"
				selected={selected}
				disableEdit
				disableAdvancedSearch
				queryParameters={['anno', 'valore', 'dataDecorrenza']}
				filtersChanged={(filters) => setQuery({ ...query, ...filters })}
				deleteCallback={() => setDeleteOpen(true)}
				createCallback={() => setCreateEditMode('create')}
				editCallback={() => setCreateEditMode('edit')}
				allColumns={allColumns}
				visibleColumns={visible}
				setVisibleColumns={setVisible}
			/>
			<DataGrid
				columns={visibleColumns}
				rows={tableData}
				query={query?.query?.split(" ") ?? []}
				onSortColumnsChange={(col) => setOrder(col.map((c) => c.key))}
				rowKeyGetter={(row) => row.anno}
				totalResults={data.totalResults}
				selectedRows={selectedRows}
				setSelectedRows={setSelectedRows}
				setPage={(page) => setQuery({ ...query, page })}
				currPage={query.page}
				sortData={sort}
				onSortDataChange={setSort}
			/>
			<br />
			<br />
			<br />
		</div>
	);
}
