import { useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

import { WidgetInstance } from 'friendly-challenge';

const FriendlyCaptcha = ({ doneCallback, errorCallback, captchaRef }) => {
	const { i18n } = useTranslation();
	const container = useRef();
	const widget = useRef();

	useEffect(() => {
		if (!widget.current && container.current) {
			widget.current = new WidgetInstance(container.current, {
				puzzleEndpoint: "https://eu-api.friendlycaptcha.eu/api/v1/puzzle",
				startMode: "auto",
				language: i18n.language,
				readyCallback: () => {
					if (!process.env.REACT_APP_FRIENDLY_CAPTCHA_SITE_KEY || process.env.REACT_APP_FRIENDLY_CAPTCHA_SITE_KEY === "NO") doneCallback(true);
				},
				doneCallback,
				errorCallback: (error) => {
					if (!process.env.REACT_APP_FRIENDLY_CAPTCHA_SITE_KEY || process.env.REACT_APP_FRIENDLY_CAPTCHA_SITE_KEY === "NO") doneCallback(true);
					else errorCallback(error);
				}
			});
		}

		// eslint-disable-next-line
		if (captchaRef) captchaRef.current = widget?.current;

		return () => {
			if (widget.current !== undefined) widget.current.reset();
		};
	}, [container]);

	useEffect(() => () => widget.current.destroy(), []);

	useEffect(() => {
		// eslint-disable-next-line
		if (captchaRef) captchaRef.current = widget?.current;
	}, [captchaRef]);

	return (
		<div ref={container} className="frc-captcha mt-2 mb-2" style={{ height: "73px" }} data-sitekey={process.env.REACT_APP_FRIENDLY_CAPTCHA_SITE_KEY} />
	);
};

export default FriendlyCaptcha;
