import React from "react";
import { TextField } from "@mui/material";

export default function CampoDataSetting({ value, onChange }) {
	return (
		<div>
			<TextField
				label="Valore di default"
				variant="standard"
				className="w-100"
				value={value.defaultValue}
				onChange={(v) => onChange({ ...value, defaultValue: v.target.value })}
			/>
		</div>
	);
}
