
export default function handleErrors(error, setError) {
	if (error.codice === 400) {
		if (error.dettaglio) {
			error.dettaglio.forEach((e) => setError(e.field.split('.').at(-1), { message: e.description }));
		}

		const errorList = error.dettaglio.map((e) => (
			<li>
				<b>{`${e.field.split('.').at(-1)}: `}</b>
				{e.description}
			</li>
		));
		setError('general', { title: error.descrizioneErrore, description: (
			<>
				{error.messaggio}
				<br />
				{errorList}
			</>
		) });
	} else if (error.codice >= 400) {
		setError('general', { title: error.descrizioneErrore, description: error.messaggio });
	}
}
