import {
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	TextField
} from "@mui/material";
import NumberFormat from "react-number-format";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment as DateAdapter } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useProvince, useComuni } from "../../../Utils/Belfiore";
import { buf2hex } from "../../../Utils/Files";

export default {
	CampoStringa: ({ value, onChange, label, readonly, obbligatorio, visible, error, maxLen, regex, edit }) => (
		<TextField
			value={value}
			onChange={onChange}
			variant="standard"
			label={label}
			disabled={readonly}
			required={!edit && obbligatorio}
			className="w-100 mt-3"
			error={error}
			style={visible ? {} : { display: "none" }}
			inputProps={{ maxLength: maxLen, pattern: regex }}
		/>
	),
	CampoSelect: ({ value, onChange, valori, label, nome, readonly, obbligatorio, visible, error, edit }) => (
		<FormControl variant="standard" className="w-100 mt-3" style={visible ? {} : { display: "none" }}>
			<InputLabel id={`${nome}Id`}>{label}</InputLabel>
			<Select labelId={`${nome}Id`} label={label} value={value} required={!edit && obbligatorio} readOnly={readonly} onChange={onChange}>
				{
					valori.map((v) => <MenuItem key={v.value} value={v.value}>{v.label}</MenuItem>)
				}
			</Select>
		</FormControl>
	),
	CampoBelfiore: ({ value, onChange, valori, label, nome, readonly, obbligatorio, visible, error }) => {
		const [provincia, setProvincia] = useState("");
		const province = useProvince();
		const comuni = useComuni({ provincia });

		return (
			<div className="d-flex w-100 mt-3" style={{ columnGap: "10px", height: "47px", display: visible ? "flex" : "none" }}>
				<FormControl variant="standard" className="w-25" style={visible ? {} : { display: "none" }}>
					<InputLabel id={`select-provincia-${nome}`}>
						{`Provincia ${label}`}
					</InputLabel>
					<Select id={`select-provincia-${nome}`} label={label} placeholder={label} value={provincia} onChange={(v) => setProvincia(v.target.value)}>
						<MenuItem value="">Nessuno</MenuItem>
						{province.map((v) => <MenuItem value={v} key={v}>{v}</MenuItem>)}
					</Select>
				</FormControl>
				<FormControl variant="standard" className="w-75" style={visible ? {} : { display: "none" }}>
					<InputLabel id={`select-provincia-${nome}`}>
						{`Provincia ${label}`}
					</InputLabel>
					<Select id={`select-provincia-${nome}`} label={label} placeholder={label} value={value} onChange={(v) => onChange(v.target.value)}>
						<MenuItem value="">Nessuno</MenuItem>
						{comuni.map((v) => <MenuItem value={v.belfiore} key={v.belfiore}>{v.comune}</MenuItem>)}
					</Select>
				</FormControl>
			</div>
		);
	},
	CampoCheckbox: ({ value, onChange, label, readonly, obbligatorio, visible, error, edit }) => (
		<FormControlLabel
			style={visible ? {} : { display: "none" }}
			className="w-100 mt-3"
			disabled={readonly}
			required={!edit && obbligatorio}
			control={(
				<Checkbox
					label="Valore di default"
					variant="standard"
					checked={value}
					onChange={(v) => onChange(v.target.checked)}
				/>
			)}
			label={label}
		/>
	),
	CampoNumerico: ({
		value, onChange, label, readonly, obbligatorio, visible, error, min, max, edit
	}) => (
		<TextField
			value={value}
			onChange={onChange}
			variant="standard"
			label={label}
			disabled={readonly}
			required={!edit && obbligatorio}
			className="w-100 mt-3"
			type="number"
			error={error}
			InputProps={{ inputProps: { min, max } }}
			style={visible ? {} : { display: "none" }}
		/>
	),
	CampoImporto: ({
		value, onChange, label, readonly, obbligatorio, visible, error, min, max, edit
	}) => (
		<NumberFormat
			onValueChange={({ floatValue, value: v }, sourceInfo) => onChange(floatValue)}
			value={value}
			required={!edit && obbligatorio}
			id="filled-adornment-amount"
			className="mt-3 w-100"
			disabled={readonly}
			label={label}
			min={min}
			max={max}
			error={error}
			variant="standard"
			style={visible ? {} : { display: "none" }}
			defaultValue={0.1}
			customInput={TextField}
			prefix="€ "
			decimalScale={2}
			decimalSeparator=","
			thousandSeparator="."
		/>
	),
	CampoEmail: ({ value, onChange, label, readonly, obbligatorio, visible, error, edit }) => (
		<TextField
			value={value}
			onChange={onChange}
			variant="standard"
			type="email"
			label={label}
			error={error}
			disabled={readonly}
			required={!edit && obbligatorio}
			className="w-100 mt-3"
			style={visible ? {} : { display: "none" }}
		/>
	),
	CampoCFIVA: ({ value = { personaFisica: true }, onChange, readonly, obbligatorio, tipo, visible, error, label, edit }) => {
		const inputStringa = useRef(null);
		const personaFisica = value?.personaFisica ?? true;
		const setPersonaFisica = (v) => onChange({ ...value, personaFisica: v.target.value });
		useEffect(() => {
			if (personaFisica) {
				onChange({
					...value,
					denominazione: "",
					codiceFiscale: "",
					partitaIva: ""
				});
			} else onChange({ ...value, nome: "", cognome: "", cfIva: "" });
		}, [personaFisica]);
		useEffect(() => {
			if (personaFisica && tipo === "PersonaGiuridica") onChange({ ...value, personaFisica: false });
			if (!personaFisica && tipo === "PersonaFisica") onChange({ ...value, personaFisica: true });
		}, []);

		if (value === "") onChange({ personaFisica: true });
		else if (typeof value === "string") onChange(JSON.parse(value));

		return (
			<>
				{visible && <h4 className="mt-4">{label}</h4>}
				{
					tipo === "Entrambi" && (
						<FormControl variant="standard" className="w-100 mt-3" style={visible ? {} : { display: "none" }}>
							<InputLabel>Tipo persona</InputLabel>
							<Select
								label="Tipo persona"
								className="w-100"
								defaultValue
								required={!edit && obbligatorio}
								value={personaFisica}
								nome="Tipo persona"
								variant="standard"
								onChange={(v) => setPersonaFisica(v)}
							>
								<MenuItem value>Persona fisica</MenuItem>
								<MenuItem value={false}>Persona giuridica</MenuItem>
							</Select>
						</FormControl>
					)
				}
				<div ref={inputStringa} style={{ }} className={`mt-5 ${!visible ? "d-none" : ""}`}>
					{
						personaFisica && (
							<Grid container direction="column" rowGap="20px">
								<div>
									<TextField
										label={value?.straniero ? "Tax/health identification code" : "Codice Fiscale oppure P.IVA"}
										required={!edit && obbligatorio}
										className="w-100"
										variant="standard"
										onChange={(e) => {
											const start = e.target.selectionStart;
											const end = e.target.selectionEnd;
											e.target.value = e.target.value.toUpperCase();
											e.target.setSelectionRange(start, end);
											onChange({ ...value, cfIva: e.target.value.toUpperCase() });
										}}
										value={value?.cfIva}
										tipo="text"
										placeholder={value?.straniero ? "Your tax or health identification code" : "Inserire il Codice Fiscale o P.IVA"}
										descrizione=""
										errore={error?.cfIva?.message}
									/>
									<Switch
										onChange={(v) => onChange({ ...value, straniero: v.target.checked })}
										checked={value?.straniero ?? false}
									/>
									<b>Non sono un cittadino italiano (I am not an Italian citizen)</b>
								</div>
								<TextField
									label="Cognome"
									variant="standard"
									required={!edit && obbligatorio}
									InputProps={{ readOnly: readonly }}
									onChange={(e) => onChange({ ...value, cognome: e.target.value })}
									value={value?.cognome ?? ""}
									tipo="text"
									placeholder="Inserire il cognome"
									descrizione=""
									errore={error?.cognome?.message}
								/>
								<TextField
									label="Nome"
									variant="standard"
									required={!edit && obbligatorio}
									InputProps={{ readOnly: readonly }}
									onChange={(e) => onChange({ ...value, nome: e.target.value })}
									value={value?.nome ?? ""}
									tipo="text"
									placeholder="Inserire il nome"
									descrizione=""
									errore={error?.nome?.message}
								/>
							</Grid>
						)
					}
					{
						!personaFisica && (
							<Grid container direction="column" rowGap="20px">
								<TextField
									label="Codice Fiscale"
									variant="standard"
									InputProps={{ readOnly: readonly }}
									onChange={(e) => {
										const start = e.target.selectionStart;
										const end = e.target.selectionEnd;
										e.target.value = e.target.value.toUpperCase();
										e.target.setSelectionRange(start, end);
										onChange({ ...value, codiceFiscale: e.target.value.toUpperCase() });
									}}
									value={value?.codiceFiscale}
									tipo="text"
									placeholder="Inserire il Codice Fiscale"
									descrizione=""
									errore={error?.codiceFiscale?.message}
								/>
								<TextField
									label="Partita IVA"
									variant="standard"
									InputProps={{ readOnly: readonly }}
									onChange={(e) => {
										const start = e.target.selectionStart;
										const end = e.target.selectionEnd;
										e.target.value = e.target.value.toUpperCase();
										e.target.setSelectionRange(start, end);
										onChange({ ...value, partitaIva: e.target.value.toUpperCase() });
									}}
									value={value?.partitaIva}
									tipo="text"
									placeholder="Inserire la partita IVA"
									descrizione=""
									errore={error?.partitaIva?.message}
								/>
								<TextField
									label="Denominazione"
									variant="standard"
									required={!edit && obbligatorio}
									InputProps={{ readOnly: readonly }}
									onChange={(e) => onChange({ ...value, denominazione: e.target.value })}
									value={value?.denominazione ?? ""}
									tipo="text"
									placeholder="Inserire la denominazione della persona giuridica"
									descrizione=""
									errore={error?.denominazione?.message}
								/>
							</Grid>
						)
					}
				</div>
			</>
		);
	},
	CampoMarcaBollo: ({ value, onChange, label, obbligatorio, visible, nascostoSpontaneo, suggerimento, placeholder, error }) => {
		const [provincia, setProvincia] = useState("");
		const province = useProvince();
		const inputStringa = useRef(null);

		const changeFile = useMemo(() => (ev) => {
			const fr = new FileReader();
			fr.onload = () => {
				const result = new Uint8Array(fr.result);
				window.crypto.subtle.digest('SHA-256', result).then((digested) => {
					const digestedRes = new Uint8Array(digested);
					const hex = buf2hex(digestedRes);
					onChange({ ...value, hash: hex });
				});
			};

			fr.readAsArrayBuffer(ev.target.files[0]);
		}, [value]);

		return (
			<div ref={inputStringa} className={`col-12 col-md-9 pt-3 ${(!visible || nascostoSpontaneo) ? "d-none" : ""}`}>
				{label && (
					<h5>
						{label}
						{obbligatorio && ' *'}
					</h5>
				)}
				<div className="d-flex flex-row justify-content-center w-100" style={{ columnGap: "30px", alignItems: "baseline" }}>
					<div style={{ width: "220px" }}>
						<Select
							titolo=""
							className="w-100"
							obbligatorio={obbligatorio}
							valoreSelezionato={value?.provResidenza ?? ""}
							opzioneVuotaPresente
							testoOpzioneVuota={placeholder}
							nome="provResidenza"
							onChange={(v) => onChange({ ...value, provResidenza: v })}
							placeholder={placeholder}
							elementiNascosti={[]}
						>
							<MenuItem>Seleziona provincia.</MenuItem>
							{
								province.map((v) => <MenuItem key={v} value={v}>{v}</MenuItem>)
							}
						</Select>
					</div>
					<input type="file" className="mr-auto" onChange={changeFile} />
				</div>
				Hash:
				{` ${(value?.hash ?? "Selezionare un file.")}`}
				{
					(error?.message || suggerimento) && (
						<p style={error?.message && { color: "red" }}>
							<i>{error?.message ?? suggerimento}</i>
						</p>
					)
				}
			</div>
		);
	},
	CampoData: ({
		value, onChange, label, readonly, obbligatorio,
		visible, suggerimento, placeholder, error, edit
	}) => {
		const style = {
			width: "100%",
			borderRadius: 1,
			border: "1px solid #FFFFFF !important"
		};
		return (
			<div className={`w-100 mt-4 ${!visible ? "d-none" : ""}`}>
				<label htmlFor="dateStandard" className="w-100 input-con-descrizione no-bot-margin active">
					{label && (
						<h5>
							{label}
							{obbligatorio && ' *'}
						</h5>
					)}
					<LocalizationProvider dateAdapter={DateAdapter}>
						<DesktopDatePicker
							id="datePicker"
							readOnly={readonly}
							onChange={(v) => onChange(v.format("DD-MM-YYYY"))}
							value={value ? moment(value, "DD/MM/YYYY") : null}
							inputFormat="DD/MM/YYYY"
							placeholder={placeholder}
							renderInput={(props) => <TextField id="datePickerText" {...props} style={style} />}
						/>
					</LocalizationProvider>
					{
						(error?.message || suggerimento) && (
							<p style={error?.message && { color: "red" }}>
								<i>{error?.message ?? suggerimento}</i>
							</p>
						)
					}
				</label>
			</div>
		);
	}
};
