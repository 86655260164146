import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

export default function ConnettoreLogico({ value, onChange, meta, campi, currentField }) {
	const conditionName1 = value.condizione1["@class"].split(".").pop();
	const conditionName2 = value.condizione2["@class"].split(".").pop();
	return (
		<>
			<div className="d-inline-flex ml-5">
				<FormControl variant="standard" style={{ width: "200px" }}>
					<InputLabel>Tipo condizione 1</InputLabel>
					<Select variant="standard" value={conditionName1} onChange={(v) => onChange({ ...value, condizione1: meta.condizioni[v.target.value].dv })}>
						{
							Object.values(meta.condizioni).map((v) => (
								<MenuItem value={v.value} key={v.value}>{v.label}</MenuItem>
							))
						}
					</Select>
				</FormControl>
				<FormControl variant="standard" className="ml-2" style={{ width: "80px" }}>
					<InputLabel>Connettore</InputLabel>
					<Select variant="standard" value={value.connettore} onChange={(v) => onChange({ ...value, connettore: v.target.value })}>
						<MenuItem value="And">And</MenuItem>
						<MenuItem value="Or">Or</MenuItem>
					</Select>
				</FormControl>
				<FormControl variant="standard" className="ml-2" style={{ width: "200px" }}>
					<InputLabel>Tipo condizione 2</InputLabel>
					<Select variant="standard" value={conditionName2} onChange={(v) => onChange({ ...value, condizione2: meta.condizioni[v.target.value].dv })}>
						{
							Object.values(meta.condizioni).map((v) => (
								<MenuItem value={v.value} key={v.value}>{v.label}</MenuItem>
							))
						}
					</Select>
				</FormControl>
			</div>
			<div className="w-100">
				<div style={{ width: "200px", display: "inline-block" }}> </div>
				{
					React.createElement(meta.condizioni[conditionName1].component, {
						value: value.condizione1,
						meta,
						campi,
						currentField,
						onChange: (curr) => onChange({ ...value, condizione1: curr })
					})
				}
				<div style={{ width: "200px", display: "inline-block" }}> </div>
				{
					React.createElement(meta.condizioni[conditionName2].component, {
						value: value.condizione2,
						meta,
						campi,
						currentField,
						onChange: (curr) => onChange({ ...value, condizione2: curr })
					})
				}
			</div>
		</>
	);
}
