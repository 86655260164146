import axios from "axios";
import { ENTE, STRUTTURA, TRIBUTO } from "./constants";
// import { entiByTributoResponse, tributiByEnteResponse } from "./mock";

export const keyEnte = (ente) => `${ente.codiceEnte}`;
export const keyTributo = (tributo) => `${tributo.codiceTributo}@${tributo.codiceEnte}`;
export const keyStruttura = (struttura) => `${struttura.cdrEnte}`;
export const typedKeyEnte = (ente) => `${ENTE}-${keyEnte(ente)}`;
export const typedKeyTributo = (tributo) => `${TRIBUTO}-${keyTributo(tributo)}`;
export const typedKeyStruttura = (struttura) => `${STRUTTURA}-${keyStruttura(struttura)}`;

export const preparaListaEnti = (data) => {
	const listaEnti = [];
	data.map((ente) => listaEnti.push({
		id: typedKeyEnte(ente),
		code: ente.codiceEnte,
		value: ente.intestatario.denominazione,
		type: ENTE
	}));
	return listaEnti;
};

export const preparaListaStrutture = (data, livello) => {
	// eslint-disable-next-line no-param-reassign
	if (!livello) livello = 0;
	const listaStrutture = [];
	data.forEach((struttura) => {
		listaStrutture.push({
			id: typedKeyStruttura(struttura),
			code: struttura.cdr,
			value: struttura.nome,
			type: STRUTTURA
		});
		if (struttura.figli.length > 0) {
			const figli = preparaListaStrutture(struttura.figli, livello + 1);
			if (figli.length > 0) {
				figli.forEach((f) => listaStrutture.push(f));
			}
		}
	});
	return listaStrutture;
};

export const preparaListaTributi = (data) => {
	const listaTributi = [];
	data.map((tributo) => listaTributi.push({
		id: typedKeyTributo(tributo),
		code: tributo.codiceTributo,
		value: tributo.denominazione,
		strutture: preparaListaStrutture(tributo.struttureAmmesse),
		type: TRIBUTO
	}));
	return listaTributi;
};

export const fetchEntiByTributo = (idTributo) => {
	const axiosParams = {
		method: "POST",
		url: `/api/ente/query-public/`,
		headers: { "Content-Type": "application/json" },
		data: {
			tributo: { codiceTributo: idTributo },
			filtraNoTributi: true,
			page: 0,
			pageSize: 40,
			orderBy: [{ field: "intestatario.denominazione", desc: false }]
		}
	};
	return new Promise((resolve, reject) => {
		axios(axiosParams)
			.then((response) => resolve(preparaListaEnti(response.data.data)))
			.catch((e) => {
				console.error(e);
				resolve(preparaListaEnti([]));
			});
	});
};

export const fetchTributiByEnte = (idEnte) => {
	const axiosParams = {
		method: "POST",
		url: `/api/tributo/query-public/`,
		headers: { "Content-Type": "application/json" },
		data: {
			page: 0,
			pageSize: 40,
			ente: {
				codiceEnte: idEnte
			},
			query: '',
			orderBy: [{ field: "denominazione", desc: false }],
			queryParameters: [
				"codiceTributo",
				"denominazione"
			]
		}
	};
	return new Promise((resolve, reject) => {
		axios(axiosParams)
			.then((response) => resolve(preparaListaTributi(response.data.data)))
			.catch((e) => {
				console.error(e);
				resolve(preparaListaTributi([]));
			});
	});
};

export const fetchTributiByStruttura = (cdrEnte) => {
	const [cdr, ente] = cdrEnte.split("///");
	const axiosParams = {
		method: "POST",
		url: `/api/tributo/query-public/`,
		headers: { "Content-Type": "application/json" },
		data: {
			page: 0,
			pageSize: 40,
			struttura: {
				cdr,
				ente: { codiceEnte: ente }
			},
			query: '',
			orderBy: [{ field: "nome", desc: false }],
			queryParameters: [
				"codiceTributo",
				"denominazione"
			]
		}
	};
	return new Promise((resolve, reject) => {
		axios(axiosParams)
			.then((response) => resolve(preparaListaTributi(response.data.data)))
			.catch((e) => {
				console.error(e);
				resolve(preparaListaTributi([]));
			});
	});
};

export const fetchStruttureByTributo = (ente, tributo) => {
	const axiosParams = {
		method: "POST",
		url: `/api/struttura/query-public/`,
		headers: { "Content-Type": "application/json" },
		data: {
			page: 0,
			pageSize: 40,
			ente: {
				codiceEnte: ente
			},
			tributo: { codiceTributo: tributo },
			query: '',
			queryParameters: [
				"descrizione"
			],
			senzaTributi: false
		}
	};
	return new Promise((resolve, reject) => {
		axios(axiosParams)
			.then((response) => resolve(preparaListaStrutture(response.data.data)))
			.catch((e) => {
				console.error(e);
				resolve(preparaListaStrutture([]));
			});
	});
};


export const fetchStruttureByEnte = (idEnte) => {
	const axiosParams = {
		method: "POST",
		url: `/api/struttura/query-public/`,
		headers: { "Content-Type": "application/json" },
		data: {
			page: 0,
			pageSize: 40,
			ente: {
				codiceEnte: idEnte
			},
			query: '',
			queryParameters: [
				"descrizione"
			],
			senzaTributi: false
		}
	};
	return new Promise((resolve, reject) => {
		axios(axiosParams)
			.then((response) => resolve(preparaListaStrutture(response.data.data)))
			.catch((e) => {
				console.error(e);
				resolve(preparaListaStrutture([]));
			});
	});
};
