import ReactDOM from 'react-dom';
import { PersistGate } from "redux-persist/integration/react";
import './index.css';

import { Provider } from 'react-redux';
import Store, { persistor } from './Store/Store';

import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';

ReactDOM.render(
	<Provider store={Store}>
		<PersistGate loading={null} persistor={persistor}>
			<App />
		</PersistGate>
	</Provider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
