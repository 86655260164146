/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';

import { FormProvider, useForm, Controller, useWatch } from "react-hook-form";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment as DateAdapter } from "@mui/x-date-pickers/AdapterMoment";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary, Button,
	TextField,
	Typography,
	Alert, Grid
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import NumberFormat from "react-number-format";
import EnteSelect from "../../Components/Forms/EnteSelect";
import TributoSelect from "../../Components/Forms/TributoSelect";
import StrutturaSelect from "../../Components/Forms/StrutturaSelect";
import PluginManager from "../../../Plugins/PluginManager";
import configuration from "../../../configuration";
import handleErrors from "../../Utils/HandleErrors";
import useToken from "../../../Hooks/useToken";
import ModalMail from "../../Components/ModalMail";
import ConfirmModal from "../../Components/ConfirmModal";
import { AnagraficaDebitore, RateForm } from "../../Components/Forms/UtilitiesDebito";
import { removeEmptyObjects } from "../../../Utils/Object";
import BackofficeInputMap from "../../../Plugins/Campi/Interfaces/BackofficeInputMap";
import { defaultPaymentFormValidator, vCdr, vPrivacy } from "../../../DataModels/DebitoSpontaneo";
import { useFetch } from "../../../Hooks/useFetch";


function FormSpontaneo({ plugins, onChange, strutture }) {
	const { t } = useTranslation('translation');

	const hasPluginCampi = plugins.filter((plugin) => plugin.configuration.codicePlugin === "CAMPI").length > 0;
	const hasStrutture = strutture?.length > 0 ?? false;

	const baseValidator = {
		privacy: vPrivacy,
		...(hasPluginCampi ? {} : defaultPaymentFormValidator.fields),
		...(hasStrutture ? { cdr: vCdr } : {})
	};


	// - Prepara il validatore per il form tenendo conto dei plugin
	const formValidator = useMemo(() => plugins.reduce((validator, plugin) => yup.object({
		...validator.fields, ...plugin.backofficePaymentValidation(t).fields
	}), yup.object(baseValidator)), []);

	const privateForm = useForm({
		resolver: yupResolver(formValidator),
		defaultValues: formValidator.getDefault()
	});

	const { watch, register } = privateForm;

	useEffect(() => { onChange(watch()); }, [watch()]);

	return (
		<FormProvider {...privateForm}>
			<div className="mt-3">
				{hasStrutture
					&& (
						<Controller
							name="cdr"
							render={({ field: { onChange, value } }) =>
								<StrutturaSelect onChange={onChange} required={(strutture?.length ?? 0) > 0} value={value} strutture={strutture ?? []} />}
						/>
					)
				}
				{!hasStrutture
					&& (
						<Controller name="cdr" render={({ field: { onChange, value } }) => <TextField onChange={onChange} variant="standard" label="CDR" value={value} className="w-100" />} />
					)
				}
			</div>
			{
				!hasPluginCampi && (
					<form>
						<Controller
							name="importo"
							defaultValue={null}
							required
							render={({ field: { onChange: change, v } }) => (
								<NumberFormat
									onValueChange={({ value }) => change(value)}
									value={v}
									required
									id="filled-adornment-amount"
									className="mt-3 w-50"
									label="Importo"
									variant="standard"
									defaultValue={null}
									customInput={TextField}
									prefix="€ "
									decimalScale={2}
									decimalSeparator=","
									thousandSeparator="."
								/>
							)}
						/>
						<TextField {...register("causale")} variant="standard" label="Causale del versamento" required className="w-100" />
						<Controller
							name="debitore"
							render={({ field: { onChange, value } }) => (
								<BackofficeInputMap.CampoCFIVA onChange={onChange} value={value} tipo="Entrambi" visible />
							)}
						/>
						<TextField {...register("comune")} variant="standard" label="Comune di residenza" required className="w-100" />
						<TextField {...register("indirizzo")} variant="standard" label="Indirizzo di residenza" required className="w-100" />
						<TextField {...register('noteVersante')} label="Note del debito" multiline rows={7} className="w-100 mt-4" />
					</form>
				)
			}
			{plugins.map((plugin, i) => <plugin.backofficePaymentForm key={plugin.configuration.codicePlugin} config={plugin.configuration} />)}
		</FormProvider>
	);
}

const schemaValidation = yup.object().shape({
	//Validazione campo per non consentire caratteri speciali
	codiceDebito: yup.string().matches(/^[a-zA-Z0-9_\s]*$/, "Solo lettere, numeri e underscore sono consentiti")
});

export default function CaricamentoForm({ account }) {
	// CALL STATE
	const [submitState, setSubmitState] = useState('idle');
	// CALL RESULTS
	const [result, setResult] = useState(null);
	// PLUGINS STATES
	const [pluginExpanded, setPluginExpanded] = useState(false);
	// LOADING STATES
	const [copyingIuv, setCopyingIuv] = useState(false);
	const [copyingId, setCopyingId] = useState(false);
	const [downloading, setDownloading] = useState(false);
	const [sendingEmail, setSendingEmail] = useState(false);
	// MODAL STATES
	const [renameAvvisiModal, setRenameAvvisiModal] = useState(false);
	const [noMailModal, setNoMailModal] = useState(false);
	const [emailSentModal, setEmailSentModal] = useState(false);
	const [emailFailModal, setEmailFailModal] = useState(false);

	const [forceComplexForm, setForceComplexForm] = useState(false);

	const jwt = useToken();

	const paymentForm = useForm({
		resolver: yupResolver(schemaValidation)
	});

	// USE WATCH
	const ente = account.ente?.codiceEnte ?? useWatch({ control: paymentForm.control, name: "codiceEnte" });
	const tirbutoCod = useWatch({ control: paymentForm.control, name: "tributo" });
	const { data: tributo } = useFetch(tirbutoCod?.codiceTributo && `/tributo/${ente}/${tirbutoCod.codiceTributo}/all`, null, 'GET');
	const strutture = tributo?.struttureAmmesse ?? [];
	const causale = useWatch({ control: paymentForm.control, name: "causale", defaultValue: [] });

	// - Recupero la configurazione del plugin e genero il plugin
	const pluginManager = new PluginManager();
	const plugins = tributo?.plugins?.map((conf) => pluginManager.getPlugin(conf.codicePlugin, conf));

	const filteredStrutture = useMemo(() => {
		if (account?.strutture && Object.keys(account.strutture).length > 0) {
			const struttureOperatore = Object.keys(account.strutture).map((x) => x.replaceAll("-", "."));
			return strutture?.filter((x) => struttureOperatore.includes(x.cdr));
		}
		return strutture;
	}, [account.strutture, strutture]);

	const submitForm = async (data) => {
		paymentForm.clearErrors();
		setResult(null);

		const pluginCampi = data.pluginData?.find((plugin) => plugin.codicePlugin === "CAMPI");
		if (pluginCampi) {
			pluginCampi.valueMap = Object.keys(pluginCampi.valueMap).reduce((fData, field) => {
				fData[field] = typeof pluginCampi.valueMap[field] === 'object' ? JSON.stringify(pluginCampi.valueMap[field]) : pluginCampi.valueMap[field];
				return fData;
			}, {});
		}

		const modData = {
			...data,
			codiceTributo: data.tributo.codiceTributo
		};

		const codiceEnte = modData.codiceEnte ?? null;
		const selectedEnte = codiceEnte ? { Ente: codiceEnte } : {};

		const response = await fetch(`${configuration.serverAddress}/debiti/new`, {
			method: 'POST',
			body: JSON.stringify(removeEmptyObjects(modData)),
			headers: new Headers({
				Authorization: `Bearer ${jwt}`,
				...selectedEnte,
				...configuration.defaultHeaders
			})
		});

		const responseData = await response.json();

		if (response.status >= 400) {
			if (responseData.dettaglio !== undefined) {
				responseData.dettaglio.forEach((errore) => paymentForm.setError(errore.field, {
					description: errore.description }));
			} else handleErrors(responseData, paymentForm.setError);
			return;
		}
		setResult(responseData);
	};

	const copyToCliboard = (data) => {
		const iuv = data === "iuv";

		navigator.clipboard.writeText(iuv ? result?.pendenze[0].iuv : result?.codiceDebito).then(() => {
			if (iuv) setCopyingIuv(true);
			else setCopyingId(true);

			setTimeout(() => {
				if (iuv) setCopyingIuv(false);
				else setCopyingId(false);
			}, 2000);
		}, (err) => {
			console.error('Could not copy text: ', err);
		});
	};

	const downloadNotice = async () => {
		setDownloading(true);
		const response = await fetch(`${configuration.serverAddress}/debiti/avviso/caricamento`, {
			method: 'POST',
			body: JSON.stringify([{ codiceEnte: result.ente.codiceEnte, iuv: result?.pendenze?.[0].iuv }]),
			headers: new Headers({ Authorization: `Bearer ${jwt}`, 'Content-Type': 'application/json' })
		});

		const [_, filename] = response.headers.get('content-disposition').split('filename=');

		const blob = await response.blob();

		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		a.remove();
		setDownloading(false);
	};

	const checkEmail = async () => {
		const body = {
			flagAllSelected: false,
			selected: [{
				codiceEnte: result.ente.codiceEnte,
				codiceTributo: result.tributo.codiceTributo,
				codiceDebito: result.codiceDebito
			}],
			query: {
				page: undefined,
				pageSize: undefined
			}
		};

		const response = await fetch(`${configuration.serverAddress}/debiti/avviso/checkEmails`, {
			method: 'POST',
			body: JSON.stringify(body),
			headers: new Headers({ Authorization: `Bearer ${jwt}`, 'Content-Type': 'application/json' })
		});

		response.json()
			.then((res) => {
				if (res.cfList.length > 0) setNoMailModal(true);
				else setRenameAvvisiModal(true);
			});
	};

	const sendEmail = async (emailData) => {
		const form = new FormData();
		if (emailData.allegati !== undefined) {
			for (let i = 0; i < emailData.allegati.length; i++) {
				form.append(`file-${i}`, emailData.allegati[i]);
			}
		}

		await fetch(`${configuration.serverAddress}/debiti/avviso/createAllegati`, {
			method: 'POST',
			body: form,
			headers: new Headers({ Authorization: `Bearer ${jwt}` })
		}).then(async (ris) => {
			if (ris.status >= 400) {
				throw await ris.json();
			}
			ris.json().then(async (resp) => {
				const body = {
					idDebiti: [{
						codiceEnte: result.ente.codiceEnte,
						codiceTributo: result.tributo.codiceTributo,
						codiceDebito: result.codiceDebito
					}],
					emailDest: (emailData.emailDest !== "" && emailData.emailDest !== undefined) ? emailData.emailDest : null,
					oggetto: emailData.mailObject,
					contenuto: emailData.mailBody,
					filename: emailData.fileName,
					allegati: resp.idAllegati,
					operatore: { id: account.id },
					flagAllSelected: false,
					query: {
						page: undefined,
						pageSize: undefined
					}
				};
				setSendingEmail(true);

				await fetch(`${configuration.serverAddress}/debiti/avviso/generateEmails`, {
					method: 'POST',
					body: JSON.stringify(body),
					headers: new Headers({ Authorization: `Bearer ${jwt}`, 'Content-Type': 'application/json' })
				}).then(async (risp) => {
					setSendingEmail(false);
					setRenameAvvisiModal(false);
					if (risp.status !== 200) {
						setEmailFailModal(true);
						throw await risp.json();
					} else {
						setEmailSentModal(true);
						return risp.json();
					}
				});
			});
		});
	};

	const visitLeaf = (obj, oldKey) => Object.keys(obj)
		.filter((k) => k !== "ref" && k !== "title")
		.map((key) => ((obj[key] != null && typeof obj[key] === 'object' && obj[key].constructor === Object) // eslint-disable-line
			? visitLeaf(obj[key], key)
			: Array.isArray(obj[key])
				? obj[key].map((v, i) => visitLeaf(v, i))
				: { key: oldKey, value: obj[key] }));

	useEffect(() => {
		paymentForm.setValue('pendenze.0.causale', causale);
	}, [causale]);

	const formSpontaneoChange = useCallback((formValues) => {
		if (!formValues?.importo) return;

		const { formValues: data, pluginData } = plugins?.reduce((acc, plugin) => plugin.backofficePaymentSubmit(acc), { formValues, pluginData: [] });

		const generateDebitore = (debitore) => {
			let debitoreInfo = {};
			if (debitore.denominazione) {
				debitoreInfo = {
					ragioneSociale: debitore.denominazione,
					partitaIva: debitore.partitaIva,
					codiceFiscale: debitore.codiceFiscale,
					personaFisica: false
				};
			} else {
				if (debitore.cfIva && debitore.straniero) {
					debitoreInfo = {
						codiceFiscale: "STRANIERO",
						zipCode: debitore.cfIva
					};
				} else if (debitore.cfIva) {
					if (debitore.cfIva.length === 11) debitoreInfo = { partitaIva: debitore.cfIva };
					else debitoreInfo = { codiceFiscale: debitore.cfIva };
				}

				if (debitore.codiceFiscale) debitoreInfo.codiceFiscale = debitore.codiceFiscale;
				if (debitore.partitaIva) debitoreInfo.partitaIva = debitore.partitaIva;
				debitoreInfo.nome = debitore.nome;
				debitoreInfo.cognome = debitore.cognome;
				debitoreInfo.personaFisica = true;
			}

			if (debitore.comune) debitoreInfo.comune = debitore.comune;
			if (debitore.indirizzo) debitoreInfo.indirizzo = debitore.indirizzo;
			if (debitore.email) debitoreInfo.email = debitore.email;

			return debitoreInfo;
		};

		const hasObbligatoInSolido = (deb) => deb.cfIva || deb.partitaIva || deb.codiceFiscale;


		const debit = {
			causale: data.causale,
			noteVersante: data.noteVersante,
			annoRiferimento: moment().year(),
			anagraficaDebitore: generateDebitore(data?.debitore),
			debitoriAlternativi: data?.obbligatoInSolido && hasObbligatoInSolido(data?.obbligatoInSolido) ? [generateDebitore(data?.obbligatoInSolido)] : [],
			cdr: data.cdr,
			pluginData,
			pendenze: [
				{
					numeroRata: 0,
					altRata: 0,
					causale: data.causale,
					inizioValidita: moment().format("yyyy-MM-DD"),
					fineValidita: moment().add(99, "year").format("yyyy-MM-DD"),
					scadenza: moment().add(1, "year").format("yyyy-MM-DD"),
					dataInsorgenza: moment().format("yyyy-MM-DD"),
					"@class": "it.ras.pagopa.shared.dto.pendenze.PendenzaNewDto",
					dettagli: [
						{
							tipo: "Capitale",
							codiceDettaglio: "Capitale",
							stato: "Attiva",
							descrizione: "Capitale automatico",
							importo: Number.parseFloat(data.importo),
							capitoloEntrata: data.capitoloEntrata,
							"@class": "it.ras.pagopa.shared.dto.pendenze.DettaglioNewDto"
						}
					]
				}
			]
		};

		const processedDebit = plugins?.reduce((acc, plugin) => plugin.backofficePaymentEditorSubmit(data, acc), debit);
		Object.keys(processedDebit).forEach((k) => paymentForm.setValue(k, processedDebit[k]));
	}, [tributo]);

	return (
		<>
			<ModalMail
				open={renameAvvisiModal}
				setOpen={setRenameAvvisiModal}
				onSubmit={sendEmail}
				onDeny={() => {}}
				loading={sendingEmail}
				addEmail
			/>
			<ConfirmModal
				open={noMailModal}
				title="Email non trovata"
				text="Il debitore non ha fornito un indirizzo e-mail per l'invio del documento. Desideri proseguire e inserirlo manualmente?"
				onConfirm={() => {
					setNoMailModal(false);
					setRenameAvvisiModal(true);
				}}
				onDeny={() => setNoMailModal(false)}
				confirmText="Prosegui"
				width="600px"
			/>
			<ConfirmModal
				open={emailSentModal}
				title="Generazione Email Avviso"
				text={(
					<>
						L&apos;Email è stata generata correttamente.
						<br />
						Visita la sezione Documenti e Comunicazioni -&gt; Registro email per verificare lo stato di invio.
					</>
				)}
				onConfirm={() => setEmailSentModal(false)}
				confirmText="OK"
				width={600}
			/>
			<ConfirmModal
				open={emailFailModal}
				title="Generazione Email Avviso"
				text={(
					<>
						La generazione non è andata a buon fine.
						<br />
						Se il problema persiste si prega di contattare l&apos;assistenza.
					</>
				)}
				onConfirm={() => setEmailFailModal(false)}
				confirmText="OK"
				width={600}
			/>
			<form onSubmit={paymentForm.handleSubmit(submitForm)}>
				<FormProvider {...paymentForm}>
					<Grid className="d-flex flex-row rounded align-items-center" padding="0px 20px 10px 20px" marginTop={3} backgroundColor="#E8EDFC" columnGap="30px" minWidth="570px" maxWidth="1200px">
						{
							account.ente?.codiceEnte
								? ""
								: <Controller name="codiceEnte" render={({ field: { onChange, value } }) => <EnteSelect onChange={onChange} value={value} required />} />
						}
						<Controller name="tributo" render={({ field: { onChange, value } }) => <TributoSelect onChange={onChange} value={value} ente={ente} ricerca={false} required />} />
						{tributo?.formRidotto && forceComplexForm && <Button onClick={() => setForceComplexForm(false)} style={{ width: "560px", height: "45px", marginTop: "10px" }}>Modalità semplificata</Button>}
					</Grid>
					{
						(tributo?.formRidotto || tributo?.spontaneo) && !forceComplexForm && (
							<Grid maxWidth="1200px"><FormSpontaneo plugins={plugins} onChange={formSpontaneoChange} strutture={filteredStrutture} /></Grid>
						)
					}
					{
						(!(tributo?.formRidotto || tributo?.spontaneo) || forceComplexForm) && (
							<>
								<div className="d-flex flex-row mt-4">
									<div style={{ width: "570px" }} className="d-flex flex-column">
										<div>
											{
												(filteredStrutture?.length ?? 0) > 0 && (
													<Controller name="cdr" render={({ field: { onChange, value } }) => <StrutturaSelect onChange={onChange} required={(filteredStrutture?.length ?? 0) > 0} value={value} strutture={filteredStrutture} />} />
												)
											}
											{(filteredStrutture?.length ?? 0) <= 0
												&& (
													<Controller name="cdr" render={({ field: { onChange, value } }) => <TextField onChange={onChange} variant="standard" label="CDR" value={value} className="w-100" />} />
												)
											}
											<Controller control={paymentForm.control} name="codiceDebito" render={({ field }) => (
												<TextField {...field} label="ID debito" onChange={(e) => { field.onChange(e); }} inputProps={{ maxLength: 50 }} variant="standard" error={paymentForm.formState.errors?.codiceDebito?.message}
													helperText={(
														<span style={{ color: 'red' }}>
															{paymentForm.formState.errors?.codiceDebito?.message}
														</span>
													)} className="mt-2 w-100" />
											)} />

											<AnagraficaDebitore enableMultidebitore />
										</div>
									</div>
									<div style={{ width: "570px" }} className="d-flex flex-column ml-5">
										<TextField {...paymentForm.register('causale')} required label="Causale" inputProps={{ maxLength: 256 }} variant="standard" className="mb-3 mt-2 w-100" />
										<TextField {...paymentForm.register('annoRiferimento')} required label="Anno di riferimento" type="number" inputProps={{ step: 1, min: 0, max: 9999 }} variant="standard" className="mt-2 mb-3 w-100" />
										<LocalizationProvider dateAdapter={DateAdapter}>
											<Controller
												name="dataInsorgenza"
												render={({ field: { onChange, value } }) => (
													<DesktopDatePicker
														label="Data di insorgenza"
														inputFormat="DD/MM/yyyy"
														value={value && moment(value, "yyyy-MM-DD")}
														onChange={(v) => onChange(v?.format("yyyy-MM-DD"))}
														renderInput={(params) => <TextField className="w-100 mt-3" {...params} />}
													/>
												)}
											/>
										</LocalizationProvider>
										<TextField {...paymentForm.register('noteVersante')} label="Note del debito" multiline className="w-100 mt-4" />
									</div>
								</div>
								<div className="mr-4" style={{ width: "1240px" }}>
									<RateForm initialize />
								</div>
								<div className="mr-4" style={{ width: "1240px" }}>
									{
										plugins?.length > 0 && (
											<div className="mt-4">
												<h3>Plugin</h3>
												<hr />
												{
													plugins?.map((plugin, i) => (
														<Accordion
															key={`${i + 1}`}
															expanded={pluginExpanded === i}
															onChange={(event, isExpanded) => setPluginExpanded(isExpanded ? i : false)}
														>
															<AccordionSummary>
																<Typography sx={{ width: '33%', flexShrink: 0, lineHeight: "28px" }}>{plugin.configuration.nome}</Typography>
																<Typography sx={{ color: 'text.secondary', lineHeight: "28px" }}>{plugin.configuration.descrizione}</Typography>
															</AccordionSummary>
															<AccordionDetails>
																<plugin.backofficePaymentFormEditor key={plugin.configuration.codicePlugin} config={plugin.configuration} basePath={`pluginData.${i}`} />
															</AccordionDetails>
														</Accordion>
													))
												}
											</div>
										)
									}
								</div>
							</>
						)
					}
					<div style={{ width: "600px" }} className="d-flex flex-column mb-5">
						<Grid container direction="row" columnGap={3}>
							<Button type="submit" variant="contained" disabled={paymentForm.formState.isSubmitting} onClick={() => paymentForm.clearErrors()} className="mt-5 w-50 float-right">CARICA DEBITO</Button>
							{ !forceComplexForm && (tributo?.formRidotto || tributo?.spontaneo) && <Button type="button" variant="contained" disabled={paymentForm.formState.isSubmitting} onClick={() => setForceComplexForm(true)} className="mt-5 float-right">MODALITÀ ESTESA</Button>}
						</Grid>
						{
							visitLeaf(paymentForm.formState.errors).flat(Infinity).map((v) => (
								<Alert severity="error" className="mt-3 w-100">
									{`${v.key}: ${v.value}`}
								</Alert>
							))
						}
						{
							result !== null && (
								<>
									<hr />
									<Alert severity="info" className="mt-3 w-100">
										<b>Pendenza caricata</b>
										<p>
											<b>IUV: </b>
											{result?.pendenze[0].iuv}
										</p>
										<p>
											<b>ID Debito: </b>
											{result.codiceDebito}
										</p>
									</Alert>
									<div className="d-flex flex-row">
										<Button onClick={() => copyToCliboard("iuv")} variant="contained" className="mt-3 w-20 d-inline">{copyingIuv ? "Copiato" : "Copia IUV"}</Button>
										<Button onClick={() => copyToCliboard("id")} variant="contained" className="mt-3 ml-3 w-20 d-inline">{copyingId ? "Copiato" : "Copia ID"}</Button>
										<Button onClick={downloadNotice} variant="contained" className="mt-3 ml-3 w-30 d-inline">{downloading ? "In corso" : "Scarica avviso"}</Button>
										<Button onClick={checkEmail} variant="contained" className="mt-3 ml-3 w-25 d-inline" style={{ flexGrow: "1" }}>Invia Avviso</Button>
									</div>
								</>
							)
						}
					</div>
				</FormProvider>
			</form>
		</>
	);
}
