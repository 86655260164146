import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useComuni, useProvince } from "../../../Utils/Belfiore";

export default function CampoBelfiore({ value, onChange, campo }) {
	const [provincia, setProvincia] = useState("");
	const province = useProvince();
	const comuni = useComuni({ provincia });
	const [comune] = useComuni({ belfioreFilter: value });
	useEffect(() => { setProvincia(comune?.provincia); }, [comune]);
	return (
		<div className="d-flex" style={{ columnGap: "10px", height: "47px", width: "500px" }}>
			<FormControl variant="standard" className="w-25">
				<InputLabel id={`select-provincia-${campo.name}`}>Provincia</InputLabel>
				<Select id={`select-provincia-${campo.name}`} variant="standard" label={campo.label} placeholder={campo.label} value={provincia} onChange={(v) => setProvincia(v.target.value)}>
					<MenuItem value="">Nessuno</MenuItem>
					{province.map((v) => <MenuItem value={v} key={v}>{v}</MenuItem>)}
				</Select>
			</FormControl>
			<FormControl variant="standard" className="w-75">
				<InputLabel id={`select-comune-${campo.name}`}>Comune</InputLabel>
				<Select id={`select-comune-${campo.nome}`} label={campo.label} placeholder={campo.label} value={value} onChange={(v) => onChange(v.target.value)}>
					<MenuItem value="">Nessuno</MenuItem>
					{comuni.map((v) => <MenuItem value={v.belfiore} key={v.belfiore}>{v.comune}</MenuItem>)}
				</Select>
			</FormControl>
		</div>
	);
}
