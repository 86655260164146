/* eslint-disable react/self-closing-comp */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import useInterval from 'use-interval';
import useTimeout from 'use-timeout';
import axios from "axios";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import Breadcrumbs from "../Components/Common/Breadcrumbs";
import TitleBar from "../Components/Common/TitleBar";
import configuration from "../../configuration";
import DataViewer from "../Components/Common/DataViewer";
import Feedback from "../Components/Common/Feedback";

export default function EsitoPagamento() {
	const [data, setData] = useState(null);
	const [showFeedback, setShowFeedback] = useState(false);
	const [refreshRicevuta, setRefreshRicevuta] = useState([]);
	const [timeout, setTimeout] = useState(false);
	const isLaptop = useMediaQuery("(max-width: 991px)");
	const { t } = useTranslation('translation');


	const verificaPagamento = (idCart, iuv, codEnte, stato) => {
		if (stato === "Eseguito") {
			if (iuv !== null) {
				const axiosParams = {
					method: "GET",
					url: `/api/pagamento/checkout/stato?iuv=${iuv}&codEnte=${codEnte}&stato=${stato}`
				};
				const promise = axios(axiosParams);
				return promise.then((response) => response.data);
			}
			if (idCart !== null) {
				const axiosParams = {
					method: "GET",
					url: `/api/pagamento/checkout/stato?idCart=${idCart}&stato=${stato}`
				};
				const promise = axios(axiosParams);
				return promise.then((response) => response.data);
			}
			const axiosParams = {
				method: "GET",
				url: `/api/pagamento/checkout/stato?iuv=&codEnte=&stato=${stato}`
			};
			const promise = axios(axiosParams);
			return promise.then((response) => response.data);
		}
		return null;
	};

	const verificaFeedback = (cfIva) => {
		const axiosParams = {
			method: "GET",
			url: `/api/feedback/check/${cfIva}`
		};
		const promise = axios(axiosParams);
		return promise.then((response) => response.data.showForm);
	};

	const downloadReceipt = async (pagamento) => {
		const response = await fetch(
			`${configuration.serverAddress}/pagamento/receipt/${pagamento.id}/${pagamento.pendenza.debito.ente.codiceEnte}/${pagamento.pendenza.iuv}`, {
				method: 'GET',
				headers: new Headers({ ...configuration.defaultHeaders })
			}
		);

		const [_, filename] = response.headers.get('content-disposition').split('filename=');
		const blob = await response.blob();
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		a.remove();
		document.getElementById(`receipt${data?.pagamenti.indexOf(pagamento)}`).className = "fa-1x fas fa-check";
	};

	function useQuery() {
		const { search } = useLocation();
		return React.useMemo(() => new URLSearchParams(search), [search]);
	}

	const query = useQuery();
	const iuv = query.get("iuv");
	const codEnte = query.get("codEnte");
	const stato = query.get("stato");
	const idCart = query.get("idCart");

	const EsitoPositivo = () => (
		<div className="alert alert-success" role="alert">
			<h4 className="alert-heading">
				{t('esitoPagamento.operazioneOK')}
			</h4>
			<p>
				{t('esitoPagamento.operazioneSuccesso')}
			</p>
			<p>
				{t('esitoPagamento.invioEmail')}
			</p>
		</div>
	);

	const EsitoNegativo = () => (
		<div className="alert alert-danger" role="alert">
			<h4 className="alert-heading">
				{t('esitoPagamento.operazioneOK')}
			</h4>
			<p>{t('esitoPagamento.operazioneFallita')}</p>
		</div>
	);

	const EsitoInCorso = () => (
		<div className="alert alert-info" role="alert">
			<h4 className="alert-heading">
				{t('esitoPagamento.operazioneInCorso')}
			</h4>
			<p>{t('esitoPagamento.operazioneInCorsoPag')}</p>
		</div>
	);

	async function verificaEsitoPagamento() {
		try {
			setData(await verificaPagamento(idCart, iuv, codEnte, stato));
		} catch (e) {
			setData(undefined);
		}
	}

	async function verificaShowFeedback() {
		try {
			setShowFeedback(
				await verificaFeedback(data?.versante?.codiceFiscale ?? data?.versante?.partitaIva)
			);
		} catch (e) {
			setShowFeedback(false);
		}
	}

	useEffect(() => {
		verificaEsitoPagamento();
		// eslint-disable-next-line
	}, [iuv, codEnte, stato]);

	useEffect(() => {
		if (data?.versante !== undefined) {
			verificaShowFeedback();
		}
	}, [data?.versante?.codiceFiscale, data?.versante?.partitaIva]);

	const breadcrumbs = [{ titolo: 'esito' }];

	const tableHead = [t('esitoPagamento.ente'), t('esitoPagamento.tributo'), t('esitoPagamento.causale'), t('esitoPagamento.codiceIUV'), t('esitoPagamento.importo'), ""];

	const tableData = data?.pagamenti?.length > 0 ? data?.pagamenti.map((d) => (
		{
			ente: [d?.pendenza?.debito?.ente?.intestatario?.denominazione, "normal"],
			tributo: [d?.pendenza?.debito?.tributo?.denominazione, "normal"],
			causale: [d?.pendenza?.causale ?? d?.pendenza?.debito?.causale, "large"],
			iuv: [d?.pendenza?.iuv, "normal"],
			importo: [`${d?.importo} €`, "normal"]
		})) : [];

	const tableButtons = data?.pagamenti?.length > 0 ? data?.pagamenti.map((d, i) => {
		const fetchRicevuta = () => {
			setRefreshRicevuta([...refreshRicevuta, i]);
			setTimeout(false);
		};

		const tooltipAttesa = t('esitoPagamento.toolAttesa');
		const tooltipFallito = t('esitoPagamento.toolFallito');
		return {
			button: {
				text: (
					<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
						<div>
							<i
								id={`receipt${i}`}
								className={(d.stato !== "Eseguito" && refreshRicevuta.some((x) => x === i))
									? "fa-1x fas fa-circle-notch fa-spin"
									: "fa-1x fas fa-fw fa-file-download"}
							/>
						</div>
						<span style={{ width: "8px" }}></span>
						<div>
							{t('esitoPagamento.scaricaRicevuta')}
						</div>
					</div>),
				function: !refreshRicevuta.some((x) => x === i) ? fetchRicevuta : null,
				tooltip: refreshRicevuta.some((x) => x === i) ? tooltipAttesa : (timeout && d.stato !== "Eseguito") ? tooltipFallito : null
			}
		};
	}) : [];

	useEffect(() => {
		for (let i = 0; i < data?.pagamenti?.length; i++) {
			if (data?.pagamenti[i].stato === "Eseguito" && refreshRicevuta.some((x) => x === i)) {
				downloadReceipt(data?.pagamenti[i]);
				setRefreshRicevuta(refreshRicevuta.filter((x) => x !== i));
			}
		}
	}, [refreshRicevuta.length, data?.pagamenti]);

	useTimeout(() => {
		setRefreshRicevuta([]);
		setTimeout(true);
	}, refreshRicevuta.length > 0 ? 120000 : null);

	useInterval(() => {
		if (data?.pagamenti.some((x) => x.stato !== "Eseguito")) {
			verificaEsitoPagamento();
		}
	}, (refreshRicevuta.length > 0 && !timeout) ? 5000 : null);

	useInterval(() => {
		verificaEsitoPagamento();
	}, (!data || data?.ccp === "inProgress" || data?.ccp === undefined) ? 3000 : null);

	const getTributi = (pagamenti) => pagamenti?.map((p) => ({
		codiceEnte: p?.pendenza?.debito?.ente?.codiceEnte,
		codiceTributo: p?.pendenza?.debito?.tributo.codiceTributo
	}));
	return (
		<>
			<Breadcrumbs breadcrumbs={breadcrumbs} />
			<TitleBar titolo="Esito pagamento" />
			<section id="feedback" className="container">
				<div className="row clearfix">
					<div className="col-12">
						{query.get("stato") !== 'Eseguito' && (
							<div style={{ marginBottom: '80px' }}>
								<EsitoNegativo />
							</div>
						)}

						{query.get("stato") === 'Eseguito' && (data && data.ccp !== 'inProgress' ? (
							<div>
								<EsitoPositivo />
								<div className={isLaptop ? "container bg-white pos-deb-container" : "container bg-white"}>
									<DataViewer
										head={tableHead}
										data={tableData}
										buttons={tableButtons}
										tipo="feedback"
									/>
									<table className="table table-bordered transazione" style={{ marginTop: "1rem" }}>
										<thead>
											<tr>
												<th scope="col" className="table-bordered">
													{t('esitoPagamento.codiceTransazione')}
												</th>
												<td className="table-bordered">{ data?.ccp ?? data.pagamenti[0].identificativoRicevuta}</td>
											</tr>
										</thead>
										<tbody>
											<tr>
												<th className="table-bordered">{t('esitoPagamento.dataEsecuzione')}</th>
												<td className="table-bordered">{ moment(data?.tsInserimento).format("DD/MM/yyyy HH:mm:ss") }</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						) : (
							<div style={{ marginBottom: '80px' }}>
								<EsitoInCorso />
							</div>
						))}
					</div>
				</div>
			</section>
			<Feedback
				modal
				show={showFeedback}
				tributi={getTributi(data?.pagamenti)}
			/>
		</>
	);
}
