import { createSelector } from '@reduxjs/toolkit';

const getRicercaStore = (state) => state.ricerca;

export const getListaEnti = createSelector(
	getRicercaStore, (ricerca) => ricerca.listaEnti.data
);
export const fetchListaEntiEnded = createSelector(
	getRicercaStore, (ricerca) => ricerca.listaEnti.fetchEnded
);

export const getListaTributi = createSelector(
	getRicercaStore, (ricerca) => ricerca.listaTributi.data
);
export const fetchListaTributiEnded = createSelector(
	getRicercaStore, (ricerca) => ricerca.listaTributi.fetchEnded
);

export const getListaStrutture = createSelector(
	getRicercaStore, (ricerca) => ricerca.listaStrutture?.data ?? []
);
export const fetchListaStruttureEnded = createSelector(
	getRicercaStore, (ricerca) => ricerca.listaStrutture.fetchEnded
);
