/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	perPage: 45
};

export const paginationSlice = createSlice({
	name: 'pagination',
	initialState,
	reducers: {
		setPerPage: (state, action) => {
			const value = parseInt(action.payload, 10);
			if (value > 0) {
				state.perPage = value;
			}
		}
	}
});

export const { setPerPage } = paginationSlice.actions;

export default paginationSlice.reducer;
