import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import EditableSelectList from "../../../Components/EditableSelectList";

export default function ValidatoreCodiceFiscale({ campi = [], value, onChange }) {
	return (
		<div className="d-flex flex-column">
			<FormControl variant="standard" className="w-100 mt-4">
				<InputLabel>Campo codice fiscale</InputLabel>
				<Select className="w-100" variant="standard" value={value.campoCodiceFiscale ?? null} onChange={(v) => onChange({ ...value, campoCodiceFiscale: v.target.value })}>
					<MenuItem value={null}>Nessuno</MenuItem>
					{campi.filter((campo) => campo["@class"].split(".").pop() === "CampoCFIVA").map((v) => <MenuItem value={v.nome} key={v.nome}>{v.label}</MenuItem>)}
				</Select>
			</FormControl>
			<FormControl variant="standard" className="w-100 mt-4">
				<InputLabel>Campo luogo di nascita</InputLabel>
				<Select className="w-100" variant="standard" value={value.campoLuogoDiNascita ?? null} onChange={(v) => onChange({ ...value, campoLuogoDiNascita: v.target.value })}>
					<MenuItem value={null}>Nessuno</MenuItem>
					{campi.filter((campo) => campo["@class"].split(".").pop() === "CampoBelfiore").map((v) => <MenuItem value={v.nome} key={v.nome}>{v.label}</MenuItem>)}
				</Select>
			</FormControl>
			<FormControl variant="standard" className="w-100 mt-4">
				<InputLabel>Campo data di nascita</InputLabel>
				<Select className="w-100" variant="standard" value={value.campoDataDiNascita ?? null} onChange={(v) => onChange({ ...value, campoDataDiNascita: v.target.value })}>
					<MenuItem value={null}>Nessuno</MenuItem>
					{campi.filter((campo) => campo["@class"].split(".").pop() === "CampoData").map((v) => <MenuItem value={v.nome} key={v.nome}>{v.label}</MenuItem>)}
				</Select>
			</FormControl>
		</div>
	);
}
