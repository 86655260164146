import React from "react";
import { Controller, useForm, FormProvider, useWatch } from "react-hook-form";
import { Box, Modal, TextField, Autocomplete } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import RangePicker from "./Forms/RangePicker";
import EnteSelect from "./Forms/EnteSelect";
import { useFetch } from "../../Hooks/useFetch";

function TributoMultiSelect({ name, tributi }) {
	return (
		<>
			<Controller
				name={name}
				render={({ field: { onChange, value } }) => (
					<Autocomplete
						multiple
						id="tags-standard"
						onChange={(d, v) => onChange(v)}
						value={value === undefined ? [] : value}
						options={tributi?.data?.map((x) => ({ denominazione: x.denominazione, codiceEnte: x.codiceEnte, codiceTributo: x.codiceTributo })) ?? []}
						getOptionLabel={(option) => option.denominazione}
						isOptionEqualToValue={(option, v) => option.codiceTributo === v.codiceTributo}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="standard"
								label="Tipologie di Pagamento"
							/>
						)}
						renderOption={(props, option) => (
							<li {...props} key={option.codiceTributo}>{option.denominazione}</li>
						)}
						noOptionsText="Nessun risultato."
					/>
				)}
			/>
		</>
	);
}

export default function GenerateReportModal({
	open, onDeny, callback, generateStatus, enteOperatore
}) {
	const form = useForm();
	const ente = enteOperatore ?? useWatch({ control: form.control, name: "ente" });

	const { data: tributiData } = useFetch(ente ? `/tributo/${ente}/list` : '/tributo/list', null, 'GET');

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 600,
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 4,
		display: "flex",
		flexDirection: "column",
		gap: "20px"
	};

	return (
		<Modal open={open} onClose={onDeny}>
			<Box sx={style}>
				<h2>Genera Nuovo Report</h2>
				<h6>Seleziona l&apos;intervallo temporale e le Tipologie di Pagamento per l&apos;estrazione dei dati.</h6>
				<FormProvider {...form}>
					<RangePicker
						nameStart="inizioTsGenerazione"
						nameEnd="fineTsGenerazione"
						variant="standard"
						startText="Da"
						endText="a"
					/>
					{!enteOperatore && (
						<Controller
							name="ente"
							render={({ field: { onChange, value } }) => (
								<EnteSelect onChange={onChange} value={value} />
							)}
						/>
					)}
					<TributoMultiSelect tributi={ente ? tributiData : []} name="tributi" />
					<div style={{ display: "flex", flexDirection: "row", textAlign: "center", gap: "20px", justifyContent: "center" }}>
						<LoadingButton variant="contained" onClick={form.handleSubmit(callback)} loading={generateStatus}>Genera</LoadingButton>
						<LoadingButton onClick={onDeny}>Annulla</LoadingButton>
					</div>
				</FormProvider>
			</Box>
		</Modal>
	);
}
