/* eslint-disable no-bitwise */
import { useRowSelection } from "react-data-grid";
import { createContext, useCallback, useContext } from "react";
import { Checkbox } from "@mui/material";

export const SELECT_COLUMN_KEY = 'select-row';

const RowAllSelectContext = createContext({
	allSelected: false,
	otherPageSelected: false,
	selectedCount: 0,
	totalResults: 0,
	pageSelected: 0,
	pagesCount: 0,
	currentPageSelected: 0,
	setAllSelectionModal: () => {},
	setAllDeselectionModal: () => {}
});

export const RowAllSelectProvider = RowAllSelectContext.Provider;

function useAllSelected() {
	return useContext(RowAllSelectContext);
}

function SelectFormatter({ row }) {
	const [isRowSelected, onRowSelectionChange] = useRowSelection();
	const { allSelected } = useAllSelected();

	return (
		<Checkbox
			checked={allSelected ^ isRowSelected}
			onChange={(e) => onRowSelectionChange({ row, checked: allSelected ^ e.target.checked })}
		/>
	);
}

export const DataGridSelectColumn = {
	key: SELECT_COLUMN_KEY,
	name: '',
	width: 54,
	minWidth: 54,
	maxWidth: 54,
	resizable: false,
	draggable: false,
	frozen: true,
	headerRenderer(props) {
		const { allSelected, setAllSelectionModal, setAllDeselectionModal, selectedCount, totalResults, pagesCount, otherPageSelected, currentPageSelected } = useAllSelected();

		const allElementsSelected = (allSelected && selectedCount === 0) || (!allSelected && totalResults === selectedCount);
		const elementsPartiallySelected = (allSelected && selectedCount > 0 && selectedCount < totalResults) || (!allSelected && selectedCount > 0);
		const totalElements = Math.min(20, totalResults);
		const currentPageSelectedCalc = allSelected ? totalElements - currentPageSelected : currentPageSelected;

		const onChangeHeader = useCallback((e) => {
			if (currentPageSelectedCalc < totalElements && setAllSelectionModal && pagesCount > 1 && allSelected) props.onAllRowsSelectionChange(false);
			else if (allElementsSelected && setAllDeselectionModal && otherPageSelected) setAllDeselectionModal(true);
			else if (currentPageSelectedCalc < totalElements && setAllSelectionModal && pagesCount > 1) setAllSelectionModal(true);
			else if (currentPageSelectedCalc < totalElements) props.onAllRowsSelectionChange(true);
			else if (currentPageSelectedCalc === totalElements && setAllDeselectionModal && otherPageSelected) setAllDeselectionModal(true);
			else if (currentPageSelectedCalc === totalElements) props.onAllRowsSelectionChange(false);
		}, [allElementsSelected, elementsPartiallySelected, props.isCellSelected, props.onAllRowsSelectionChange, setAllSelectionModal, setAllDeselectionModal]);

		return (
			<Checkbox
				checked={allElementsSelected}
				indeterminate={elementsPartiallySelected && !allElementsSelected}
				onChange={onChangeHeader}
			/>
		);
	},
	formatter(props) {
		return <SelectFormatter {...props} />;
	}
};
