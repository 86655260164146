import React, { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useProvince } from "../../../Utils/Belfiore";

export default function CampoMarcaBollo({ value, onChange, campo }) {
	const [provincia, setProvincia] = useState("");
	const province = useProvince();
	return (
		<div className="d-flex" style={{ columnGap: "10px", height: "47px", width: "500px" }}>
			<FormControl variant="standard" className="w-25">
				<InputLabel id={`select-provincia-${campo.name}`}>Provincia</InputLabel>
				<Select id={`select-provincia-${campo.name}`} variant="standard" label={campo.label} placeholder={campo.label} value={provincia} onChange={(v) => setProvincia(v.target.value)}>
					<MenuItem value="">Nessuno</MenuItem>
					{province.map((v) => <MenuItem value={v} key={v}>{v}</MenuItem>)}
				</Select>
			</FormControl>
			<FormControl variant="standard" className="w-25">
				<TextField
					type="string"
					variant="standard"
					style={{ minWidth: "300px", flexGrow: 1, flexShrink: 1 }}
					value={value}
					label={campo.label}
					onChange={(v) => onChange(v.target.value)}
				/>
			</FormControl>
		</div>
	);
}
