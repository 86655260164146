/* eslint-disable  */

export function removeEmptyObjectsEditCreateForm(f) {
	if (Array.isArray(f) && f !== null) {
		f = f.map((v) => removeEmptyObjectsEditCreateForm(v));
		f = f.filter((v) => v !== null && v !== undefined);
		if (f.length === 0) return [];
		return f;
	}

	if (typeof f === 'object' && !Array.isArray(f) && f !== null) {
		const newObj = Object.keys(f).reduce((acc, key) => (
			{ ...acc, [key]: removeEmptyObjectsEditCreateForm(f[key]) }), {});

		if (Object.values(newObj).every((e) => e === null)) return null;
		else return newObj;
	}

	if (f === "" || f === undefined) return null;

	return f;
}