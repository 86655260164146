/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useController } from "react-hook-form";
import BasePlugin from "../BasePlugin";

export default class ServizioPSP extends BasePlugin {
	/**
	 * Permette di personalizzare la configurazione iniziale del plugin.
	 */
	emptyConfiguration() {
		return {
			"@class": "it.ras.pagopa.shared.plugins.pagamenti.serviziopsp.ServizioPSPConfiguration",
			nome: "Servizio PSP",
			codicePlugin: "SERVIZIOPSP",
			ordine: this.configuration.index,
			descrizione: "Permette di generare un pagamento spontaneo direttamente dal PSP."
		};
	}

	/**
	 * Renderizza il form di configurazione del plugin.
	 * @returns {JSX.Element}
	 */
	configurationForm({ path }) {
		const { field: idServizio } = useController({ name: `${path}.idServizio` });
		const { field: idSoggettoServizio } = useController({ name: `${path}.idSoggettoServizio` });
		const { field: tipoServizio } = useController({ name: `${path}.tipoServizio` });
		return (
			<div>
				<FormControl variant="standard" className="w-100 mt-3">
					<TextField type="text" value={idServizio.value} onChange={idServizio.onChange} id="idServizio" variant="standard" label="Id Servizio" className="w-75 mt-3 mr-3" />
				</FormControl>
				<FormControl variant="standard" className="w-100 mt-3">
					<TextField type="text" value={idSoggettoServizio.value} onChange={idSoggettoServizio.onChange} id="idSoggettoServizio" variant="standard" label="Id soggetto servizio" className="w-75 mt-3 mr-3" />
				</FormControl>
				<FormControl variant="standard" className="w-100 mt-3">
					<InputLabel id="tipoServizio">Tipo servizio</InputLabel>
					<Select labelId="tipoServizio" value={tipoServizio.value} required onChange={tipoServizio.onChange}>
						<MenuItem value="PAGAMENTOGENERICO">Pagamento generico</MenuItem>
					</Select>
				</FormControl>
			</div>
		);
	}
}
