/* eslint-disable react/jsx-no-target-blank */
import { useTranslation, Trans } from 'react-i18next';

import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import Logo from './Logo';

/* eslint-disable react/jsx-one-expression-per-line */
export default function Footer() {
	const currentYear = new Date().getFullYear();
	const { t } = useTranslation('translation');

	return (
		<footer className="it-footer pb-0 pt-3">
			<div className="it-footer-main">
				<div className="container" style={{ padding: "20px" }}>
					<div className="row justify-content-center">
						<div className="col-lg-6 col-md-4 col-sm-6 pb-2">
							<h6 className="border-white border-bottom font-weight-normal pb-2">
								<span>{t('footer.titolo1')}</span>
							</h6>
							<div>
								<p>
									<Trans i18nKey="footer.descrizione1" />
								</p>
							</div>
						</div>
						<div className="col-lg-6 col-md-4 col-sm-6 pb-2">
							<h6 className="border-white border-bottom font-weight-normal pb-2" style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
								<span>{t('footer.titolo2')}</span>
							</h6>
							<div>
								<p>
									<Trans i18nKey="footer.descrizione2" />
								</p>
							</div>
						</div>
						<div className="row" style={{ justifyContent: "center", margin: "15px" }}>
							<div className="row align-content-center" style={{ gap: "20px", justifyContent: "center" }}>
								<div title="" style={{ height: "130px", width: "150px", paddingTop: "12px" }}>
									<img src="./images/logo-europa.png" alt="" />
								</div>
								<div title="" style={{ height: "130px", width: "150px" }}>
									<img src="./images/logo-repubblica-italiana.svg" alt="" />
								</div>
								<div title="" style={{ height: "130px", width: "200px" }}>
									<img src="https://www.regione.sardegna.it/immagini/61_240_20210401105330.svg" alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="it-footer-small-prints clearfix d-print-none">
				<div className="container">
					<h3 className="sr-only">Sezione Link Utili</h3>
					<ul className="it-footer-small-prints-list list-inline mb-0 d-flex flex-column flex-md-row">
						<li className="list-inline-item"><a href="/documenti/Informativa_GDPR.pdf" title="Vai alla pagina: Privacy Policy" rel="noreferrer"><span>Privacy Policy</span></a></li>
						<li className="list-inline-item"><a href="/documenti/Cookie_Policy.pdf" title="Vai alla pagina: Cookie policy"><span>Cookie Policy</span></a></li>
						<li className="list-inline-item"><a target="_blank" download href="./manualistica/Manuale_Cittadino.pdf" title="Manuale utente" rel="noreferrer"><span>Manuale utente</span></a></li>
						<li className="list-inline-item flex-grow-1"><div>&copy;<a target="_blank" className=" d-print-none" href="https://www.regione.sardegna.it">&nbsp;</a>{`${currentYear} Regione Autonoma della Sardegna`}</div></li>
					</ul>
				</div>
			</div>
		</footer>
	);
}
