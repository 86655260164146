/* eslint-disable react/no-array-index-key */
import { useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import BoxHomepage from './BoxHomepage';

export default function BoxesHomepage() {
	const user = useSelector((state) => state.authentication.user);
	const location = useLocation();
	const links = [
		{
			testoRiga1: 'CONSULTARE LO STATO DI UNA',
			testoRiga2: 'POSIZIONE DEBITORIA',
			testoLink: 'Prosegui',
			tooltip: 'Consulta posizione debitorie',
			linkTo: '/posizione-debitoria'
		},
		{
			testoRiga1: 'CONSULTARE LO',
			testoRiga2: 'STORICO DEI PAGAMENTI',
			testoLink: 'Prosegui',
			tooltip: 'Consulta storico pagamenti',
			linkTo: '/storico-pagamenti'
		},
		{
			testoRiga1: 'CONSULTARE LA LISTA DEGLI',
			testoRiga2: 'ENTI CHE OFFRONO PAGAMENTI PAGOPA',
			testoLink: 'Visualizza',
			tooltip: 'Consulta gli Enti creditori',
			linkTo: '/enti'
		},
		{
			testoRiga1: 'CONSULTARE LA LISTA DEI',
			testoRiga2: 'PAGAMENTI CHE NON RICHIEDONO AUTENTICAZIONE',
			testoLink: 'Visualizza',
			tooltip: 'Consulta pagamenti senza autenticazione',
			linkTo: '/'
		}
	];

	const boxes = links.map((l, i) => (
		<BoxHomepage
			key={`box-home-${i}`}
			testoRiga1={l.testoRiga1}
			testoRiga2={l.testoRiga2}
			testoLink={l.testoLink}
			link={l.linkTo}
			tooltip={l.tooltip}
		/>
	));

	if (location.pathname === "/") return <></>;
	return (
		<section className="bg-lightgrey pt-4 pb-4">
			<div className="container">
				<div className="row">
					{boxes}
				</div>
			</div>
		</section>
	);
}
