import React from "react";
import { TextField } from "@mui/material";
import NumberFormat from "react-number-format";

export default function CampoImporto({ value, onChange, campo }) {
	return (
		<NumberFormat
			onValueChange={({ formattedValue, value: val }) => onChange(formattedValue)}
			value={value}
			style={{ minWidth: "300px", flexGrow: 1, flexShrink: 1, margin: "8px" }}
			label={campo.label}
			variant="standard"
			InputProps={{ inputProps: { min: campo.min, max: campo.max } }}
			customInput={TextField}
			decimalScale={2}
			decimalSeparator=","
			thousandSeparator="."
		/>
	);
}
