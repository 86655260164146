import { Box, Modal as LibraryModal } from '@mui/material';

export default function Modal({ open, children, onClose, addStyle }) {
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 4,
		...addStyle
	};

	return (
		<LibraryModal
			open={open}
			onClose={onClose}
			style={{ zIndex: 5 }}
		>
			<Box sx={style}>
				{children}
			</Box>
		</LibraryModal>
	);
}
